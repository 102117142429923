import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@blueprintjs/datetime";
import { Popover, Icon } from "@blueprintjs/core";
import dayjs from "util/dayjs-init.js";
import CalendarIcon from "../../../assets/images/cargo/chart/calendar.svg";
import "../durationPicker/durationPicker.css";
import { Timepicker } from "./timePicker.jsx";
import { Grid, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../constants/colors";
import Select from "react-select";
import ClockIcon from "../../../assets/images/dateTime/clock.svg";
import { useSelector } from "react-redux";
import { getShorthandSettings } from "../../model/slice/dateTimeShorthandSlice";
import { selectedTimezone } from "../../model/slice/timezoneSlice.js";
import { TIMEZONE } from "../../constants/timezone.js";
import { convertToTimezone, getCurrentDateWithTimezone } from "../../util/timezone.js";

const dateTimePickerSelectorStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "45%",
    fontSize: "12px",
  }),
  control: (provided, state) => ({
    ...provided,
    // width: "100px",
    opacity: state?.isDisabled ? 0.5 : 1,
    height: "30px",
    minHeight: "30px",
    background: state?.selectProps?.isSm ? "#21262f" : "#2c3a48",
    borderRadius: "5px",
    borderColor: "#2c3b48",
    boxShadow: null,
    overflow: "hidden",
    "&:hover": {
      borderColor: "#2c3b48",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    display: "none",
    height: "30px",
    minHeight: "30px",
    background: state?.isSm ? "#21262f" : "#2c3a48",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "grid",
    placeItems: "center",
    fontSize: "12px",
    padding: "0px",
    background: "none",
    height: "22px",
    "&:hover": {
      background: "#26303b",
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "null",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 10,
    borderRadius: 0,
    color: "#ffffff",
    marginTop: "8px",
  }),
  singleValue: (provided, state) => {
    const opacity = state?.isDisabled ? 0.5 : 1;

    return {
      ...provided,
      opacity,
      color: "#ffffff",
    };
  },
  menuList: (provided) => ({
    ...provided,
    background: "#2c3a48",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    height: "100%",
    minHeight: "50px",
    maxHeight: "330px",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: "30px",
    minHeight: "30px",
    "& svg": {
      fill: "#19b2ab",
    },
    "& svg:checked": {
      fill: "#19b2ab",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const useStyles = makeStyles(() => ({
  timePickerContainer: {
    display: "flex",
    background: COLORS.componentBackColor,
    justifyContent: "flex-end",
    margin: "10px 0",
    right: "0%",
  },
  popoverContentContainer: {
    background: COLORS.componentBackColor,
    color: COLORS.white,
    zIndex: 500,
    borderRadius: "10px",
    border: "solid 1px white",
  },
  submitButton: {
    width: "80%",
    height: "34px",
    borderRadius: "5px",
    background: "#19b2ab",
    textTransform: "none",
    fontSize: "12px",
    color: "#21262f",
    "& span": {
      marginLeft: "0px",
    },
    "&:hover": {
      background: "#19b2ab",
    },
  },
  advancedSettings: {
    fontSize: "12px",
    width: "70%",
  },
  shorthandSettingsContainer: {
    marginLeft: "15px",
    marginBottom: "10px",
  },
  clockIconHolder: {
    position: "relative",
    top: "2px",
  },
  smallIncrement: {
    display: "flex",
    alignItems: "center",
  },
  largeIncrement: {
    display: "flex",
    alignItems: "center",
  },
}));

export const DateTimePicker = (props) => {
  const {
    date,
    submitDateTimeChange,
    disabled = false,
    isMaximized = false,
    latestDateTime,
  } = props;
  const styles = useStyles();
  const timezone = useSelector(selectedTimezone);
  const currentShorthandSettings = useSelector(getShorthandSettings);
  const [time, setTime] = useState(date);
  // for additional settings
  const [isOpen, setIsOpen] = useState(false);
  const [isPopover, setIsPopover] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [maxDateTime, setMaxDateTime] = useState(timezone === TIMEZONE.UTC ? dayjs.utc() : dayjs());
  const [shorthandSettingsForShort, setShorthandSettingsForShort] = useState(
    currentShorthandSettings.short
  );
  const [shorthandSettingsForLong, setShorthandSettingsForLong] = useState(
    currentShorthandSettings.long
  );
  const defaultDate = (timezone === TIMEZONE.UTC ? dayjs.utc(date) : dayjs(date)).toDate();

  const shortOptions = [
    { value: 1, label: "1min" },
    { value: 2, label: "2min" },
    { value: 3, label: "3min" },
    { value: 5, label: "5min" },
    { value: 7, label: "7min" },
    { value: 10, label: "10min" },
  ];

  const longOptions = [
    { value: 10, label: "10min" },
    { value: 15, label: "15min" },
    { value: 20, label: "20min" },
    { value: 25, label: "25min" },
    { value: 30, label: "30min" },
  ];

  const changeHandler = (date) => {
    setTime(date);
  };

  const timeShorthandHandler = (e) => {
    if (e.value < 10) {
      setShorthandSettingsForShort(e.value);
    } else if (e.value === 10 && shorthandSettingsForLong === 10) {
      setShorthandSettingsForShort(e.value);
    } else if (e.value === 10 && shorthandSettingsForShort === 10) {
      setShorthandSettingsForLong(e.value);
    } else {
      setShorthandSettingsForLong(e.value);
    }
  };

  const shortcutHandler = (props) => {
    setTime(props.date);
  };

  const shortcutSetting = () => {
    const dateValue = timezone === TIMEZONE.UTC ? dayjs.utc() : dayjs();
    return [
      {
        date: new Date(dateValue),
        label: "show current",
      },
      {
        date: new Date(dateValue.subtract(1, "minute")),
        label: "1 minute ago",
      },
      {
        date: new Date(dateValue.subtract(30, "minute")),
        label: "30 minutes ago",
      },
      {
        date: new Date(dateValue.subtract(1, "hour")),
        label: "1 hour ago",
      },
      {
        date: new Date(dateValue.subtract(24, "hour")),
        label: "24 hours ago",
      },
      {
        date: new Date(dateValue.subtract(7, "day")),
        label: "7 days ago",
      },
      {
        date: new Date(dateValue.subtract(30, "day")),
        label: "30 days ago",
      },
    ];
  };

  useEffect(() => {
    if (!time) return;
    const dateValue = getCurrentDateWithTimezone(timezone);
    const timeValue = convertToTimezone(time, timezone);
    if (
      timeValue.isAfter(dateValue) ||
      timeValue.isBefore(dateValue.subtract(30, "day").subtract(1, 'minute'))
    ) {
      setSubmitDisable(true);
    } else {
      setSubmitDisable(false);
    }
  }, [time]);

  useEffect(() => {
    // follow the shorthand change
    setTime(date);
  }, [date]);

  useEffect(() => {
    if (latestDateTime) {
      setMaxDateTime(timezone === TIMEZONE.UTC ? dayjs.utc(latestDateTime) : dayjs(latestDateTime));
    }
  }, [latestDateTime]);

  return (
    <>
      <Popover
        position={isMaximized ? "left-bottom" : "left"}
        disabled={disabled}
        isOpen={isPopover}
        onClosed={() => setIsOpen(false)}
        interactionKind="click"
        onInteraction={(state) => setIsPopover(state)}
        content={
          <div className={styles.popoverContentContainer}>
            <DatePicker
              onChange={changeHandler}
              onShortcutChange={shortcutHandler}
              reverseMonthAndYearMenus={true}
              defaultValue={defaultDate}
              minDate={new Date(getCurrentDateWithTimezone(timezone).subtract(30, "day"))}
              maxDate={new Date(maxDateTime)}
              shortcuts={shortcutSetting()}
            />
            <Grid container className={styles.timePickerContainer}>
              <Grid item xs={4} />
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <div className={styles.clockIconHolder}>
                  <img src={ClockIcon} width={20} height={20} />
                </div>
                <Timepicker time={time} setTime={setTime} timezone={timezone} />
              </Grid>
              <Grid item xs={3}>
                <Button
                  className={styles.submitButton}
                  disabled={submitDisable}
                  onClick={() => {
                    submitDateTimeChange(timezone === TIMEZONE.UTC ? time : dayjs(time), shorthandSettingsForShort, shorthandSettingsForLong);
                    setIsPopover(false);
                  }}
                >
                  {"submit"}
                </Button>
              </Grid>
            </Grid>
            <Grid container className={styles.shorthandSettingsContainer}>
              <Grid item xs={4} />
              <div onClick={() => setIsOpen(!isOpen)}>
                <span>Advanced settings</span>
                <Icon icon="chevron-down" />
              </div>
            </Grid>
            {isOpen && (
              <>
                <Grid container style={{ marginBottom: "5px" }}>
                  <Grid item xs={4} />
                  <Grid item xs={8}>
                    <div className={styles.smallIncrement}>
                      <div className={styles.advancedSettings}>
                        <Icon icon="chevron-left" />
                        <span style={{ marginRight: "20px" }}>Set small Increment</span>
                      </div>
                      <Select
                        defaultValue={shortOptions.find(
                          (option) => option.value === shorthandSettingsForShort
                        )}
                        options={shortOptions}
                        onChange={timeShorthandHandler}
                        name="shorthandOption"
                        styles={dateTimePickerSelectorStyles}
                      />
                    </div>
                    <div className={styles.largeIncrement}>
                      <div className={styles.advancedSettings}>
                        <Icon icon="double-chevron-left" />
                        <span style={{ marginRight: "20px" }}>Set large Increment</span>
                      </div>
                      <Select
                        defaultValue={longOptions.find(
                          (option) => option.value === shorthandSettingsForLong
                        )}
                        options={longOptions}
                        onChange={timeShorthandHandler}
                        name="shorthandOption"
                        styles={dateTimePickerSelectorStyles}
                      />
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        }
      >
        <img src={CalendarIcon} width={20} height={20} style={{marginTop: '10px'}}/>
      </Popover>
    </>
  );
};

DateTimePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  submitDateTimeChange: PropTypes.func,
  disabled: PropTypes.bool,
  isNoData: PropTypes.bool,
  isMaximized: PropTypes.bool,
  latestDateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
};
