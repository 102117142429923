import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from "react-router-dom";
import dayjs from 'util/dayjs-init.js';

//Image imports
import EngineIcon from "../../../assets/images/vessels/vessel-menu/icon-menu-engine.svg";
import EngineIconSelected from "../../../assets/images/vessels/vessel-menu/icon-menu-engine-selected.svg";

//Localization Imports
import localizationKeys from "../../i18n/localizationKeys.js";
import {
  FOC_DURATION_LIST,
  selectedFOCDuration,
  setSelectedFOCDuration,
} from "../../model/slice/fleetNavigationDataSlice";
import clsx from "clsx";
import { useVesselSettings } from "../../helpers/vesselSetting/useVesselSettings";
import { detectDevice, DEVICE_TYPE } from "../../constants/detectDevice";
import { NoData } from "../offlineComponent/noData.jsx";
import { editVesselSettings } from "../../helpers/vesselSetting/editVesselSettings";
import CustomToggleButton from "../common/toggleButton.jsx";
import { getFocUnit, focValue } from "../../util/navigation/navigation";

const useStyles = ({ isMobile, layoutStyle }) =>
  makeStyles((theme) => ({
    engineDataHolder: {
      "& td": {
        paddingTop: "15px",
      },
      width: "100%",
    },
    engineImage: {
      height: "65px",
      width: "60px",
    },
    iconLabel: {
      position: "absolute",
      top: "25px",
      left: "50%",
      transform: "translateX(-50%)",
      fontSize: "13px",
    },
    iconLabelSelected: {
      color: "#ffffff",
    },
    iconHolder: {
      cursor: "pointer",
      position: "relative",
    },
    fixedTitleTextSize: {
      "& p": {
        fontSize: isMobile ? "0.78rem" : layoutStyle.paperTitle.fontSize,
      },
      paddingLeft: isMobile ? "0.5rem" : null,
      paddingRight: isMobile ? "0.5rem" : null,
    },
    navValueTextSize: {
      fontSize: isMobile ? "0.78rem" : layoutStyle.navValue.fontSize,
      textAlign: "right",
    },
    navLabelTextSize: {
      fontSize: isMobile ? "0.78rem" : layoutStyle.navLabel.fontSize,
    },
  }));

const EngineDataPanel = (props) => {
  const dispatch = useDispatch();
  const isMobile = detectDevice === DEVICE_TYPE.PHONE;
  const { t, isDisconnected, foUnits } = props;
  const { vesselId } = useParams();
  const { vesselSettings } = useVesselSettings({ vesselId });
  const styles = useStyles({ isMobile, layoutStyle: props.classes })();
  const classes = { ...styles, ...props.classes };
  const [selectedEngine, setSelectedEngine] = React.useState(1); //Selected engine defaults to 1
  const [geFoc, setGeFoc] = React.useState();
  const [meFoc, setMeFoc] = React.useState();
  const [focDate, setFocDate] = React.useState();
  editVesselSettings(vesselSettings, [props.mainEngineData[selectedEngine - 1]?.position]);
  const vesselMaxPower = (vesselId) => {
    const retMaxPower = vesselSettings.meMaxKw;
    return retMaxPower;
  };
  const selectedFocDuration = useSelector(selectedFOCDuration);
  const [engines, setEngines] = React.useState([]);

  React.useEffect(() => {
    let engine = [];
    switch (vesselSettings?.NoME) {
      case 1:
        engine = [
          {
            label: localizationKeys.Center_abbreviation,
            position: 1,
          },
        ];
        break;
      case 2:
        engine = [
          {
            label: localizationKeys.Port_abbreviation,
            position: 1,
          },
          {
            label: localizationKeys.Starboard_abbreviation,
            position: 2,
          },
        ];
    }
    setEngines(engine);
  }, [vesselSettings?.NoME]);

  useEffect(() => {
    setSelectedEngine(1);
  }, [engines]);

  const clickEventHandler = (selectionIndex) => {
    setSelectedEngine(selectionIndex);
  };

  const focUnit = (type) => {
    const unit = getFocUnit(foUnits[type]);
    if (selectedFocDuration?.duration === 24) {
      return `${unit?.toUpperCase()}/day`;
    } else if (selectedFocDuration?.duration === 2) {
      return `${unit?.toUpperCase()}/2hours`;
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    if (props.focData === undefined || props.focData === null || !vesselSettings) {
      setFocDate(null);
      setGeFoc(null);
      setMeFoc(null);
    } else {
      setFocDate(props.focData.latestDate);
      setGeFoc(props.focData.geTotal * vesselSettings?.generatorFoUnitFactor);
      setMeFoc(props.focData.meTotal * vesselSettings?.engineFoUnitFactor);
    }
  }, [props.focData, vesselSettings]);

  const getEngineIcons = () => {
    return engines.map((e) => (
      <span
        className={classes.iconHolder}
        key={e.position}
        onClick={() => {
          clickEventHandler(e.position);
        }}
      >
        <span>
          <img
            className={classes.engineImage}
            src={selectedEngine === e.position ? EngineIconSelected : EngineIcon}
            alt={"engine image"}
          />
        </span>
        <span
          className={
            selectedEngine === e.position
              ? `${classes.iconLabel} ${classes.iconLabelSelected}`
              : classes.iconLabel
          }
        >
          {t(e.label)}
        </span>
      </span>
    ));
  };

  const MAIN_ENGINE = ["Port", "Starboard", "Center"];

  const handleFocDataChange = (e, value) => {
    value !== undefined &&
      value !== null &&
      (() => {
        dispatch(setSelectedFOCDuration(value));
      })();
  };

  return (
    <React.Fragment>
      <Paper
        className={clsx(
          classes.fixedTitleTextSize,
          isDisconnected ? classes.disconnectedPaperTitle : classes.paperTitle
        )}
      >
        <p>{t(localizationKeys.BasicEngineData_upper)}</p>
        {(engines.length > 0 || (engines.length > 0 && props.focData)) && (
          <>
            {getEngineIcons()}
            <CustomToggleButton
              options={FOC_DURATION_LIST}
              selectedOption={selectedFocDuration}
              handler={handleFocDataChange}
            />
          </>
        )}
      </Paper>
      {engines.length === 0 ? (
        <NoData />
      ) : (
        <table className={classes.engineDataHolder}>
          <tbody>
            <tr>
              <td>
                <div
                  className={clsx(
                    classes.navLabelTextSize,
                    isDisconnected ? classes.disconnectedNavLabel : classes.navLabel
                  )}
                >
                  {t(localizationKeys.EngineLoad_upper)}
                </div>
              </td>
              <td>
                {(() => {
                  if (vesselSettings?.NoME === 2) {
                    //Get values of mainEngine data.
                    //mainEngineData is an array[ 0: port, 1: starboard]
                    //we need -1 because if selected engine in UI is 1 then we need to access mainEngineData[0]
                    const mainEngineData = props.mainEngineData?.find(
                      (m) => m.position === MAIN_ENGINE[selectedEngine - 1]
                    );
                    const engineLoad =
                      mainEngineData !== undefined && mainEngineData.meOutput !== -1
                        ? (mainEngineData.meOutput / vesselMaxPower(vesselId)) * 100
                        : undefined;
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>
                        {engineLoad === undefined ? "*" : engineLoad.toFixed(2)} %
                      </div>
                    );
                  } else if (vesselSettings?.NoME === 1) {
                    const mainEngineData = props.mainEngineData?.find(
                      (m) => m.position === MAIN_ENGINE[2]
                    );
                    const powerData =
                      mainEngineData?.shaftHorsePower !== null
                        ? mainEngineData?.shaftHorsePower
                        : mainEngineData?.meOutput;
                    const engineLoad =
                      mainEngineData !== undefined && powerData !== -1
                        ? (powerData / vesselMaxPower(vesselId)) * 100
                        : undefined;

                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>
                        {engineLoad === undefined ? "*" : engineLoad.toFixed(2)} %
                      </div>
                    );
                  } else {
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>* %</div>
                    );
                  }
                })()}
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className={clsx(
                    classes.navLabelTextSize,
                    isDisconnected ? classes.disconnectedNavLabel : classes.navLabel
                  )}
                >
                  {t(localizationKeys.EngineRevolution_upper)}
                </div>
              </td>
              <td>
                {(() => {
                  if (vesselSettings?.NoME === 2) {
                    //Get values of mainEngine data.
                    //mainEngineData is an array[ 0: port, 1: starboard]
                    //we need -1 because if selected engine in UI is 1 then we need to access mainEngineData[0]
                    const mainEngineData = props.mainEngineData?.find(
                      (m) => m.position === MAIN_ENGINE[selectedEngine - 1]
                    );
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>
                        {mainEngineData === undefined || mainEngineData.shaftRevolution === -1
                          ? ""
                          : mainEngineData.shaftRevolution}{" "}
                        rpm
                      </div>
                    );
                  } else if (vesselSettings?.NoME === 1) {
                    const mainEngineData = props.mainEngineData?.find(
                      (m) => m.position === MAIN_ENGINE[2]
                    );
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>
                        {mainEngineData === undefined || mainEngineData.meSpeed === -1
                          ? ""
                          : mainEngineData.meSpeed}{" "}
                        rpm
                      </div>
                    );
                  } else {
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>rpm</div>
                    );
                  }
                })()}
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className={clsx(
                    classes.navLabelTextSize,
                    isDisconnected ? classes.disconnectedNavLabel : classes.navLabel
                  )}
                >
                  {t(localizationKeys.TotalEngineFoc_upper)}
                </div>
              </td>
              <td>
                {/* Main Engine FOC */}
                <div
                  data-testid="main_engine_foc_data"
                  className={clsx(classes.navValueTextSize, classes.navValue)}
                >
                  {focValue(meFoc, foUnits["engine"])} {focUnit("engine")}(
                  {focDate !== null && focDate !== undefined ? dayjs(focDate).format("MM/DD") : ""}
                  )
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className={clsx(
                    classes.navLabelTextSize,
                    isDisconnected ? classes.disconnectedNavLabel : classes.navLabel
                  )}
                >
                  {t(localizationKeys.TotalDieselEngineFoc_upper)}
                </div>
              </td>
              <td>
                {/* Generator Engine FOC */}
                <div
                  data-testid="generator_engine_foc_data"
                  className={clsx(classes.navValueTextSize, classes.navValue)}
                >
                  {focValue(geFoc, foUnits["generator"])} {focUnit("generator")}(
                  {focDate !== null && focDate !== undefined ? dayjs(focDate).format("MM/DD") : ""}
                  )
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
};

EngineDataPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
  mainEngineData: PropTypes.array,
  focData: PropTypes.object,
  isDisconnected: PropTypes.bool,
  foUnits: PropTypes.object,
};

export default withTranslation()(EngineDataPanel);
