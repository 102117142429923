
const formatGraphDataForCSV = (graphData, isCustom, customChartSettings) => {
  if (!graphData) return [];
  if (isCustom) {
    const graphDataWithChannelName = [];
    graphData?.forEach((data) => {
      const obj = {};
      obj.dateTime = data.dateTime;
      customChartSettings?.forEach((e) => {
        obj[e.name] = data[e.key];
      });
      graphDataWithChannelName.push(obj);
    });
    return graphDataWithChannelName;
  }
  return graphData;
};

export { formatGraphDataForCSV };