import React, { useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys";
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from "react-redux";
import {
  engineChartList,
  sepChartList,
  electricChartList,
} from "../../model/slice/chartSettingsSlice.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { ENGINE_CHART_TYPE } from "../../constants/trendGraph/enginesLine.js";
import { ColorPicker } from "./colorPicker.jsx";

import { visibilityIcon } from "../../../assets/images/visibility/icon-watch.js";
import { hideIcon } from "../../../assets/images/visibility/icon-hide.js";
import { NAVIGATION_BAR_HEIGHT, TAB_BAR_HEIGHT } from "../../layout/panelLayout.jsx";
import { TITLE_PAPER_HEIGHT } from "../../constants/monitoring/constants.js";
import { updateLines } from "../../api/charts.js";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType.js";
import { COLORS } from "../../constants/colors.js";
import { DEVICE_TYPE, detectDevice } from "../../constants/detectDevice.js";
import { selectedTimezone } from "../../model/slice/timezoneSlice.js";
import { TIMEZONE } from "../../constants/timezone.js";
import dayjs from "../../util/dayjs-init.js";
import { calDurations } from "../../constants/calDuration.js";
import { VESSEL_TYPE } from "../../constants/constants.js";
import { isDigitalChannel, isAnalogChannel } from "../../constants/channel/config.js";

export const MONTH_DATE_FORMAT = "YYYY/MM/dd";
export const DATE_TIME_FORMAT = "MM/dd HH:mm";

const useStyles = makeStyles((theme) => ({
  root: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#2c3a48",
    color: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 30, 25),
    width: "290px",
    height: "70px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const getLineInfo = ({ vesselData, dataList, chartType, isComparison, type }) => {
  if (isComparison) {
    if (
      chartType === ENGINE_CHART_TYPE.ENGINE_DEVIATION ||
      chartType === ENGINE_CHART_TYPE.ENGINE_EXHAUST ||
      chartType === ENGINE_CHART_TYPE.ENGINE_TEMP
    ) {
      return filterDatalistByCylinders(dataList, vesselData);
    } else if (type === GRAPH_TYPE.ELECTRIC) {
      return filterDataListByGenerator(dataList, vesselData).filter(
        (d) => d.name !== "dateTime" && d.name !== "localDateTime" && d.key !== "dateTime"
      );
    } else {
      return (
        dataList?.filter(
          (d) => d.name !== "dateTime" && d.name !== "localDateTime" && d.key !== "dateTime"
        ) ?? []
      );
    }
  } else {
    if (
      chartType === ENGINE_CHART_TYPE.ENGINE_DEVIATION ||
      chartType === ENGINE_CHART_TYPE.ENGINE_EXHAUST ||
      chartType === ENGINE_CHART_TYPE.ENGINE_TEMP
    ) {
      return filterDatalistByCylinders(dataList, vesselData);
    } else if (type === GRAPH_TYPE.ELECTRIC) {
      return filterDataListByGenerator(dataList, vesselData).filter(
        (d) => d.name !== "dateTime" && d.name !== "localDateTime" && d.key !== "dateTime"
      );
    } else {
      return (
        dataList?.filter(
          (d) => d.name !== "dateTime" && d.name !== "localDateTime" && d.key !== "dateTime"
        ) ?? []
      );
    }
  }
};
const filterDatalistByCylinders = (dataList, vesselData) => {
  if (!vesselData) return [];
  //fetch number of cylinders by vessel id
  const cylinders = vesselData.cylinder;
  const MEEngineType = vesselData.MEEngineType;
  // If MEEngineType is S, remove B type cylinders
  if (MEEngineType === "S") {
    dataList = dataList?.filter((d) => !d.name.includes("B"));
  }
  //Filter the datalist according to the number of cylinders
  const filteredDataItems = dataList?.filter(
    (d) =>
      !(
        d.name === "dateTime" ||
        d.name === "localDateTime" ||
        d.key === "dateTime" ||
        d.name.match(/\d+/)?.[0] > cylinders
      )
  );
  return filteredDataItems;
};
const filterDataListByGenerator = (dataList, vesselData) => {
  if (!vesselData) return [];
  const filteredDataItems = dataList?.filter(
    (d) =>
      (d.keyName.match(/sg\d/)?.length > 0 &&
        d.keyName.match(/sg\d/)?.[0].substr(-1) <= vesselData.NoSG) ||
      (d.keyName.match(/dg\d/)?.length > 0 &&
        d.keyName.match(/dg\d/)?.[0].substr(-1) <= vesselData.NoDG)
  );
  return filteredDataItems;
};

let isLocked = false;
const AmCharts = React.memo((props) => {
  const {
    graphData = [],
    graphDataDigital = [],
    vesselId,
    type,
    chartType,
    isComparison = false,
    vesselData,
    toggleVariables = false,
    setComparisonLegendHeight = null,
    setComparisonLegendLeft = null,
    customChartLines,
    customLineData,
    isMockPreview = false,
    expand,
    comparisonGraphIndex,
    isVesselOverview,
    setChartRef,
    overviewTrendIdx,
  } = props;

  const isMobile = [DEVICE_TYPE.PHONE, DEVICE_TYPE.TABLET].includes(detectDevice);
  const refXYchart = useRef(null);
  const chartRef = useRef();
  const legendRef = useRef(null);
  const rootRef = useRef(null);
  let chartid = expand == null ? "amchartdata" : "amchartdataexpand";
  if (comparisonGraphIndex !== null) {
    chartid = chartid + comparisonGraphIndex;
  }
  if (isVesselOverview) {
    chartid = chartid + overviewTrendIdx;
  }
  const classes = useStyles();
  const highlight = useRef(null);
  const engineChartSetting = useSelector(engineChartList);
  const sepChartSetting = useSelector(sepChartList);
  const electricChartSetting = useSelector(electricChartList);
  const timezone = useSelector(selectedTimezone);
  const [hideLineList, updateHideLineList] = useState([]);

  //for Color select trick
  const [displayColorPickerFlag, setDisplayColorPickerFlag] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState({
    flag: "",
    position: 0,
  });
  const [dataKeyName, setDataKeyName] = useState("");
  const [colorPickerPos, setColorPickerPos] = useState({ x: 0, y: 0 });
  const [legendHeight, setLegendHeight] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (isComparison) {
      const currentHideLineList = JSON.parse(
        sessionStorage.getItem(`comparison-${chartType}-${vesselId}`)
      );
      if (!!currentHideLineList && currentHideLineList.length > 0) {
        updateHideLineList(currentHideLineList);
      }
    }
  }, []);

  useEffect(() => {
    if (!isComparison) {
      if (!customLineData) return;
      const currentHideLineList = Object.entries(customLineData)
        .filter(([key, value]) => "visible" in value && !value?.visible)
        .map(([key]) => key);
      if (!!currentHideLineList && currentHideLineList.length > 0) {
        updateHideLineList(currentHideLineList);
      }
    }
  }, [customLineData]);

  useEffect(() => {
    if (isComparison) {
      sessionStorage.setItem(`comparison-${chartType}-${vesselId}`, JSON.stringify(hideLineList));
    }
  }, [hideLineList]);

  // amcharts
  useEffect(() => {
    if (!chartRef) return;
    if (!vesselData) return;
    if (graphData?.length <= 0 && graphDataDigital?.length <= 0) return;

    // *** License From 2023/05/22 ***
    am5.addLicense(process.env.AMCHARTS_LICENSE);
    // ******

    const groot = am5.Root.new(chartid, {
      useSafeResolution: false,
    });
    groot.utc = timezone === TIMEZONE.UTC; //set time zone to UTC
    groot.setThemes([am5themes_Animated.new(groot)]);
    rootRef.current = groot;
    const touchOptions = isMobile ? {
      pinchZoomX: true,
      pinchZoomY: true,
      panX: true,
      panY: true,
    } : {
      panX: false,
      panY: false,
    };
    const xyChart = groot.container.children.push(
      am5xy.XYChart.new(groot, {
        wheelY: isMockPreview ? "none" : "zoomX",
        maxTooltipDistance: -1,
        layout: groot.verticalLayout,
        ...touchOptions,
      })
    );

    // we will optimize this isOneSec options, but it need to change a lot of area...
    const duration = calDurations({
      startDate: graphData[0]?.dateTime,
      endDate: graphData[graphData?.length-1]?.dateTime
    });
    const checkOneSec = vesselData.vesselType === VESSEL_TYPE.SEP && duration === 0;

    const linesInfo = getLineInfo({
      vesselData,
      chartType,
      dataList: currentDataList(),
      isComparison,
      type,
    });
    const ganttOnly = type === GRAPH_TYPE.CUSTOM ? !linesInfo?.some(line => isAnalogChannel(line?.channelType)) : false;
    const lineOnly = type === GRAPH_TYPE.CUSTOM ? !linesInfo?.some(line => isDigitalChannel(line?.channelType)) : true;

    if (!ganttOnly && !lineOnly) xyChart.leftAxesContainer.set("layout", groot.verticalLayout);

    //Scrollbar X
    initScrollbarX(xyChart, groot, isMockPreview);
    //Scrollbar Y
    if (!ganttOnly) {
      initScrollbarY(xyChart, groot, isMockPreview, lineOnly);
    }

    const valueAxis = initYAxis(groot, xyChart, isComparison, setComparisonLegendLeft, isVesselOverview, ganttOnly);
    const dateAxis = initXAxis(groot, xyChart, isMockPreview, checkOneSec, graphData, duration, lineOnly);

    //Data prepare
    const tooltipText = createToolTipLabel(linesInfo);
    const dispData = Array.isArray(graphData) ? graphData.map((list) => ({ ...list })) : [];
    const dispDataDigitalInit = graphDataDigital.map((list) => ({ ...list }));
    ConvertDateForDisplayData(dispData);
    const dispDataDigital = makeDigitalData(dispDataDigitalInit, linesInfo);

    //Each LineSeries and setting
    initLineSeries(
      dispData,
      linesInfo,
      tooltipText,
      xyChart,
      groot,
      dateAxis,
      valueAxis,
      vesselId,
      isComparison,
      customLineData
    );

    if (!lineOnly) {
      const valueAxisGantt = initGanttYAxis(groot, xyChart, isComparison, setComparisonLegendLeft, isVesselOverview, ganttOnly);
      initGanttSeries(
        dispDataDigital,
        linesInfo,
        tooltipText,
        xyChart,
        groot,
        dateAxis,
        valueAxisGantt,
        vesselId,
        isComparison,
        customLineData
      );
    }

    if (!isMockPreview) {
      if (!isVesselOverview) {
        const tooltip = initTooltip(groot, xyChart);
        initTooltipLabel(xyChart, tooltip, type, t, timezone);
      }
      //Cursor
      initCursor(xyChart, groot, dateAxis, valueAxis, isMockPreview, isMobile);
    }

    //legend : Because of the drawing order, LEGEND needs to be here.
    if (!isVesselOverview) {
      const legend = initLegend(
        xyChart,
        groot,
        hideLineList,
        setDataKeyName,
        setColorPickerPos,
        setDisplayColorPicker,
        setDisplayColorPickerFlag,
        type,
        highlight,
        isMobile,
        t,
        timezone,
      );
      legend.itemContainers._values.forEach((v) => {
        v.events.on("click", function (ev) {
          const tooltip = initTooltip(groot, xyChart);
          initTooltipLabel(xyChart, tooltip, null, t, timezone);
        });
      });
      legend.events.on("boundschanged", function () {
        setLegendHeight(legend.height());
        if (isComparison) {
          setComparisonLegendHeight(legend.height());
        }
      });

      if (isComparison || isMockPreview) {
        legend.hide(0);
      }
      legendRef.current = legend;
    }
    //Click stop trick : START

    if (!isMockPreview && !isVesselOverview && !isMobile) {
      xyChart.plotContainer.events.on("click", function (ev) {
        if (isLocked) {
          handleTooltipUnlocking(xyChart);
        } else {
          handleTooltipLock(xyChart);
        }
      });
      //Click stop trick : END

      //click zoom out button also
      xyChart.zoomOutButton.events.on("click", (e) => {
        if (isLocked) {
          handleTooltipUnlocking(xyChart);
        }
      });

      // follow the locked cursor position change by maximize
      xyChart.plotContainer.events.on("boundschanged", (e) => {
        if (isLocked) handleTooltipLock(xyChart);
      });
    }

    //set ref xyChart for redraw function
    refXYchart.current = xyChart;
    rootRef.current = groot;

    if (isVesselOverview) {
      setChartRef(xyChart);
    }

    return () => {
      groot.dispose();
    };
  }, [vesselData, graphData, graphDataDigital, chartRef, timezone]);

  useEffect(() => {
    if (refXYchart.current && displayColorPicker) {
      const tooltip = initTooltip(refXYchart.current?.root, refXYchart?.current);
      initTooltipLabel(refXYchart?.current, tooltip, null, t, timezone);
    }
  }, [displayColorPicker]);

  useEffect(() => {
    //for User change line color trick
    if (displayColorPicker.flag == "" || displayColorPicker.position == 0) {
      // Do nothing
    } else {
      refXYchart.current.series.each(function (item, index) {
        if (displayColorPicker.flag == item._settings.key) {
          //color change trick
          item.set("fill", am5.color(displayColorPicker.position));
          item.set("stroke", am5.color(displayColorPicker.position));

          // necessary for column series to set color using both item.set() and item.columns.template.setAll()
          if (item instanceof am5xy.ColumnSeries) {
            item.columns.template.setAll({
              fill: am5.color(displayColorPicker.position),
              stroke: am5.color(displayColorPicker.position),
            });
          }
          legendRef.current.data.setAll(refXYchart.current.series.values);
          addLegendFeatures(refXYchart.current, rootRef.current, legendRef.current, hideLineList, null, t, isMobile, timezone);
        }
      });
      legendRef.current.data.setAll(refXYchart.current.series.values);
      addLegendFeatures(refXYchart.current, rootRef.current, legendRef.current, hideLineList, null, t, isMobile, timezone);
      refXYchart.current.series.each(function (series, i) {
        if (series instanceof am5xy.LineSeries && series.get("isDigitalChannel")) {
          if (series.get("legendDataItem")) series.get("legendDataItem").dispose();
        }
      });
    }
  }, [displayColorPicker]);

  useEffect(() => {
    if (legendRef.current) {
      if (toggleVariables || isVesselOverview) {
        legendRef.current.show(0);
      } else {
        legendRef.current.hide(0);
      }
    }
  }, [legendRef.current, toggleVariables]);

  const currentDataList = () => {
    switch (type) {
      case GRAPH_TYPE.ELECTRIC:
        return electricChartSetting;
      case GRAPH_TYPE.ENGINE:
        return engineChartSetting.filter((d) => d.chartType === chartType);
      case GRAPH_TYPE.SEP:
        return sepChartSetting.filter((d) => d.chartType === chartType);
      case GRAPH_TYPE.CUSTOM:
        return customChartLines;
    }
  };

  //amCharts functions START
  const initLineSeries = (
    displayData,
    inlinesInfo,
    intooltipText,
    chart,
    root,
    inxAxis,
    inyAxis,
    vesselId,
    isComparison,
    customLineData
  ) => {
    inlinesInfo.forEach((oneLineInfo) => {
      if (!oneLineInfo) {
        if (isMockPreview) {
          inxAxis.hide(0)
          inyAxis.hide(0)
          const emptyChart = chart.series.push(
            am5xy.LineSeries.new(root, {
              valueXField: "a",
              valueYField: "b",
              xAxis: inxAxis,
              yAxis: inyAxis,
              visible: false,
            })
          );
          emptyChart.data.setAll([{"a": 1689897614000, "b": 20}])
        }
        return;
      }
      if (!isDigitalChannel(oneLineInfo?.channelType)) {     
        const key = oneLineInfo.keyName;
        inxAxis.show();
        inyAxis.show();
        const hiddenFlag = hideLineList.includes(key);
        let color = "";
        if (isComparison) {
          color = sessionStorage.getItem(`comparison-${chartType}-${vesselId}-${key}`)
            ? sessionStorage.getItem(`comparison-${chartType}-${vesselId}-${key}`)
            : oneLineInfo.color;
        } else {
          color = customLineData?.[key]?.color ?? oneLineInfo.color;
        }
        const valueSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            key: key,
            name: oneLineInfo.name,
            valueXField: "dateTime",
            valueYField: key,
            xAxis: inxAxis,
            yAxis: inyAxis,
            visible: !hiddenFlag,
            fill: am5.color(color),
            stroke: am5.color(color),
            legendLabelText: oneLineInfo.name,
          })
        );
        if (hideLineList.includes(key)) {
          valueSeries.hide();
        }
        valueSeries.set("isDigitalChannel", false);
        valueSeries.on("visible", async (visible, target) => {
          const targetName = target.get("valueYField");
          if (visible) {
            updateHideLineList((prev) => prev.filter((d) => d !== targetName));
          } else {
            updateHideLineList((prev) => prev.concat([targetName]));
          }
          if (!isComparison) {
            await updateLines(vesselId, type, chartType, targetName, null, visible);
          }
        });
        valueSeries.data.setAll(displayData);  
      }
    });

    // For tooltip
    inlinesInfo.forEach(oneLineInfo => {
      const key = oneLineInfo.keyName;
      const hiddenFlag = hideLineList.includes(key);
      let color = "";
      if (isComparison) {
        color = sessionStorage.getItem(`comparison-${chartType}-${vesselId}-${key}`)
          ? sessionStorage.getItem(`comparison-${chartType}-${vesselId}-${key}`)
          : oneLineInfo.color;
      } else {
        color = customLineData?.[key]?.color ?? oneLineInfo.color;
      }
      if (isDigitalChannel(oneLineInfo?.channelType)) {
        const valueSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            key: key,
            name: oneLineInfo.name,
            valueXField: "dateTime",
            valueYField: key,
            xAxis: inxAxis,
            yAxis: inyAxis,
            visible: !hiddenFlag,
            fill: am5.color(color),
            stroke: am5.color(color),
            legendLabelText: oneLineInfo.name,
          })
        );
        valueSeries.set("isDigitalChannel", true);
        valueSeries.data.setAll(displayData);
      }
    });
  };

  const initGanttSeries = (
    displayData,
    inlinesInfo,
    intooltipText,
    chart,
    root,
    inxAxis,
    inyAxis,
    vesselId,
    isComparison,
    customLineData
  ) => {
    if (Object.keys(displayData).length === 0) {
      inxAxis.hide(0)
      inyAxis.hide(0)
      return;
    }
    inlinesInfo.forEach((oneLineInfo) => {
      const key = oneLineInfo?.key;
      if (!oneLineInfo) {
        if (isMockPreview) {
          inxAxis.hide(0)
          inyAxis.hide(0) 
        }
        return;
      }
      if (isDigitalChannel(oneLineInfo?.channelType)) {
        const key = oneLineInfo.keyName;
        inyAxis.data.setAll([
          { category: "digitalVar" }
        ]);
        inxAxis.show()
        inyAxis.show()
        const hiddenFlag = hideLineList.includes(key);
        let color = "";
        if (isComparison) {
          color = sessionStorage.getItem(`comparison-${chartType}-${vesselId}-${key}`)
            ? sessionStorage.getItem(`comparison-${chartType}-${vesselId}-${key}`)
            : oneLineInfo.color;
        } else {
          color = customLineData?.[key]?.color ?? oneLineInfo.color;
        }

        const ganttSeries = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            key: key,
            name: oneLineInfo.name,
            openValueXField: "start",
            valueXField: "end",
            categoryYField: "category",
            sequencedInterpolation: true,
            xAxis: inxAxis,
            yAxis: inyAxis,
            visible: !hiddenFlag,
            fill: am5.color(color),
            stroke: am5.color(color),
            legendLabelText: oneLineInfo.name,
          })
        );
        ganttSeries.data.processor = am5.DataProcessor.new(root, {
          dateFields: ["start", "end"],
          dateFormat: "yyyy-MM-dd HH:mm"
        });
        if (hideLineList.includes(key)) {
          ganttSeries.hide();
        }
        ganttSeries.on("visible", async (visible, target) => {
          const targetName = target.get("valueYField");
          if (visible) {
            updateHideLineList((prev) => prev.filter((d) => d !== targetName));
          } else {
            updateHideLineList((prev) => prev.concat([targetName]));
          }
          if (!isComparison) {
            await updateLines(vesselId, type, chartType, targetName, null, visible);
          }
        });
        ganttSeries.data.setAll(displayData[key]);
      }
    });
  };

  //amCharts functions END
  const ConvertDateForDisplayData = (testData) =>
    testData.map((value) => {
      value.dateTime = Date.parse(value["dateTime"]);
      return value;
    });
  
  const makeDigitalData = (digitalData, linesInfo) => {
    digitalData.map((value) => {
      value.dateTime = Date.parse(value["dateTime"]);
      return value;
    });
    const data = {};
    const digitalLines = linesInfo.filter(l => isDigitalChannel(l?.channelType));

    digitalLines.forEach((line) => {
      const key = line.key;
      const selectedVar = line.digitalVar;
      data[key] = [];
      let curGroup = null;
      digitalData.forEach((r, idx) => {
        if (!curGroup || curGroup.category !== r[key]) {
          if (curGroup) {
            curGroup.end = digitalData[idx - 1].dateTime;
            data[key].push({
              category: curGroup.category == selectedVar ? "digitalVar" : "",
              start: curGroup.start,
              end: curGroup.end,
              status: r[key],
            });
          }
          curGroup = { category: r[key], start: r.dateTime };
        }
      });
  
      if (curGroup) {
        curGroup.end = digitalData[digitalData.length - 1].dateTime;
        data[key].push({
          category: curGroup.category == selectedVar ? "digitalVar" : "",
          start: curGroup.start,
          end: curGroup.end,
          status: curGroup.category,
        });
      }
    });

    return data;
  };

  const createToolTipLabel = (inlinesInfo) => {
    let retString = "[bold]{dateTime.formatDate('yyyy/MM/dd HH:mm:ss')}[/]\n\n";
    inlinesInfo.forEach(function (oneLineInfo) {
      if (!oneLineInfo) return;
      retString =
        retString +
        "[" +
        oneLineInfo.color +
        " " +
        " width: 130px]" +
        oneLineInfo.name +
        "[/] {" +
        oneLineInfo.keyName +
        "}\n";
    });
    return retString;
  };

  const PAPER_HEIGHT = `calc(50vh - ${NAVIGATION_BAR_HEIGHT}px - ${TAB_BAR_HEIGHT}px - 20px - ${TITLE_PAPER_HEIGHT}px)`;
  const COMPARISON_GRAPH_HEIGHT = `calc(${PAPER_HEIGHT} - ${TITLE_PAPER_HEIGHT}px + ${legendHeight}px + ${"30"}px)`;

  return (
    <>
      <div
        id={chartid}
        ref={chartRef}
        data-testid="amcharts"
        style={{
          width: "100%",
          height: isComparison
            ? toggleVariables
              ? COMPARISON_GRAPH_HEIGHT
              : PAPER_HEIGHT
            : "calc(100%)",
        }}
      />
      <div
        className="colorPickerContainer"
        style={{
          width: "fit-content",
          visibility: displayColorPickerFlag ? "visible" : "hidden",
          position: "relative",
          left: colorPickerPos.x,
          bottom: colorPickerPos.y,
        }}
      >
        <div style={{ left: `10px` }}>
          <ColorPicker
            className={classes.colorPicker}
            setDisplayColorPicker={setDisplayColorPicker}
            graphType={type}
            dataKey={dataKeyName}
            setOnClose={setDisplayColorPickerFlag}
            chartType={chartType}
            isComparison={isComparison}
            vesselId={vesselId}
          />
        </div>
      </div>
    </>
  );
});

//export amCharts common init functions
export const initXAxis = (
  root,
  chart,
  isMockPreview,
  isOneSec = false,
  graphData = [],
  interval = 1,
  lineOnly = true,
) => {
  // Gantt chart cannot use gapless date axis
  const dateAxis = lineOnly
    ? chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        minZoomCount:7,
        baseInterval: {
        timeUnit: isOneSec ? "second" : "minute",
        count: interval <= 1 ? 1 : interval,
        },
        groupData: true,
        groupCount: 3600,
        tooltipDateFormat: "yyyy/MM/dd HH:mm:ss",
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 150,
          minorGridEnabled: true,
        }),
      })
    )
    : chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        minZoomCount:7,
        baseInterval: {
          timeUnit: "minute",
          count: 1,
        },
        groupData: true,
        tooltipDateFormat: "yyyy/MM/dd HH:mm:ss",
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 150,
          minorGridEnabled: true
        }),
      })
    );

  dateAxis.get("dateFormats")["month"] = MONTH_DATE_FORMAT;
  dateAxis.get("periodChangeDateFormats")["month"] = MONTH_DATE_FORMAT;
  dateAxis.get("dateFormats")["week"] = MONTH_DATE_FORMAT;
  dateAxis.get("periodChangeDateFormats")["week"] = MONTH_DATE_FORMAT;
  dateAxis.get("dateFormats")["day"] = MONTH_DATE_FORMAT;
  dateAxis.get("periodChangeDateFormats")["day"] = MONTH_DATE_FORMAT;
  dateAxis.get("dateFormats")["hour"] = DATE_TIME_FORMAT;
  dateAxis.get("periodChangeDateFormats")["hour"] = DATE_TIME_FORMAT;
  dateAxis.get("dateFormats")["minute"] = DATE_TIME_FORMAT;
  dateAxis.get("periodChangeDateFormats")["minute"] = DATE_TIME_FORMAT;
  dateAxis.get("dateFormats")["second"] = DATE_TIME_FORMAT;
  dateAxis.get("periodChangeDateFormats")["second"] = DATE_TIME_FORMAT;
  const xRenderer = dateAxis.get("renderer");
  xRenderer.labels.template.setAll({
    fill: am5.color(COLORS.white),
    opacity: 0.2,
    fontSize: "12px",
    paddingTop: 14,
    centerX: am5.percent(20),
  });
  xRenderer.grid.template.setAll({
    stroke: am5.color(COLORS.white),
    strokeWidth: 2,
    strokeDasharray: [4, 2],
  });
  const rangeDataItem = dateAxis.makeDataItem({
    value: 0,
  });
  const range = dateAxis.createAxisRange(rangeDataItem);

  range.get("label").setAll({
    text: "",
    maxPosition: 1,
  });

  range.get("grid").setAll({
    stroke: am5.color(0x000000),
    strokeOpacity: 0.1,
    location: 1,
  });
  dateAxis.onPrivate("selectionMax", function (max) {
    if (max) {
      rangeDataItem.set("value", max);
      range.get("label").setAll({});
    }
  });
  return dateAxis;
};

export const initYAxis = (
  root,
  chart,
  isComparison = false,
  setComparisonLegendLeft,
  isVesselOverview,
  ganttOnly = false,
) => {
  const valueAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      height: ganttOnly ? am5.percent(0) : am5.percent(100),
      maxPrecision: 3,
      renderer: am5xy.AxisRendererY.new(root, {}),
      autoZoom: false,
    })
  );
  const yRenderer = valueAxis.get("renderer");
  yRenderer.labels.template.setAll({
    fill: am5.color(COLORS.white),
    opacity: 0.2,
    fontSize: "14px",
  });
  yRenderer.grid.template.setAll({
    stroke: am5.color(COLORS.white),
    strokeWidth: 2,
    strokeDasharray: [4, 2],
  });
  //align legend start of chart area.
  if (!isVesselOverview) {
    valueAxis.events.on("boundschanged", (ev) => {
      const legend = chart.children._values[1];
      legend?.set("dx", valueAxis.width());
      if (isComparison) {
        setComparisonLegendLeft(valueAxis.width());
      }
    });
  }
  return valueAxis;
};

export const initGanttYAxis = (root, chart, isComparison = false, setComparisonLegendLeft, isVesselOverview, ganttOnly) => {
  const valueAxisGantt = chart.yAxes.push(
    am5xy.CategoryAxis.new(root, {
      height: am5.percent(100),
      categoryField: "category",
      renderer: am5xy.AxisRendererY.new(root, {}),
      autoZoom: false,
      zoomable: false
    })
  );
  const yRenderer = valueAxisGantt.get("renderer");
  yRenderer.labels.template.setAll({
    forceHidden: true,
  });
  yRenderer.grid.template.setAll({
    stroke: am5.color(COLORS.white),
    strokeWidth: 2,
    strokeDasharray: [4, 2],
  });
  //align legend start of chart area.
  if (!isVesselOverview) {
    valueAxisGantt.events.on("boundschanged", (ev) => {
      const legend = chart.children._values[1];
      legend?.set("dx", valueAxisGantt.width());
      if (isComparison) {
        setComparisonLegendLeft(valueAxisGantt.width());
      }
    });
  }

  return valueAxisGantt;
};

export const initTooltip = (root, chart) => {
  let lockedPositionX;
  let lockedPositionY;
  const existingTooltip = chart.plotContainer.getTooltip();
  if (existingTooltip) {
    lockedPositionX = chart.plotContainer._settings.tooltipX;
    lockedPositionY = chart.plotContainer._settings.tooltipY;
    existingTooltip.dispose();
  }
  const tooltip = am5.Tooltip.new(root, {"pointerOrientation": "horizontal"});
  if (isLocked) {
    chart.plotContainer.setAll({
      tooltipPosition: "fixed",
      tooltipX: lockedPositionX,
      tooltipY: lockedPositionY,
      tooltip,
      tooltipText: "a",
      showTooltipOn: "always",
    });
    tooltip.get("background").setAll({
      fill: am5.color(0x27303d),
      fillOpacity: 0.8,
      stroke: am5.color(0xfea813),
    });
  } else {
    chart.plotContainer.setAll({
      tooltipPosition: "pointer",
      tooltipText: "a",
      tooltip,
    });

    tooltip.get("background").setAll({
      fill: am5.color(0x27303d),
      fillOpacity: 0.8,
      stroke: am5.color(0xffffff),
      strokeOpacity: 1,
    });
  }
  return tooltip;
};

const initTooltipLabel = (chart, tooltip, type, t, timezone) => {
  let width = 230;
  switch (type) {
    case GRAPH_TYPE.ELECTRIC:
      width = 130;
      break;
    case GRAPH_TYPE.ENGINE:
    case GRAPH_TYPE.SEP:
    case GRAPH_TYPE.CUSTOM:
      break;
  }

  tooltip.label.adapters.add("text", (text) => {
    text = t(localizationKeys.NoData_lower);
    let hasTooltipData = false;
    const hiddenDigitalList = [];
    chart.series.each(series => {
      if (series instanceof am5xy.ColumnSeries) {
        if (!series.get("visible")) hiddenDigitalList.push(series.get("key"))
      }
    });
    chart.series.each(function (series, i) {
      const tooltipDataItem = series.get("tooltipDataItem");
      const currName = series.get("name");
      if (tooltipDataItem && !currName.includes("test") && series instanceof am5xy.LineSeries) {
        hasTooltipData = true;
        if (i === 0) {
          const dateStr = tooltipDataItem.get("valueX");
          const date = new Date(timezone === TIMEZONE.UTC ? dateStr : dayjs(dateStr).format());
          const formatDate = `${date.getUTCFullYear()}/${("00" + (date.getUTCMonth() + 1)).slice(
            -2
          )}/${("00" + date.getUTCDate()).slice(-2)} ${("00" + date.getUTCHours()).slice(-2)}:${(
            "00" + date.getUTCMinutes()
          ).slice(-2)}:${("00" + date.getUTCSeconds()).slice(-2)} (${timezone})\n`;
          text += `[bold]${formatDate}[/]`;
          text = text.replace(t(localizationKeys.NoData_lower), "");
        }
        if (!series.isHidden() && !hiddenDigitalList.includes(series.get("key"))) {
          const digitalStatus = tooltipDataItem?.dataContext?.status;
          const valueY = tooltipDataItem.get("valueY") === undefined ? digitalStatus : tooltipDataItem.get("valueY");
          const strokeColor = series.get("stroke").toString();
          text += `\n[${strokeColor} width: ${width}px]${series.get(
            "name"
          )}:[/] [${strokeColor}]${valueY !== null && valueY !== undefined ? valueY : "--"}[/]`;
        }
      }
    });

    if (!hasTooltipData) {
      tooltip.set("forceHidden", true);
    } else {
      tooltip.set("forceHidden", false);
    }

    return text;
  });
};

export const initCursor = (chart, root, inxAxis, inyAxis, isMockPreview, isMobile) => {
  const cursorinstance = chart.set(
    "cursor",
    am5xy.XYCursor.new(root, {
      xAxis: inxAxis,
      yAxis: inyAxis,
      behavior: isMockPreview || isMobile ? "none" : "zoomXY",
    })
  );
  cursorinstance.lineX.setAll({
    stroke: am5.color(0xffffff),
    strokeWidth: 2,
    strokeDasharray: [],
  });
  cursorinstance.lineY.setAll({
    stroke: am5.color(0xffffff),
    strokeWidth: 2,
    strokeDasharray: [],
  });
  cursorinstance.selection.setAll({
    fill: am5.color(0xffffff),
    fillOpacity: 0.2,
  });

  return cursorinstance;
};
export const initScrollbarX = (chart, root, isMockPreview) => {
  const minGripWidth = 40;
  const scrollbarX = am5.Scrollbar.new(root, {
    orientation: "horizontal",
  });
  const isExpand = root.dom.id.includes("expand");
  scrollbarX.thumb.setAll({
    fill: isExpand ? am5.color("#626970") : am5.color(0x2c3a48),
    stroke: am5.color(0x020202),
    strokeOpacity: 0.3,
    minWidth: minGripWidth,
  });
  scrollbarX.get("background").setAll({
    fill: am5.color(0x020202),
    fillOpacity: 0.2,
  });
  scrollbarX.startGrip.get("background").setAll({
    fill: am5.color(0x2c3a48),
    maxWidth: 20,
    maxHeight: 20,
    centerX: -5,
    centerY: -5,
  });
  scrollbarX.endGrip.get("background").setAll({
    fill: am5.color(0x2c3a48),
    maxWidth: 20,
    maxHeight: 20,
    centerX: -5,
    centerY: -5,
  });
  scrollbarX.events.on("rangechanged", () => {
    if (isLocked) {
      handleTooltipUnlocking(chart);
    }
  });
  const startGripHandler = () => {
    if(scrollbarX.endGrip.x() - scrollbarX.startGrip.x() <= minGripWidth){
      scrollbarX.startGrip.set('x', scrollbarX.endGrip.x() - minGripWidth);
    }
  };
  const endGripHandler = () => {
    if(scrollbarX.endGrip.x() - scrollbarX.startGrip.x() <= minGripWidth){
      scrollbarX.endGrip.set('x', scrollbarX.startGrip.x() + minGripWidth);
    }
  };
  scrollbarX.startGrip.events.on("dragged", startGripHandler);
  scrollbarX.startGrip.events.on("positionchanged", startGripHandler);
  scrollbarX.endGrip.events.on("dragged", endGripHandler);
  scrollbarX.endGrip.events.on("positionchanged", endGripHandler);

  if (isMockPreview) {
    scrollbarX.hide(0);
  }
  chart.set("scrollbarX", scrollbarX);
  chart.bottomAxesContainer.children.push(scrollbarX);

  return scrollbarX;
};
export const initScrollbarY = (chart, root, isMockPreview, lineOnly = true) => {
  const minGripHeight = 50;
  const isExpand = root.dom.id.includes("expand");
  const scrollbarY = am5.Scrollbar.new(root, {
    orientation: "vertical",
    height: lineOnly ? am5.percent(100) : am5.percent(50)
  });
  scrollbarY.thumb.setAll({
    fill: isExpand ? am5.color("#626970") : am5.color(0x2c3a48),
    stroke: am5.color(0x020202),
    strokeOpacity: 0.3,
    minHeight: minGripHeight,
  });
  scrollbarY.get("background").setAll({
    fill: am5.color(0x020202),
    fillOpacity: 0.2,
  });
  scrollbarY.startGrip.get("background").setAll({
    fill: am5.color(0x2c3a48),
    maxWidth: 20,
    maxHeight: 20,
    centerX: -5,
    centerY: -5,
  });
  scrollbarY.endGrip.get("background").setAll({
    fill: am5.color(0x2c3a48),
    maxWidth: 20,
    maxHeight: 20,
    centerX: -5,
    centerY: -5,
  });
  scrollbarY.events.on("rangechanged", () => {
    if (isLocked) {
      handleTooltipUnlocking(chart);
    }
  });
  const startGripHandler = () => {
    if(scrollbarY.endGrip.y() - scrollbarY.startGrip.y() <= minGripHeight){
      scrollbarY.startGrip.set('y', scrollbarY.endGrip.y() - minGripHeight);
    }
  };
  const endGripHandler = () => {
    if(scrollbarY.endGrip.y() - scrollbarY.startGrip.y() <= minGripHeight){
      scrollbarY.endGrip.set('y', scrollbarY.startGrip.y() + minGripHeight);
    }
  };
  scrollbarY.startGrip.events.on("dragged", startGripHandler);
  scrollbarY.startGrip.events.on("positionchanged", startGripHandler);
  scrollbarY.endGrip.events.on("dragged", endGripHandler);
  scrollbarY.endGrip.events.on("positionchanged", endGripHandler);

  if (isMockPreview) {
    scrollbarY.hide(0);
  }
  chart.set("scrollbarY", scrollbarY);
  chart.rightAxesContainer.children.push(scrollbarY);

  return scrollbarY;
};

export const initLegend = (
  chart,
  root,
  hideLineList = [],
  setDataKeyName,
  setColorPickerPos,
  setDisplayColorPicker,
  setDisplayColorPickerFlag,
  type,
  highlight,
  isMobile,
  t,
  timezone,
) => {
  let dynamicWidth = {
    label: 130,
    container: 230,
  };

  switch (type) {
    case GRAPH_TYPE.SEP:
    case GRAPH_TYPE.ENGINE:
      dynamicWidth = {
        label: 105,
        container: 200,
      };
      break;
    case GRAPH_TYPE.ELECTRIC:
      dynamicWidth = {
        label: 0,
        container: 95,
      };
      break;
    case GRAPH_TYPE.CUSTOM:
      dynamicWidth = {
        label: 130,
        container: 230,
      };
      break;
    case GRAPH_TYPE.PRESSURE:
      dynamicWidth = {
        label: 0,
        container: 100,
      };
      break;
  }

  const legend = chart.children.push(
    am5.Legend.new(root, {
      x: am5.percent(0),
      y: am5.percent(100),
      centerX: am5.percent(0),
      centerY: am5.percent(100),
      layout: am5.GridLayout.new(root, {
        maxColumns: 12,
        fixedWidthGrid: false,
      }),
      clickTarget: "none",
      // useDefaultMarker: true,
    })
  );

  legend.labels.template.setAll({
    fontSize: 10,
    fill: am5.color(0xffffff),
    truncate: false,
    width: dynamicWidth.label,
    height: 15,
  });
  legend.itemContainers.template.setAll({
    marginTop: 8,
    marginRight: 5,
    paddingTop: 8,
    paddingBottom: 0,
    paddingLeft: 0,
    height: 15,
    width: dynamicWidth.container,
  });

  //legend click event trick
  // When legend mark click - disable hide behavior and display color picker
  legend.markers.template.interactionsEnabled = true;
  legend.markers.template.events.on("click", (e) => {
    if (chart.get("height")) {
      const colorPickerHeight =
        document.getElementsByClassName("colorPickerContainer")[0].clientHeight;
      const xRatio = Math.floor((e.point.x - legend.get("dx")) / 155);
      const yRatio = Math.ceil((legend.y() - e.point.y) / 30);
      setColorPickerPos({
        x: 160 * xRatio + (legend.get("dx") - 10),
        y: colorPickerHeight + 35 * yRatio + 19,
      });
    } else {
      setColorPickerPos({ x: 0, y: 0 });
    }
    const targetName = e.target.dataItem.dataContext._settings.key;
    chart.series.each(function (item) {
      if (targetName == item._settings.key && !(item instanceof am5xy.LineSeries && item.get("isDigitalChannel"))) {
        setDataKeyName(targetName);
        setDisplayColorPicker({ flag: targetName, position: 0 });
        setDisplayColorPickerFlag(true);
        item.hide();
      }
    });
  });
  legend.data.setAll(chart.series.values);
  addLegendFeatures(chart, root, legend, hideLineList, highlight, t, isMobile, timezone);
  chart.series.each(function (series, i) {
    if (series instanceof am5xy.LineSeries && series.get("isDigitalChannel")) {
      if (series.get("legendDataItem")) series.get("legendDataItem").dispose();
    }
  });
  return legend;
};

export const addLegendFeatures = (chart, root, legend, hideLineList, highlight, t, isMobile = false, timezone) => {
  let flg = false;
  legend.allChildren().forEach((v) => {
    v.events.on("pointerover", (e) => {
      const itemContainer = e.target;
      if (!itemContainer.dataItem.dataContext.isHidden()) {
        const visibleIcon = am5.Graphics.new(root, {
          fill: am5.color("#fff"),
          fillOpacity: 1,
          width: 20,
          height: 20,
          centerY: am5.p50,
          layer: 50,
          svgPath: visibilityIcon,
        });
        e.target.children.push(visibleIcon);
      }
      const series = itemContainer.dataItem.dataContext;
      if ("strokes" in series) {
        //for line chart
        chart.series.each((chartSeries) => {
          // if (chartSeries !== series && chartSeries !== highlight.current) {
          if (chartSeries !== series) {
            chartSeries?.strokes?.template.setAll({
              strokeOpacity: 0.15,
              stroke: am5.color(COLORS.white),
            });
          } else {
            chartSeries?.strokes?.template.setAll({
              strokeWidth: 3,
            });
          }
        });
      } else if ("columns" in series) {
        //for bar chart
        chart.series.each((chartSeries) => {
          // if (chartSeries !== series || chartSeries !== highlight) {
          if (chartSeries !== series) {
            chartSeries?.columns?.template.setAll({
              fillOpacity: 0.15,
              fill: am5.color(COLORS.white),
            });
          } else {
            chartSeries?.columns?.template.setAll({
              strokeWidth: 3,
            });
          }
        });
      }
      const rect = itemContainer._settings.background;
      rect.set("stroke", am5.color("#fff"));
    });
    v.events.on("pointerout", (e) => {
      flg = false;
      const itemContainer = e.target;
      if (!itemContainer.dataItem.dataContext.isHidden() && e.target.children.length > 3) {
        e.target.children.pop();
      }
      chart.series.each(function (chartSeries) {
        // if(chartSeries !== highlight.current){
        if ("strokes" in chartSeries) {
          //for line chart
          chartSeries.strokes.template.setAll({
            strokeOpacity: 1,
            strokeWidth: 1,
            stroke: chartSeries.get("fill"),
          });
        } else if ("columns" in chartSeries) {
          // for bar chart
          chartSeries.columns.template.setAll({
            strokeOpacity: 1,
            strokeWidth: 1,
            fill: chartSeries.get("fill"),
            fillOpacity: 1,
          });
        }
        // }
      });
      const rect = itemContainer._settings.background;
      rect.set("stroke", am5.color(COLORS.baseColor));
    });
    v.events.on("click", (e) => {
      while (e.target.children.length > 3) e.target.children.pop();
      if (flg) return;
      if (e.target.dataItem.dataContext.isHidden()) {
        e.target.dataItem.dataContext.show();
      } else {
        e.target.dataItem.dataContext.hide();
      }
      const tooltip = initTooltip(root, chart);
      initTooltipLabel(chart, tooltip, null, t, timezone);
      if (e.target.dataItem.dataContext.isHidden()) {
        const notVisibleIcon = am5.Graphics.new(root, {
          fill: am5.color("#fff"),
          fillOpacity: 1,
          width: 20,
          height: 20,
          centerY: am5.p50,
          layer: 50,
          svgPath: hideIcon,
        });
        e.target.children.push(notVisibleIcon);
      } else if (!e.target.dataItem.dataContext.isHidden()) {
        if (isMobile) return;
        const visibleIcon = am5.Graphics.new(root, {
          fill: am5.color("#fff"),
          fillOpacity: 1,
          width: 20,
          height: 20,
          centerY: am5.p50,
          layer: 50,
          svgPath: visibilityIcon,
        });
        // visibleIcon.events.on('click', (e) => {
        //   highlight.current = e.target._parent._dataItem.dataContext;
        // })
        e.target.children.push(visibleIcon);
      }
    });
    if (hideLineList.includes(v.dataItem.dataContext.get("valueYField"))) {
      const notVisibleIcon = am5.Graphics.new(root, {
        fill: am5.color("#fff"),
        fillOpacity: 1,
        width: 20,
        height: 20,
        centerY: am5.p50,
        layer: 50,
        svgPath: hideIcon,
      });
      v.children.push(notVisibleIcon);
    }
  });
};

//lock tooltip handle function
export const handleTooltipLock = (chart) => {
  isLocked = true;
  const cursor = chart.get("cursor");
  const cursorX = cursor.getPrivate("positionX");
  const cursorY = cursor.getPrivate("positionY");
  cursor.setAll({
    positionX: cursorX,
    positionY: cursorY,
    alwaysShow: true,
  });

  chart.plotContainer.setAll({
    tooltipPosition: "fixed",
    tooltipX: chart.plotContainer.width() * cursorX,
    tooltipY: chart.plotContainer.height() * cursorY,
    showTooltipOn: "always",
  });

  chart.plotContainer
    .getTooltip()
    .get("background")
    .setAll({
      fill: am5.color(0x27303d),
      stroke: am5.color(0xfea813),
    });
  cursor.lineX.setAll({
    stroke: am5.color(0xfea813),
    strokeWidth: 2,
    strokeDasharray: [],
  });
  cursor.lineY.setAll({
    stroke: am5.color(0xfea813),
    strokeWidth: 2,
    strokeDasharray: [],
  });
  setChartInteraction(chart, false);
  setCursorInteraction(chart, false);
};

export const handleTooltipUnlocking = (chart) => {
  isLocked = false;
  const cursor = chart.get("cursor");
  chart.plotContainer.setAll({
    tooltipPosition: "pointer",
    showTooltipOn: "hover",
  });
  cursor.setAll({
    positionX: null,
    positionY: null,
    alwaysShow: false,
  });
  chart.plotContainer.getTooltip().hide();
  chart.plotContainer
    .getTooltip()
    .get("background")
    .setAll({
      fill: am5.color(0x27303d),
      stroke: am5.color(0xffffff),
    });
  cursor.lineX.setAll({
    stroke: am5.color(0xffffff),
    strokeWidth: 2,
    strokeDasharray: [],
  });
  cursor.lineY.setAll({
    stroke: am5.color(0xffffff),
    strokeWidth: 2,
    strokeDasharray: [],
  });
  setChartInteraction(chart, true);
  setCursorInteraction(chart, true);
};

// Disable zooming and panning when the tooltip is locked
export const setChartInteraction = (chart, interactive) => {
  chart.set("wheelY", isLocked ? interactive : "zoomX");
  chart.plotContainer.set("clickable", interactive);
};

// Disable cursor interactions when the tooltip is locked
export const setCursorInteraction = (chart, interactive) => {
  chart.get("cursor").setAll({
    behavior: interactive ? "zoomXY" : "none",
    lineX: { strokeOpacity: interactive ? 1 : 0 },
    lineY: { strokeOpacity: interactive ? 1 : 0 },
  });
};

AmCharts.propTypes = {
  vesselData: PropTypes.object,
  expand: PropTypes.string,
  type: PropTypes.string,
  isNoData: PropTypes.bool,
  graphData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  graphDataDigital: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  enginePosition: PropTypes.string,
  chartType: PropTypes.string,
  vesselId: PropTypes.string,
  isInitialLoad: PropTypes.bool,
  isComparison: PropTypes.bool,
  comparisonGraphIndex: PropTypes.number,
  isVesselOverview: PropTypes.bool,
  setChartRef: PropTypes.func,
  overviewTrendIdx: PropTypes.number,
};

export default AmCharts;
