import React from "react";
import PropTypes from "prop-types";

//localization imports
import { withTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys.js";
import { Grid } from "@mui/material";
import { COLORS } from "../../constants/colors.js";

import AirIcon from "../../../assets/images/compass/windpanel/air.svg";
import WindPointer from "../../../assets/images/compass/windpanel/relative-wind-indicator.svg";
import WindPointerTrue from "../../../assets/images/compass/windpanel/true-wind-indicator.svg";
import { withStyles } from "@mui/styles";

const styles = () => ({
  alignRight: {
    textAlign: "right",
  },
  row: {
    margin: "8px 0 8px 0",
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    color: COLORS.white70,
  },
  value: {
    color: COLORS.white,
    fontSize: "18px",
  },
  label: {
    color: COLORS.greenishBlue,
    fontSize: "14px",
  },
})

const WindPanel = (props) => {
  const { heading, speedOG, twSpeed, twDirection, rwSpeed, rwDirection, classes, t } = props;

  return (
    <React.Fragment>
      <Grid container style={{ backgroundColor: '#2c3a4880', padding: '16px', borderRadius: '12px' }} gap={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ color: COLORS.greenishBlue }}>
              {t(localizationKeys.VesselHeading)}
            </Grid>
            <Grid item xs={6} style={{ color: COLORS.white }} className={`${classes.alignRight}`}>
              {(heading ?? 0).toFixed(1)} °
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ color: COLORS.greenishBlue }}>
              {t(localizationKeys.VesselSpeedOG)}
            </Grid>
            <Grid item xs={6} style={{ color: COLORS.white }} className={`${classes.alignRight}`}>
              {(speedOG ?? 0).toFixed(1)} knot
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "16px", display: "flex" }}>
        <Grid item xs={6}>
          <Grid container style={{ width: "97%", backgroundColor: '#2c3a4880', padding: '16px', borderRadius: '12px' }}>
            <Grid item xs={12}>
              <Grid container className={`${classes.row}`}>
                <Grid item xs={6} className={`${classes.header}`}><img src={AirIcon} style={{ marginRight: "4px" }} />{t(localizationKeys.TrueWind)}</Grid>
                <Grid item xs={6} className={`${classes.alignRight}`}><img src={WindPointerTrue} /></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ borderBottom: `solid 1px ${COLORS.componentBackColor}` }}>
              <Grid container className={`${classes.row}`}>
                <Grid item xs={6} className={`${classes.label}`}>{t(localizationKeys.WindSpeed_lower)}</Grid>
                <Grid item xs={6} className={`${classes.alignRight} ${classes.value}`}>{(twSpeed ?? 0).toFixed(1)} m/s</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={`${classes.row}`}>
                <Grid item xs={6} className={`${classes.label}`}>{t(localizationKeys.WindDirection_lower)}</Grid>
                <Grid item xs={6} className={`${classes.alignRight} ${classes.value}`}>{(twDirection ?? 0).toFixed(1)} °</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
          <Grid container style={{ width: "97%", alignSelf: "right", backgroundColor: '#2c3a4880', padding: '16px', borderRadius: '12px' }}>
            <Grid item xs={12}>
              <Grid container className={`${classes.row}`}>
                <Grid item xs={6} className={`${classes.header}`}><img src={AirIcon} style={{ marginRight: "4px" }} />{t(localizationKeys.RelativeWind)}</Grid>
                <Grid item xs={6} className={`${classes.alignRight}`}><img src={WindPointer} /></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ borderBottom: `solid 1px ${COLORS.componentBackColor}` }}>
              <Grid container className={`${classes.row}`}>
                <Grid item xs={6} className={`${classes.label}`}>{t(localizationKeys.WindSpeed_lower)}</Grid>
                <Grid item xs={6} className={`${classes.alignRight} ${classes.value}`}>{(rwSpeed ?? 0).toFixed(1)} m/s</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={`${classes.row}`}>
                <Grid item xs={6} className={`${classes.label}`}>{t(localizationKeys.WindDirection_lower)}</Grid>
                <Grid item xs={6} className={`${classes.alignRight} ${classes.value}`}>{(rwDirection ?? 0).toFixed(1)} °</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

WindPanel.propTypes = {
  heading: PropTypes.number,
  twSpeed: PropTypes.number,
  twDirection: PropTypes.number,
  rwSpeed: PropTypes.number,
  rwDirection: PropTypes.number,
  speedOG: PropTypes.number,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(WindPanel));
