import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useMediaQuery,
} from "@mui/material";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
  TableCellStyled,
  TableRowStyled,
  TableCellStyledForFunction,
} from "../utils/tableStyled.jsx";
import constants from "../utils/constants.js";
import { getComparator, stableSort, alarmHistoryListHeader } from "../utils/helper.js";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredAlarmSelector,
  historyAlarmSelector,
  isHistoryAlarmLoading,
  getSearchButtonSelector,
  currentWindowPosition,
  currentTablePosition,
  setCurrentWindowPosition,
  setCurrentTablePosition,
  currentHistoryPage,
  setCurrentHistoryPage,
} from "../../../model/slice/alarmSlice.js";
import { fetchAlarmHistory } from "../../../model/async/alarmAsync.js";
import clsx from "clsx";
import PageButton from "../../common/listUtil/pageButton.jsx";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside.js";

import SortIcon from "../../../../assets/images/alarmIcon/sort-icon.svg";
import SortIconPrimary from "../../../../assets/images/alarmIcon/sort-icon-primary.svg";
import ResetIcon from "../../../../assets/images/alarmIcon/reset-icon.svg";
import ResetIconPrimary from "../../../../assets/images/alarmIcon/reset-icon-primary.svg";
import CalenderIconSetColor from "../../common/calenderIconSetColor.jsx";
import CloseIcon from "../../../../assets/images/alarmIcon/close-icon.svg";
import { MobileSortIcon } from "../../common/listUtil/mobileSortIcon.jsx";
import { paginationStyle } from "../../common/listUtil/paginationStyle.js";

import SortUpIconSetColor from "../../common/SortUpIconSetColor.jsx";
import SortDownIconSetColor from "../../common/SortDownIconSetColor.jsx";
import AlarmIconSetColor from "../../common/AlarmIconSetColor.jsx";
import { selectedTimezone } from "../../../model/slice/timezoneSlice.js";
import { formatDate } from "../../../util/timezone.js";

const pageButtonBase = {
  width: "100%",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "10px",
};

const settingHolder = {
  padding: "10px 0px",
  backgroundColor: "rgba(44, 58, 72, 1.0)",
  position: "absolute",
  color: "#ffffff",
  fontSize: "12px",
};

const useStyles = makeStyles((theme) => ({
  headerHolder: {
    height: "38px",
    backgroundColor: "#2c3a48",
    "& .MuiTableCell-root": {
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
      color: "#ffffff",
      [theme.breakpoints.down('sm')]: {
        fontSize: "10px",
      },
    },
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
  columnHeader: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  dataHolder: {
    "& .MuiTableCell-root": {
      fontSize: "12px",
      fontWeight: "300",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
      color: "#ffffff",
      [theme.breakpoints.down('sm')]: {
        fontSize: "8px",
      },
    },
  },
  noAlarmStyle: {
    paddingTop: "50px",
    fontSize: "16px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pageButton: {
    ...pageButtonBase,
    "& .MuiSvgIcon-root": {
      color: "#FFFFFF",
    },
  },
  disabledPageButton: {
    ...pageButtonBase,
    "& .MuisvgIcon-root": {
      color: "rgba(255,255,255,0.3)",
    },
  },
  buttonGrid: {
    justifyContent: "center",
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  backButtonAdjustment: {
    "& .MuisvgIcon-root": {
      paddingLeft: "13px",
    },
  },
  japaneseTableTitle: {
    whiteSpace: "nowrap",
  },
  controlRow: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "inline-block",
    [theme.breakpoints.down('sm')]: {
      height: "10px",
      width: "10px",
    },
  },
  userSettingHolder: {
    ...settingHolder,
    top: "44px",
    width: "250px",
    right: "0",
    [theme.breakpoints.down('md')]: {
      top: "38px",
      right: "5%",
    },
    [theme.breakpoints.down('sm')]: {
      top: "auto",
      bottom: "8%",
      right: "8%",
      position: "fixed",
    },
  },
  settingsTable: {
    width: "100%",
    borderSpacing: "0",
  },
  settingRow: {
    height: "35px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(38, 48, 59) !important",
    },
    "& td": {
      paddingLeft: "10px",
    },
  },
  settingRowForHR: {
    height: "10px",
    cursor: "pointer",
  },
  selectColor: {
    backgroundColor: "rgb(33, 38, 47) !important",
  },
  mobileSort: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "8px",
    position: "fixed",
    right: "30px",
    bottom: "60px",
    width: "30px",
    height: "30px",
    background: "#2c3a48",
    "&:hover": {
      background: "#2c3a48",
    },
  },
  mobileSortIcon: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "inline-block",
  },
  mobileRestSort: {
    marginTop: "10px",
    width: "60%",
    height: "30px",
    borderRadius: "5px 5px 5px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#21262f",
    "&:hover": {
      background: "#21262f",
    },
  },
  settingRowMobile: {
    height: "35px",
    cursor: "pointer",
  },
  mobileCloseIcon: {
    width: "20px",
    borderRadius: "5px 5px 5px 5px",
    display: "flex",
    justifyContent: "right",
  },
  arrowicon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rowLeft: {
    width: "25px",
    "& > img": {
      position: "relative",
      left: "3px",
    },
  },
  rowLeftForCalender: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
  },
  whiteText: {
    color: '#bec2c5',
  },
}));

// https://material-ui.com/components/tables/
// material ui table sample
const AlarmHistoryList = memo(({ t, vesselId, i18n, height }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const alarmHistoryList = useSelector(historyAlarmSelector);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(constants.ALARM_HEADER_ID.OCCURRENCE); //set the default sort
  const dispatch = useDispatch();
  const filteredHistoryAlarmList = useSelector(filteredAlarmSelector);
  const isLoading = useSelector(isHistoryAlarmLoading);
  const searchButtonFlag = useSelector(getSearchButtonSelector);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isDisplaySortDialog, setIsDisplaySortDialog] = useState(false);
  const menuRef = useRef(null);
  const tableRef = useRef(null);
  const currentWinPos = useSelector(currentWindowPosition);
  const currentTablePos = useSelector(currentTablePosition);
  const page = useSelector(currentHistoryPage);
  const timezone = useSelector(selectedTimezone);
  const setPage = (d) => dispatch(setCurrentHistoryPage(d));
  let updateFlag = true;

  useEffect(() => {
    dispatch(fetchAlarmHistory({ vesselId }));
    const interval = setInterval(() => dispatch(fetchAlarmHistory({ vesselId })), 1000 * 60);
    const windowScrollHandler = () => {
      const y = window.scrollY;
      if (y !== 0) dispatch(setCurrentWindowPosition(y));
    };
    window.addEventListener("scroll", windowScrollHandler);

    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", windowScrollHandler);
    };
  }, []);

  useEffect(() => {
    setInitialLoad(true);
  }, [vesselId]);

  useOnClickOutside([menuRef], () => {
    setIsDisplaySortDialog(false);
  });

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === constants.ORDER.ASC;
    setOrder(isAsc ? constants.ORDER.DESC : constants.ORDER.ASC);
    setOrderBy(property);
  };

  const ResetSort = () => {
    setOrder("asc");
    setOrderBy(constants.ALARM_HEADER_ID.OCCURRENCE);
  };

  const CloseDialog = () => {
    setIsDisplaySortDialog(false);
  };

  const handleRequestSortForMenu = (property, selectorder) => (event) => {
    setOrder(selectorder);
    setOrderBy(property);
  };

  const displaySortDialog = () => {
    if (isDisplaySortDialog) {
      return (
        <div className={classes.userSettingHolder} ref={menuRef}>
          <table className={classes.settingsTable}>
            <tbody>
              {isXs && (
                <tr className={`${classes.settingRowMobile}`}>
                  <td colSpan="2" align="right" valign="top">
                    <div className={classes.mobileCloseIcon} onClick={CloseDialog}>
                      <img
                        src={CloseIcon}
                        className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
                      />
                    </div>
                  </td>
                </tr>
              )}
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ID && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ID,
                  constants.ORDER.DESC
                )}
              >
                <td className={classes.rowLeft}>
                  <SortDownIconSetColor color={orderBy === constants.ALARM_HEADER_ID.ID && order === constants.ORDER.DESC? "white" : "#bec2c5"} />
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.ID || order !== constants.ORDER.DESC? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortAscendingNo)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ID && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ID,
                  constants.ORDER.ASC
                )}
              >
                <td className={classes.rowLeft}>
                  <SortUpIconSetColor color={orderBy === constants.ALARM_HEADER_ID.ID && order === constants.ORDER.ASC? "white" : "#bec2c5"} />
                </td>
                  <td className={`${orderBy !== constants.ALARM_HEADER_ID.ID || order !== constants.ORDER.ASC? classes.whiteText : ""}`}>
                {t(localizationKeys.SortDescendingNo)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.OCCURRENCE && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.OCCURRENCE,
                  constants.ORDER.ASC
                )}
              >
                <td className={classes.rowLeftForCalender}>
                  <CalenderIconSetColor color={orderBy === constants.ALARM_HEADER_ID.OCCURRENCE && order === constants.ORDER.ASC? "white" : "#bec2c5"} />
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.OCCURRENCE || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortByNewestOccurence)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.OCCURRENCE && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.OCCURRENCE,
                  constants.ORDER.DESC
                )}
              >
                <td className={classes.rowLeftForCalender}>
                  <CalenderIconSetColor color={orderBy === constants.ALARM_HEADER_ID.OCCURRENCE && order === constants.ORDER.DESC? "white" : "#bec2c5"} />
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.OCCURRENCE || order !==  constants.ORDER.DESC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortByOldestOccurence)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ALARM_NAME && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ALARM_NAME,
                  constants.ORDER.DESC
                )}
              >
                <td className={`${classes.rowLeft} ${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                  &ensp;A
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortAlarmItemAZ)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ALARM_NAME && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ALARM_NAME,
                  constants.ORDER.ASC
                )}
              >
                <td className={`${classes.rowLeft} ${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  &ensp;Z
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortAlarmItemZA)}
                </td>
              </tr>
              <tr className={`${classes.settingRowForHR}`}>
                <td>
                  <hr />
                </td>
                <td>
                  <hr />
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.UNRESOLVEDALARMS &&
                  order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.UNRESOLVEDALARMS,
                  constants.ORDER.ASC
                )}
              >
                <td className={classes.rowLeft}>
                  <AlarmIconSetColor color={orderBy === constants.ALARM_HEADER_ID.UNRESOLVEDALARMS && order === constants.ORDER.ASC? "white" : "#bec2c5"} />
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.UNRESOLVEDALARMS || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.ShowUnresolvedAlarms)}
                </td>
              </tr>
              {isXs && (
                <tr className={`${classes.settingRowMobile}`}>
                  <td colSpan="2" align="center">
                    <div className={classes.mobileRestSort} onClick={ResetSort}>
                      <img
                        src={ResetIcon}
                        className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
                      />
                      {t(localizationKeys.ResetSort)}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  const EnhancedTableHeader = () => {
    return (
      <TableHead>
        <TableRow className={classes.headerHolder}>
          {alarmHistoryListHeader(t)
            .filter((a) => isMdUp || !a.hideOnSmallDevice)
            .map((d, i) => (
              <TableCellStyled
                key={`alarm-history-table-header-${i}`}
                align={d.align}
                sortDirection={(d.sortable && orderBy) === d.id ? order : false}
                onClick={handleRequestSort(d.id)}
                style={d.style}
                className={classes.columnHeader}
              >
                <span
                  className={clsx(
                    i18n?.language !== "en-US" &&
                      [
                        constants.ALARM_HEADER_ID.DATA,
                        constants.ALARM_HEADER_ID.OCCURRENCE,
                        constants.ALARM_HEADER_ID.RECOVERY,
                      ].includes(d.id) &&
                      classes.japaneseTableTitle
                  )}
                >
                  {d.name}
                </span>
                {orderBy === d.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === constants.ORDER.DESC ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
                <TableSortLabel
                  active={orderBy === d.id}
                  direction={orderBy === d.id ? order : "asc"}
                />
              </TableCellStyled>
            ))}
          {!isXs && (
            <TableCellStyledForFunction
              style={{ maxWidth: "30px" }}
              onClick={() => {
                setIsDisplaySortDialog(!isDisplaySortDialog);
              }}
            >
              <img
                src={
                  order === "asc" && orderBy === constants.ALARM_HEADER_ID.OCCURRENCE
                    ? SortIcon
                    : SortIconPrimary
                }
                className={`${classes.controlRow} ${classes.columnHeader}`}
              />
            </TableCellStyledForFunction>
          )}
          {!isXs && (
            <TableCellStyledForFunction style={{ maxWidth: "30px" }} onClick={ResetSort}>
              <img
                src={
                  order === "asc" && orderBy === constants.ALARM_HEADER_ID.OCCURRENCE
                    ? ResetIcon
                    : ResetIconPrimary
                }
                className={`${classes.controlRow} ${classes.columnHeader}`}
              />
            </TableCellStyledForFunction>
          )}
        </TableRow>
      </TableHead>
    );
  };

  const tableBody = useCallback((row, i) => (
    <TableRowStyled key={`alarm-history-list-${i}`} className={classes.dataHolder}>
      {isMdUp && <TableCellStyled align="left">{row.channelNo}</TableCellStyled>}
      <TableCellStyled align="left">{row[constants.AMS_CHANNEL_COLUMN]}</TableCellStyled>
      {/* if there are value (ex. Low, High atc...), it will be displayed, there are no value, alarm status will be displayed */}
      <TableCellStyled align="center">{row.value}</TableCellStyled>
      {/* occurrance time is datetime in iAPI */}
      <TableCellStyled align="left">
        {row?.dateTime
          ? formatDate(row.dateTime, timezone, constants.ALARM_DATE_FORMAT)
          : constants.INVALID_DATE}
      </TableCellStyled>
      <TableCellStyled align="left">
        {row?.recoveryDate
          ? formatDate(row.recoveryDate, timezone, constants.ALARM_DATE_FORMAT)
          : constants.INVALID_DATE}
      </TableCellStyled>
    </TableRowStyled>
  ), [isMdUp, timezone]);

  // for update filtered alarm history list. if there are no filter settings, it will be return alarm history list.
  const displayData = useCallback(() => {
    if (searchButtonFlag) {
      return filteredHistoryAlarmList?.length ? filteredHistoryAlarmList : null;
    } else if (alarmHistoryList?.length) {
      return alarmHistoryList;
    } else {
      return null;
    }
  }, [searchButtonFlag, alarmHistoryList, filteredHistoryAlarmList]);
  //}, [filteredHistoryAlarmList, alarmHistoryList, hasActiveFilter]);

  useEffect(() => {
    const table = document.getElementById("dataGrid");
    const tableScrollHandler = () => {
      const y = table.scrollTop;
      if (y !== 0) dispatch(setCurrentTablePosition(y));
    };

    if (selectUnresolvedAlarms(displayData())?.length === 0 || isSmDown) setPage(0);
    else if (
      searchButtonFlag &&
      Math.floor(filteredHistoryAlarmList?.length / constants.ROWS_PER_PAGE) < page
    ) {
      setPage(Math.floor(filteredHistoryAlarmList?.length / constants.ROWS_PER_PAGE));
    }
    if (updateFlag) {
      updateFlag = false;
      table.addEventListener("scroll", tableScrollHandler);
    }
    window.scrollTo(0, currentWinPos);
    table.scrollTo(0, currentTablePos);

    return () => {
      table.removeEventListener("scroll", tableScrollHandler);
    };
  }, [displayData()]);

  const selectUnresolvedAlarms = (array) => {
    if (!array) return array;
    if (orderBy === constants.ALARM_HEADER_ID.UNRESOLVEDALARMS) {
      const result = array.filter(function (value) {
        return !value.recoveryDate;
      });
      return result;
    } else {
      return array;
    }
  };

  const DisplayAlarmHistoryList = () => {
    if (initialLoad && isLoading) {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={isMdUp ? 5 : 4} align="center">
            <CircularProgress />
          </TableCellStyled>
        </TableRowStyled>
      );
    } else if (selectUnresolvedAlarms(displayData()) !== null) {
      return (
        isSmDown
          ? stableSort(
              selectUnresolvedAlarms(displayData()),
              getComparator(order, orderBy),
              orderBy
            )
          : stableSort(
              selectUnresolvedAlarms(displayData()),
              getComparator(order, orderBy),
              orderBy
            ).slice(
              page * constants.ROWS_PER_PAGE,
              page * constants.ROWS_PER_PAGE + constants.ROWS_PER_PAGE
            )
      ).map((row, i) => tableBody(row, i));
    } else if (isLoading) {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={isMdUp ? 5 : 4} align="center">
            <CircularProgress />
          </TableCellStyled>
        </TableRowStyled>
      );
    } else {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={isMdUp ? 5 : 4} align="center" className={classes.noAlarmStyle}>
            {t(localizationKeys.NoAlarmHistory_upper)}
          </TableCellStyled>
        </TableRowStyled>
      );
    }
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container alignItems="stretch" style={{ display: "flex" }} justifyContent="center">
      <Grid item sm={12} style={{ width: "100%" }}>
        <TableContainer
          id="dataGrid"
          style={{
            borderRadius: "8px",
            height: isMdUp ? "100%" : `calc(100vh - ${height}px)`,
            minHeight: "400px",
            position: "relative",
          }}
        >
          <Table aria-label="sticky table" ref={tableRef}>
            <EnhancedTableHeader />
            <TableBody>
              <DisplayAlarmHistoryList />
            </TableBody>
          </Table>
          {isXs && (
            <div
              className={classes.mobileSort}
              onClick={() => {
                setIsDisplaySortDialog(!isDisplaySortDialog);
              }}
            >
              <MobileSortIcon
                color={orderBy == null ? "white" : "#19B2AB"}
                className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
              />
            </div>
          )}
          {displaySortDialog()}
        </TableContainer>
        {isMdUp && (
          <div className={classes.arrowicon}>
            <Grid className={classes.arrowicon}>
              <PageButton
                type={constants.PAGE_BUTTON.BACK}
                page={page}
                setPage={setPage}
                displayData={selectUnresolvedAlarms(displayData())}
                classes={classes}
                isLoading={isLoading}
              />
              <TablePagination
                component="div"
                sx={paginationStyle}
                count={selectUnresolvedAlarms(displayData())?.length ?? 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={constants.ROWS_PER_PAGE}
                rowsPerPageOptions={[]}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from} - ${to} ${t(localizationKeys.OutOf_lower)} ${count !== -1 ? count : 0}`
                }
              />
              <PageButton
                type={constants.PAGE_BUTTON.FORWARD}
                page={page}
                setPage={setPage}
                displayData={selectUnresolvedAlarms(displayData())}
                classes={classes}
                isLoading={isLoading}
              />
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
});

AlarmHistoryList.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(AlarmHistoryList);
