import React from "react";
import PropTypes from "prop-types";

import withStyles from '@mui/styles/withStyles';

import {
  RW_TW_LIST,
} from "../../model/slice/windowDirectionTypeSlice";

//Image imports
import WindPointer from "../../../assets/images/compass/readings-compass-apparent-wind.svg";
import WindPointerTrue from "../../../assets/images/compass/readings-compass-true-wind.svg";

const styles = () => ({
  pointerHolder: {
    position: "relative",
    textAlign: "center",
  },
  windPointer: {
    "& img": {
      height: "80%",
      width: "80%",
    },
    position: "absolute",
    top: "6%",
    left: "0",
    right: "0",
    margin: "0 auto",
    height: "100%",
    width: "100%",
  },
  windPointerTrue: {
    "& img": {
      height: "90%",
      width: "90%",
    },
    position: "absolute",
    top: "-8%",
    left: "0",
    right: "0",
    margin: "0 auto",
    height: "100%",
    width: "100%",
  },
});

const WindIndicator = (props) => {
  const [rotation, setRotation] = React.useState(props.rotation); //Default rotation

  React.useLayoutEffect(() => {
    setRotation(props.rotation);
  }, [props.rotation]);

  return (
    <React.Fragment>
      <div
        className={props.classes.pointerHolder}
        style={{
          height: `100%`,
          width: `100%`,
          transform: `rotate(${rotation}deg)`,
        }}
      >
        <div
          className={
            props.pointerType == RW_TW_LIST[0].name
              ? props.classes.windPointer
              : props.classes.windPointerTrue
          }
        >
          <img
            src={props.pointerType == RW_TW_LIST[0].name ? WindPointer : WindPointerTrue}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

WindIndicator.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  rotation: PropTypes.number,
  pointerType: PropTypes.string,
};

export default withStyles(styles)(WindIndicator);
