import React, { memo, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import dayjs from "../../../util/dayjs-init.js";

import { fetchVesselAsync } from "../../../model/async/vesselAsync.js";
import { fetchVesselLatestNavigationData } from "../../../api/latestData.js";
import { fetchChannelModAsync } from "../../../model/async/channelAsync.js";

import { currentVesselSelector } from "../../../model/slice/vesselSlice.js";
import { latestEngineData } from "../../../model/slice/fleetEngineDataSlice.js";
import { channelModData } from "../../../model/slice/channelSlice.js";
import { useTaggedCargoLatestData } from "../../cargo/useLatestCargoData.js";

import { NAVIGATION_BAR_HEIGHT } from "../../../layout/panelLayout.jsx";
import { COLORS } from "../../../constants/colors.js";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { VESSEL_TYPE } from "../../../constants/constants.js";
import { CARGO_PAGES } from "../../../constants/cargo/pages.js";

import VesselOverviewDetails from "./details/details.jsx";
import Temperature from "./temperature/temperature.jsx";
import Alarm from "./alarm/alarm.jsx";
import Trend from "./trend/trend.jsx";
import EngineMonitoring from "./monitoring/engine.jsx";
import ElectricMonitoring from "./monitoring/electric.jsx";
import EngineData from "./engineData/engineData.jsx";
import Navigation from "./navigation/navigation.jsx";
import SepData from "./engineData/sepData.jsx";
import Cargo from "./cargo/cargo.jsx";
import CargoGauge from "./cargo/gauge.jsx";
import OverviewCargoStatus from "./cargo/status.jsx";
import { DEVICE_TYPE, detectDevice } from "../../../constants/detectDevice.js";
import { selectedTimezone } from "../../../model/slice/timezoneSlice.js";
import { TIMEZONE } from "../../../constants/timezone.js";
import { fleetNavigationData } from "../../../model/slice/fleetNavigationDataSlice.js";
import { isVesselOffline } from "../../../constants/util";
import { logger } from "../../../api/logger";

const containerHeight = `calc(100dvh - ${NAVIGATION_BAR_HEIGHT}px)`;
const bodyContainerHeight = `calc(100dvh - ${NAVIGATION_BAR_HEIGHT}px - 50px - 22px)`;

const styles = (theme) => ({
  container: {
    position: "fixed",
    width: "calc(50vw - 60px)",
    [theme.breakpoints.down('lg')]: {
      width: "calc(75vw - 60px)",
    },
    height: containerHeight,
    minHeight: containerHeight,
    maxHeight: containerHeight,
    right: "0px",
    top: "50px",
    backgroundImage: "linear-gradient(to left, rgba(44, 58, 72, 0.8), rgba(44, 58, 72, 0.3))",
    color: COLORS.white,
  },
  vesselPointerContainer: {
    position: "absolute",
    top: "calc(50dvh)",
    right: "calc(50vw - 60px)",
    [theme.breakpoints.down('lg')]: {
      right: "calc(75vw - 60px)",
    },
    width: "40px",
    overflow: "hidden",
  },
  vesselPointer: {
    width: "50px",
    height: "50px",
    transform: "rotate(-45deg) skew(25deg, 25deg)",
    backgroundColor: "rgba(44, 58, 72, 0.3)",
    marginLeft: "25px",
  },
  titleContainer: {
    height: "60px",
    background: COLORS.componentBackColor,
    boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.5) !important",
    padding: "20px 40px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
  },
  updateDate: {
    position: "absolute",
    right: "40px",
    color: COLORS.greenishBlue,
    fontSize: "10px",
  },
  bodyContainer: {
    padding: "0 20px",
    height: bodyContainerHeight,
    minHeight: bodyContainerHeight,
    maxHeight: bodyContainerHeight,
    overflowY: "auto",
    marginTop: "12px",
  },
  components: {
    margin: "0 0 10px 0",
    padding: "20px",
    background: COLORS.componentBackColor,
    height: "fit-content",
    borderRadius: "6px",
    border: "0",
  },
  vesselOfflineContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
  },
  offline: {
    color: "#ff5252 !important",
    fontWeight: "bold",
    fontStyle: "normal",
    fontsize: "10px !important",
    fontStretch: "normal",
    fontHeight: "normal",
    letterSpacing: "normal",
    fontFamily: "Inter",
    textOverflow: "ellipsis",
    // overflowWrap: "normal",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    // [theme.breakpoints.down("sm")]: {
    //   marginLeft: "10px"
    // }
  },
});

export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm:ss";

const getLatestDatetime = (data, timezone) => {
  return timezone === TIMEZONE.UTC 
    ? dayjs.utc(Math.max(...data.map(e => new Date(e.dateTime)))).format(DATE_TIME_FORMAT)
    : dayjs.utc(Math.max(...data.map(e => new Date(e.dateTime)))).local().format(DATE_TIME_FORMAT);
}

const VesselOverview = memo(({ vessel, classes, t }) => {
  const dispatch = useDispatch();
  const isTouchDevice = [DEVICE_TYPE.PHONE, DEVICE_TYPE.TABLET].includes(detectDevice);
  const [navigationData, setNavigationData] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSelectedVesselSEP, setIsSelectedVesselSEP] = useState(false);
  const currentVessel = useSelector(currentVesselSelector);
  const engineData = useSelector(latestEngineData(vessel.vessel_id));
  const latestData = useSelector(channelModData);
  const { taggedLatestData } = useTaggedCargoLatestData(latestData);
  const timezone = useSelector(selectedTimezone);
  const isPC = detectDevice === DEVICE_TYPE.PC;
  const vesselList = useSelector(fleetNavigationData);
  const isOffline = useMemo(() => {
    try {
      if (vesselList?.fleetNavigationData.length === 1) {
        return isVesselOffline(
          vesselList?.fleetNavigationData?.[0],
          engineData
        );
      } else {
        return isVesselOffline(
          vesselList?.fleetNavigationData?.find((d) => d?.vessel_id === currentVessel.id),
          engineData
        );
      }
    } catch (e) {
      logger.error(`isOffline error: ${e}`);
      return true;
    }
  }, [currentVessel, vesselList]);

  useEffect(async () => {
    setIsInitialLoad(true);

    if (!vessel?.vessel_id) return;

    dispatch(fetchVesselAsync({ vesselId: vessel.vessel_id }));

    const { latestNavigationData } = await fetchVesselLatestNavigationData(vessel.vessel_id);
    setNavigationData(latestNavigationData);

  }, [vessel?.vessel_id]);

  useEffect(() => {
    if (!currentVessel) return;
    setIsInitialLoad(false);
    setIsSelectedVesselSEP(currentVessel.vesselType === VESSEL_TYPE.SEP);

    if (currentVessel.NoTank > 0) {
      dispatch(fetchChannelModAsync({ vesselId: currentVessel.id }));
    }
  }, [currentVessel])

  return (
    <div>
      <Grid container className={classes.container} style={isTouchDevice ? { paddingBottom: "75px" } : {}}>
        <Grid item xs={12} className={classes.titleContainer}>
          <Grid container>
            <Grid item xs={5} className={classes.title}>
              {t(localizationKeys.VesselDetailsOverview)}
            </Grid>
            <Grid item xs={3} className={classes.vesselOfflineContainer}>
              {isOffline && isPC ? (
                <span className={classes.offline}>
                  {t(localizationKeys.Disconnected_upper)}
                </span>
              ) : null}
            </Grid>
            <Grid item xs={4} className={classes.dateContainer}>
              <div className={classes.updateDate}>
                {`${t(localizationKeys.LastUpdate_upper)}:`} 
                {navigationData ? getLatestDatetime(navigationData, timezone) : '-'} 
                {timezone === TIMEZONE.UTC ? ` UTC` : ` LT`} 
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.bodyContainer}>
          <VesselOverviewDetails style={classes} vessel={vessel} navigationData={navigationData} />
          <Navigation style={classes} vessel={vessel} navigationData={navigationData} />
          {currentVessel && currentVessel?.id && <Grid container columnSpacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={6}>
              <Temperature vessel={currentVessel} navigationData={navigationData} style={classes} />
            </Grid>
            <Grid item xs={6}>
              {currentVessel.NoTank > 0 ?
                <CargoGauge page={CARGO_PAGES.HANDLING} taggedLatestData={taggedLatestData} isVesselOverview={true} classes={classes} /> :
                isSelectedVesselSEP ?
                  <SepData vessel={currentVessel} classes={classes} /> :
                  <EngineData vessel={currentVessel} mainEngineData={engineData?.vesselLatestMainEngineData} isInitialLoad={isInitialLoad} style={classes} />
              }
            </Grid>
          </Grid>}
          <Alarm style={classes} vesselId={vessel?.vessel_id} />
          {currentVessel && currentVessel?.id && <Trend style={classes} vessel={currentVessel} isSepVessel={isSelectedVesselSEP} t={t} />}
          {currentVessel && currentVessel?.id && currentVessel?.NoME > 0 && <EngineMonitoring style={classes} vessel={currentVessel} t={t} />}
          {currentVessel && currentVessel?.id && (currentVessel?.NoDG > 0 || currentVessel?.NoSG > 0) && <ElectricMonitoring style={classes} vessel={currentVessel} t={t} />}
          {currentVessel && currentVessel?.id && currentVessel?.NoTank > 0 && (
            <>
              <OverviewCargoStatus taggedLatestData={taggedLatestData} classes={classes} />
              <Cargo taggedLatestData={taggedLatestData} classes={classes} />
            </>
          )}
        </Grid>
      </Grid>
      <div className={classes.vesselPointerContainer}>
        <div className={classes.vesselPointer} />
      </div>
    </div>
  );
});

VesselOverview.propTypes = {
  vessel: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(VesselOverview);