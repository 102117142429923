import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";
import dayjs from 'util/dayjs-init.js';
import { ReleaseNoteModal } from "./releaseNoteModal";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys";
import * as PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';

import { releaseNoteAllEn } from "../../i18n/releaseNotes/releaseNoteAllEn";

const useStyles = makeStyles({
  whatsNewBtn: {
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    padding: "5px 10px",
    float: "right",
  },
  whatsNewBtnClicked: {
    backgroundColor: "#28b6c780",
    border: "solid 2px #28b6c7",
    borderRadius: "5px",
  }
});

const RELEASE_VERSION = releaseNoteAllEn[0][0].version;
const RELEASE_DATE = dayjs(releaseNoteAllEn[0][0].releaseDate);
const UPDATED_DATE = dayjs(releaseNoteAllEn[0][0].updatedDate);
const DISPLAY_DAYS = 30;

const WhatsNew = ({
  selectedLanguage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  useEffect(() => {
    const now = dayjs();
    setShowButton(now.isBefore(UPDATED_DATE.add(DISPLAY_DAYS, "day")));
  }, []);

  const onButtonClick = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  return (
    <Grid style={{ position: "absolute", display: "flex", right: isSm ? "295px" : "350px" }}>
      {showButton && (
        <button
          onClick={onButtonClick}
          className={`${classes.whatsNewBtn} ${showModal ? classes.whatsNewBtnClicked : ""}`}
        >
          <span style={{fontWeight: "900", fontSize: "16px", color: "#28b6c7", marginRight: "5px"}}>!</span>
          <span>{t(localizationKeys.WhatsNew_lower)}</span>
        </button>
      )}

      <ReleaseNoteModal
        when={showModal}
        onCancel={onModalClose}
        version={RELEASE_VERSION}
        releaseDate={RELEASE_DATE}
        updatedDate={UPDATED_DATE}
        selectedLanguage={selectedLanguage}
      />
    </Grid>
  );
};

WhatsNew.propTypes = {
  selectedLanguage: PropTypes.string,
}

export default WhatsNew;