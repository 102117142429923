import React from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import { toPng } from "html-to-image";
import download from "downloadjs";

import CameraIcon from "./cameraIcon.jsx";
import dayjs from 'util/dayjs-init.js';
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType.js";
import { DEVICE_TYPE, detectDevice } from "../../../constants/detectDevice.js";
import { formatDate } from "../../../util/timezone.js";
import { selectedTimezone } from "../../../model/slice/timezoneSlice.js";

const useStyles = makeStyles((theme) => ({
  cameraIcon: {
    width: "25px",
    height: "25px",
    padding: "0px 0px",
    minWidth: "0px",
  },
}));

const DisplayScreenshot = React.memo(
  ({
    isDurationDisabled,
    isNoData,
    element,
    vesselName,
    graphType,
    selectedGraph,
    selectedEngine,
    isTwoGraph = false,
    isCargo = false,
    isLoading = false,
    marginRight = "10px",
  }) => {
    const classes = useStyles();
    const timezone = useSelector(selectedTimezone);
    const filename = () => {
      const date = `${formatDate(dayjs(), timezone, "YYYY-MM-DD-HH-mm-ss")}(${timezone})`;
      if (isCargo) {
        return `Cargo_${date}`;
      } else {
        return isTwoGraph
          ? `Comparison_${date}`
          : graphType === GRAPH_TYPE.ELECTRIC
          ? `${vesselName.replace(/\s/g, "-")}_${graphType}_${date}`
          : `${vesselName.replace(/\s/g, "-")}_${graphType}_${
              selectedEngine ? `${selectedEngine.split("_")[0]}_` : ""
            }${selectedGraph}_${date}`;
      }
    };
    const capture = () => {
      toPng(element, {
        cacheBust: true,
        backgroundColor: "rgba(36, 44, 55)",
      }).then((dataUrl) => {
        download(dataUrl, `${filename()}.png`);
      });
    };

    const captureCargo = () => {
      toPng(element, {
        cacheBust: true,
        backgroundColor: "rgba(36, 44, 55)",
        height: "1800",
      }).then((dataUrl) => {
        download(dataUrl, `${filename()}.png`);
      });
    };

    const shouldBeDisabled = isDurationDisabled || isNoData || isLoading;
    return (
      <Button className={classes.cameraIcon} style={{ marginRight: marginRight }} disabled={shouldBeDisabled} onClick={() => (isCargo ? captureCargo() : capture())}>
        <CameraIcon 
          color={shouldBeDisabled ? "#FFFFFF" : "#D8D8D8"}
          opacity={shouldBeDisabled ? "0.5" : "1"} />
      </Button>
    );
  }
);

const Screenshot = React.memo((props) => {
  const isTouchDevice = [DEVICE_TYPE.PHONE, DEVICE_TYPE.TABLET].includes(detectDevice);

  return !isTouchDevice && <DisplayScreenshot {...props} />
});

export default Screenshot;
