export const mockDataAvg = {
  1: 71.97169811320755,
  2: 45.867924528301884,
  3: 129.33962264150944,
  4: 65.00943396226415,
  5: 69.83962264150944,
  6: 70.33962264150944,
  7: 71.04716981132076,
  8: 71.83018867924528,
  9: 71.69811320754717,
  10: 71.36792452830188,
  11: 45.83018867924528,
  12: 46.68867924528302,
  13: 46.783018867924525,
  14: 46.783018867924525,
  15: 47.075471698113205,
  16: 46.594339622641506,
}