import React, { useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { useDetectVesselConnection } from "../../../hooks/useDetectVesselConnection";
import { Grid } from "@mui/material";
import { useDurationHandle } from "../calendarDuration/useDurationHandle";
import { DURATION } from "../calendarDuration/durationOption";
import EngineTrendBase from "../engine/engineTrendBase.jsx";
import * as PropTypes from "prop-types";
import { useCustomData } from "./channelData";
import { DEFAULT_LINE_COLORS } from "../../../constants/trendGraph/linesDefaultColors";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType";

const Custom = (props) => {
  const {
    vesselId,
    currentVessel,
    selectedChart,
    graphData,
    customCharts,
    channels,
    isCustomChartsCreated,
    setGraphData,
    setDataFetchStatus,
    layoutStyle,
    toggleVariables,
  } = props;

  const { connections } = useDetectVesselConnection();
  const isDisconnected = connections.includes(vesselId);

  const chartLines = useMemo(() => {
    if (!customCharts?.length || !channels?.length) return;

    const lines = customCharts.find((ch) => ch.id === selectedChart)?.lines;

    return lines
      ?.filter((line) => channels.some((ch) => ch.chNo === line.chNo))
      ?.map((line, idx) => {
        const channel = channels.find((ch) => ch.chNo === line.chNo);
        return {
          key: `"${line.chNo}"`,
          keyName: `"${line.chNo}"`,
          name: channel.chName,
          color: line.color ?? DEFAULT_LINE_COLORS[idx],
          enableVisibilityIcon: "hidden",
          enable: true,
          channelType: channel.chType,
          digitalVar: line?.digitalVar,
        };
      });
  }, [customCharts, channels, selectedChart]);

  const { customValues, isLoading, isNoData } = useCustomData({
    vesselId,
    chartId: selectedChart,
    isCustomChartsCreated,
  });

  useDurationHandle(DURATION.SEP, null);
  useEffect(() => {
    setDataFetchStatus({
      isLoading,
      isNoData,
    });
  }, [isLoading, isNoData]);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EngineTrendBase
            layoutStyle={layoutStyle}
            values={customValues}
            isDurationDisabled={isLoading}
            isNoData={isNoData}
            isDisconnected={isDisconnected}
            vesselId={vesselId}
            vessel={currentVessel}
            isLoading={isLoading}
            selectedChart={selectedChart}
            graphType={GRAPH_TYPE.CUSTOM}
            graphData={graphData}
            setGraphData={setGraphData}
            customChartLines={chartLines}
            toggleVariables={toggleVariables}
            isCustomCharts={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Custom.propTypes = {
  vesselId: PropTypes.string,
  selectedChart: PropTypes.string,
};

export default withTranslation()(Custom);
