import React from "react";

import { Modal } from "antd";
import { styled } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import makeStyles from '@mui/styles/makeStyles';
import DigitalIcon from "../../../../../assets/images/custom/digital_icon.svg";
import CloseIcon from "../../../../../assets/images/custom/close_icon.svg";

const useStyles = makeStyles({
  modal: {
    backgroundColor: "#161a1e",
    color: "#ffffff",
    paddingBottom: 0,
    top: "165px", 
    "& .ant-modal-content": {
      backgroundColor: "unset",
      textAlign: "center",
      padding: "76px 120px",
    },
    "& .ant-modal-header": {
      backgroundColor: "unset",
      "& .ant-modal-title": {
        color: "#ffffff",
        fontSize: "24px",
        fontWeight: "400"
      },
    },
    "& .ant-modal-footer": {
      textAlign: "center",
      marginTop: "60px",
    },
  },
  unselected: {
    marginTop: "32px",
    width: "176px",
    backgroundColor: "#28b6c74c",
    borderRadius: "10px",
    borderStyle: "none"
  },
  selectedStripe: {
    display: "inline-block",
    height: "30px",
    backgroundColor: "#28b6c7"
  },
  unselectedStripe: {
    display: "inline-block",
    height: "30px",
    backgroundColor: "#28b6c74c"
  },
  varTextArea: {
    marginTop: "40px",
    textAlign: "left"
  },
  selectedText: {
    display: "inline-block",
    width: "80px",
    fontSize: "24px",
    color: "#ffffff",
    textAlign: "center"
  },
  unselectedText: {
    display: "inline-block",
    width: "80px",
    fontSize: "16px",
    color: "#ffffff",
    textAlign: "center"
  },
  selectedToggleText: {
    textTransform: "none",
    color: "#ffffff",
    fontSize: "16px"
  },
  unselectedToggleText: {
    textTransform: "none",
    color: "#ffffffb3",
    fontSize: "16px"
  }
});

const ToggleButton = styled(MuiToggleButton) ({
  "&.Mui-selected, &:hover": {
    backgroundColor: "#28b6c780 !important",
    borderRadius: "10px",
    borderStyle: "none"
  }
});

export function DigitalChVarSelect(props) {
  const { when, onOK, onCancel, chNo, chName, setSelectedVar, selectedVar, varOptions } = props;
  const [var1, var2] = varOptions;
  const classes = useStyles();

  const handleVars = (event, newVar) => {
    setSelectedVar(newVar);
  };

  return (
    <Modal
      title="Which variable should be visible in the graph?"
      open={when}
      cancelText="Cancel"
      okText="Confirm"
      onOk={onOK}
      onCancel={onCancel}
      width={980}
      height={590}
      closeIcon={<img src={CloseIcon} />}
      okButtonProps={{
        type: "primary",
        style: {
          color: "#ffffff",
          fontSize: "16px",
          fontWeight: "500",
          backgroundColor: "#28b6c7",
          borderRadius: "5px",
          marginRight: "50px",
          textAlign: "center",
          width: "172px",
          height: "43px"
        },
      }}
      cancelButtonProps={{
        type: "link",
        style: {
          color: "#19b2ab",
          fontSize: "16px",
          fontWeight: "500",
        },
      }}
      maskClosable={false}
      className={classes.modal}
    >
      <div 
        style={{ 
          display: "inline-block",
          borderRadius: "5px",
          border: "1px solid rgba(132, 235, 128, 0.7)",
          padding: "8px 16px 8px 16px",
          backgroundColor: "#252d39",
          marginTop: "30px"
        }}
      >
        <img src={DigitalIcon} style={{ marginBottom: "-2.5px" }}/>
        <span style={{ marginLeft: "5px", fontSize: "16px" }}> {chNo} </span>
        <span style={{ marginLeft: "5px", fontSize: "16px" }}> {chName} </span>
      </div>
      <div>
        <ToggleButtonGroup
          value={selectedVar}
          exclusive
          onChange={handleVars}
          aria-label="selectedVar"
        >
          <ToggleButton value={var1} aria-label="var1" className={classes.unselected}>
            <div className={selectedVar == var1 ? classes.selectedToggleText : classes.unselectedToggleText}>Show "{var1}"</div>
          </ToggleButton>
          <ToggleButton value={var2} aria-label="var2" className={classes.unselected}>
            <div className={selectedVar == var2 ? classes.selectedToggleText : classes.unselectedToggleText}>Show "{var2}"</div>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.varTextArea}>
        <span className={selectedVar == var1 ? classes.selectedText : classes.unselectedText}>{var1}</span>
        <span className={selectedVar == var2 ? classes.selectedText : classes.unselectedText}>{var2}</span>
      </div>
      <div 
        style={{ width: "80px" }} 
        className={selectedVar == var1 ? classes.selectedStripe : classes.unselectedStripe}>
      </div>
      <div
        style={{ width: "80px" }}
        className={selectedVar == var2 ? classes.selectedStripe : classes.unselectedStripe}>
      </div>
      <div 
        style={{ width: "150px" }} 
        className={selectedVar == var1 ? classes.selectedStripe : classes.unselectedStripe}>
      </div>
      <div
        style={{ width: "130px" }}
        className={selectedVar == var2 ? classes.selectedStripe : classes.unselectedStripe}>
      </div>
      <div 
        style={{ width: "120px" }} 
        className={selectedVar == var1 ? classes.selectedStripe : classes.unselectedStripe}>
      </div>
      <div
        style={{ width: "180px" }}
        className={selectedVar == var2 ? classes.selectedStripe : classes.unselectedStripe}>
      </div>
    </Modal>
  );
}