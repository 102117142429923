//Config for available languages
export const LANGUAGES = ["English", "Japanese", "Chinese"];

//Config for available roles -> For Toyobijin
export const ROLE = ["viewer", "admin", "superUser"];

//Config for cookie auth key where sessoin will be stored
export const COOKIE_AUTH_KEY = "COOKIE_AUTH_KEY";

//Config for initial login lookies displaying user service policy
export const SERVICE_POLICY = "userPolicy";
export const SERVICE_POLICY_SETTING = {
  CONNECT_TRUE: "connect-true",
  CONNECT_FALSE: "connect-false",
};

// Limited 1 file size.
// image file->1MB
// mp4 file->20MB
// PDF file->2MB

// -Limited 1 Tree total file size.
// 96MB
export const IMAGE_LIMIT = 5 * 1024 * 1024;
export const VIDEO_LIMIT = 20 * 1024 * 1024;
export const PDF_LIMIT = 20 * 1024 * 1024;
export const TOTAL_LIMIT = 96 * 1024 * 1024;

export const INITIAL_DATA_DURATION = 7;

export default {};

export const DATE_FORMAT_JST = 'YYYY-MM-DDTHH:mm:ss+09:00'; //ISO date format with JST timezone.
export const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss[.000Z]"; //Date format to be used when converting timestamp to string
export const DURATION_DISPLAY_FORMAT = "MM/DD"; //Date format to be used when display

// for detecting vessel type
export const VESSEL_TYPE = {
  SEP: "SEP",
  VESSEL: "vessel",
};

export const FETCH_INTERVAL = 1000 * 60 * 1; // 1 minute

export const FETCH_CANCEL_MESSAGE = 'Operation canceled due to new request.';

export const MAIN_ENGINE = ["Port", "Starboard", "Center"];

export const NAVIGATION_TYPES = {
  AIS: "AIS",
  GPGGA: "GPGGA",
  GPVTG: "GPVTG",
  WIMWVT: "WIMWVT",
}

export const DEFAULT_VALUE = '';
export const DEFAULT_DASH_VALUE = '-';
