import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { SEP_CHART_TYPE } from "../../constants/trendGraph/sepLines";
import {
  AMOUNT_OF_CRANE,
  AMOUNT_OF_LEGS,
  AMOUNT_OF_THRUSTERS,
  filterCraneData,
  filterTrimHeelData,
} from "../../hooks/useSepTrendData";
import {
  fetchCraneData,
  fetchLegData,
  fetchThrusterData,
  fetchTrimAndHeelData,
} from "../../api/sepTrendData";
import { fetchVessel } from "../../api/vessel.js";
import * as Comlink from "comlink";
import LegDownloadWorker from "./worker/legDownload.worker";
import ThrusterDownloadWorker from "./worker/thrusterDownload.worker";
import ElectricWorker from "../../components/trendPage/electric/worker/electricGraphData.worker";
import CustomWorker from "../../components/trendPage/customGraph/custom.worker";
import { logger } from "../../api/logger";
import { formatDate } from "../../util/timezone.js";
import { DATE_FORMAT, DATE_FORMAT_JST, FETCH_CANCEL_MESSAGE } from "../../constants/constants.js";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType.js";
import { fetchGeneratorEngineData, fetchShaftGeneratorEngineData } from "../../api/baseEngineData.js";
import { timeKey } from "../../constants/timeKey.js";
// import { fetchChannelDataByChartId } from "../../api/channel.js";
// import { formatGraphDataForCSV } from "../../util/trendPage/csvDownload.js";
// import { CHANNEL_TYPE } from "../../constants/channel/config.js";

let cancelToken;

const sortRow = (row) => {
  // Sort object key
  // 1. dateTime, 2. localDateTime, ....
  const tempRow = { ...row };
  const items = Object.entries(tempRow)?.sort((a, b) => {
    switch (true) {
      case a[0] === "dateTime" && b[0] === "localDateTime":
        return -1;
      case a[0] === "localDateTime" && b[0] === "dateTime":
        return 1;
      case a[0] === "localDateTime":
        return -1;
      case a[0] === "dateTime":
        return -2;
      default:
        return 0;
    }
  });
  const returnObject = Object.assign(
    {},
    ...items.map((d) => ({
      [d[0]]: d[1],
    }))
  );
  delete returnObject.id;
  return returnObject;
};

const getHeaders = (data) => {
  const csvHeader = [];
  data.forEach((d) => csvHeader.push(...Object.keys(sortRow(d)).filter(r => r !== "downloadDateTime")));
  return [...new Set(csvHeader)];
};

const getDefaults = (key) =>
  key === "dateTime" ? "2000-01-01T00:00:00.000Z" : "";

const generateDefaultValues = (headers) => {
  return Object.fromEntries(headers.map((h) => [h, getDefaults(h)]));
};

export const createCSV = async (data, formatter, timezone) => {
  const tempData = JSON.parse(JSON.stringify(data));
  return new Promise((resolve) => {
    logger.info("CSV Creation Started.");
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const csvHeader = getHeaders(tempData);
    const defaultValues = generateDefaultValues(csvHeader);
    const filteredRows = tempData
      .filter((d, i) => {
        const filtered = Object.keys(d)
          .filter((key) => !["downloadDateTime", "dateTime", "localDateTime"].includes(key))
          .reduce((obj, key) => {
            obj[key] = d[key];
            return obj;
          }, {});
        return !Object.values(filtered).every((v) => [undefined, null].includes(v));
      })
      .map((d) => {
        if (d["downloadDateTime"]) {
          d.dateTime = d.downloadDateTime;
        }
        delete d.downloadDateTime;
        return Object.values(Object.assign(JSON.parse(JSON.stringify(defaultValues)), sortRow(d))).join(
          formatter
        );
      })
      .join("\n");
    const csv = csvHeader.join(formatter) + "\n" + filteredRows;
    logger.info("CSV Creation Completed.");
    resolve(new Blob([bom, csv], { type: "text/csv" }));
  });
};

export const createCsvDownload = ({ blob, filename }) => {
  const downloadLink = document.createElement("a");
  downloadLink.download = filename + ".csv";
  logger.info(`CSV Filename: ${filename}`);
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.dataset.downloadurl = ["text/csv", downloadLink.download, downloadLink.href].join(
    ":"
  );

  downloadLink.click();
};

const legDataProcess = async (legRawData) => {
  const legWorker = LegDownloadWorker();
  const legWrap = Comlink.wrap(legWorker);
  const legTrendData = await legWrap?.generateDownloadData({ legRawData });
  legWorker.terminate();
  return legTrendData;
};

const thrusterDataProcess = async (thrusterRawData) => {
  const thrusterWorker = ThrusterDownloadWorker();
  const thrusterWrap = Comlink.wrap(thrusterWorker);
  const thrusterTrendData = await thrusterWrap?.generateDownloadData({
    thrusterRawData,
  });
  thrusterWorker.terminate();
  return thrusterTrendData;
};

const electricDataProcess = async ({ geData, sgData, startDate, endDate}) => {
  const worker = ElectricWorker();
  const wrap = Comlink.wrap(worker);
  const newData = await wrap?.createGraphData({
    sgData: sgData,
    geData: geData,
    startDate,
    endDate,
  });
  const key = timeKey(newData);
  newData.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
  worker.terminate();

  return newData;
};

export const customDataProcess = async (rawData) => {
  const worker = CustomWorker();
  const wrap = Comlink.wrap(worker);
  const data = await wrap?.generateData({ rawData });
  return data;
};

const sepOneSecFetch = async ({ startDate, endDate, vesselId, sepChartType }) => {
  let amountLeg = AMOUNT_OF_LEGS;
  let amountThruster = AMOUNT_OF_THRUSTERS;
  let amountCrane = AMOUNT_OF_CRANE;
  const data = await fetchVessel(vesselId);
  if (data.vessel) {
    amountLeg = !data.vessel?.NoLeg ? AMOUNT_OF_LEGS : Number(data.vessel?.NoLeg);
    amountThruster = !data.vessel?.NoThruster
      ? AMOUNT_OF_THRUSTERS
      : Number(data.vessel?.NoThruster);
    amountCrane = !data.vessel?.NoCrane ? AMOUNT_OF_CRANE : Number(data.vessel?.NoCrane);
  }
  switch (sepChartType) {
    case SEP_CHART_TYPE.LEGS: {
      const legResults = await Promise.all(
        [...new Array(amountLeg)].map((d, i) =>
          fetchLegData(vesselId, i + 1, startDate, endDate, 0)
        )
      );
      return await legDataProcess(legResults);
    }
    case SEP_CHART_TYPE.THRUSTER: {
      const thrusterResults = await Promise.all(
        [...new Array(amountThruster)].map((d, i) =>
          fetchThrusterData(vesselId, startDate, endDate, i + 1, 0)
        )
      );
      return await thrusterDataProcess(thrusterResults);
    }
    case SEP_CHART_TYPE.CRANE1: 
    case SEP_CHART_TYPE.CRANE2: {
      const craneResult = await Promise.all(
        [...new Array(amountCrane)].map((d, i) =>
          fetchCraneData(vesselId, i, startDate, endDate, 0)
        )
      );
      const craneReturnData = filterCraneData(craneResult.map(d => d.craneData).flat(), sepChartType === SEP_CHART_TYPE.CRANE1 ? 1 : 2);
      return craneReturnData;
    }
    case SEP_CHART_TYPE.TRIM_AND_HEEL: {
      const trimHeelResult = await fetchTrimAndHeelData(vesselId, startDate, endDate, 0);
      const result = filterTrimHeelData(trimHeelResult?.trimAndHeelData);
      return result;
    }
  }
}

const electricOneSecFetch = async ({ startDate, endDate, vesselId, ge, sg }) => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel(FETCH_CANCEL_MESSAGE);
  }

  cancelToken = axios.CancelToken.source();

  const geData = [];
  if (ge > 0) {
    for(const i of [...Array(ge).keys()]) {
      const data = await fetchGeneratorEngineData({ vesselId, startDate, endDate, interval: 0, generatorNumber: i + 1, cancelToken});
      geData.push(data?.loadingData)
    };
  }

  const sgData = [];
  if (sg > 0) {
    for(const i of [...Array(sg).keys()]) {
      const data = await fetchShaftGeneratorEngineData({ vesselId, startDate, endDate, interval: 0, generatorNumber: i + 1, cancelToken});
      sgData.push(data?.loadingData)
    };
  }

  return electricDataProcess({ geData: geData?.flat(), sgData: sgData?.flat(), startDate, endDate });
}

// const customOneSecFetch = async ({ startDate, endDate, vesselId, sepChartType, customChartSettings }) => {
//   const result = await fetchChannelDataByChartId(vesselId, sepChartType, startDate, endDate, 0);
//   const digitalKeys = [];
//   const config = result.customData?.config;
//   config?.chType?.forEach((c, i) => {
//     if (c === CHANNEL_TYPE.DIGITAL) {
//       digitalKeys.push(`"${config.chNo[i]}"`);
//     }
//   });
//   const processedData = (await customDataProcess(result.customData))
//   ?.map((d) => {
//     let data = d;
//     Object.entries(d).forEach(([k, v]) => {
//       if (!["dateTime", "downloadDateTime"].includes(k)) {
//         data = { ...data, [k]: digitalKeys.includes(k) ? v.status : v.value }
//       }
//     });
//     return data;
//   });
//   const data = formatGraphDataForCSV(processedData, true, customChartSettings);
//   return data;
// };

const oneSecFetch = async (params) => {
  const { sepGraphType } = params;
  switch (sepGraphType) {
    case GRAPH_TYPE.SEP:
      return await sepOneSecFetch(params);
    case GRAPH_TYPE.ELECTRIC:
      return await electricOneSecFetch(params);
    // case GRAPH_TYPE.CUSTOM:
    //   return await customOneSecFetch(params);
  }
};

export const oneSecDownload = async (params) => {
  const { filename, setIsDownloading, timezone } = params;
  try {
    logger.info("One Second Fetch Started.");
    setIsDownloading(true);
    const result = (await oneSecFetch(params)).map(data => ({
      ...data,
      downloadDateTime: formatDate(data.dateTime, timezone, timezone === 'UTC' ? DATE_FORMAT : DATE_FORMAT_JST)
    }));
    const csvData = await createCSV(result);
    createCsvDownload({ blob: csvData, filename });
    logger.info(`One Second Fetch Completed.`);
  } catch (e) {
    logger.error(`One Second Fetch Failed: ${e}`);
  } finally {
    setIsDownloading(false);
  }
};

export const useCsvDownload = ({ data, formatter = "," }) => {
  const [blob, setBlob] = useState(false);
  const unmount = useRef(false);

  const createBlob = async () => {
    const blobData = await createCSV(data, formatter);
    unmount.current || setBlob(blobData);
  };

  useEffect(() => {
    setBlob(false);
    if (!data || !data?.length) {
      return;
    }
    unmount.current || createBlob();
  }, [data]);

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  return {
    blob,
  };
};
