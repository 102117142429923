const getFocUnit = (engineFoUnit) => {
  switch (engineFoUnit) {
    case "l":
      return "kl";
    case "kg":
      return "mt";
    default:
      return engineFoUnit;
  }
};

const focValue = (value, unit) => !isNaN(value) ? (value * (unit === 'l' || unit === 'kg' ? 0.001 : 1)).toFixed(2) : "-";

const getEta = (month, day, hour, minute) => {
  if (month === undefined || day === undefined || hour === undefined || minute === undefined) {
    return;
  }

  const dateNow = new Date();
  const etaYear = dateNow.getFullYear().toString();
  const etaMonth = month.toString().length < 2 ? 0 + month.toString() : month.toString();
  const etaDay = day.toString().length < 2 ? 0 + day.toString() : day.toString();
  const etaHour = hour.toString().length < 2 ? 0 + hour.toString() : hour.toString();
  const etaMinute = minute.toString().length < 2 ? 0 + minute.toString() : minute.toString();

  return `${etaYear}/${etaMonth}/${etaDay} - ${etaHour}:${etaMinute}`;
};

export { getFocUnit, focValue, getEta };