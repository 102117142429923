import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import dayjs from 'util/dayjs-init.js';
import { Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import DataDuration from "./durationController/dataDuration.jsx";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType.js";
import { DURATION } from "./calendarDuration/durationController.jsx";
import { useEngineDataOnce } from "./useEngineDataOnce.js";
import { useSepLatestDataOnce } from "./useSepLatestDataOnce.js";
import { useElectricLatestDataOnce } from "./useGetLatestGeneratorOnce.js";
import { SEP_CHART_TYPE } from "../../constants/trendGraph/sepLines.js";
import {
  fetchChannelDataByChartIdForDurationSelector
} from "../../api/channel.js";
import { DATE_FORMAT } from "../../constants/constants.js";
import { LATEST_DATA_LOAD_STATUS } from "../../constants/trendGraph/dataLoad.js";

const label = {
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#19b2ab",
};

const useStyles = makeStyles((theme) => ({
  paperHeightEngine: {
    height: "100%",
    position: "relative",
  },
  engineSelectorContainer: {
    display: "flex",
    justifyContent: "center",
    "& p": {
      ...label,
    },
  },
  radio: {
    color: "#19b2ab !important",
  },
  typographyLabel: {
    marginLeft: "20px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    "& .MuiTypography-body1": {
      fontSize: "12px !important",
    },
  },
  radioLabel: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    "& .MuiTypography-body1": {
      fontSize: "10px !important",
    },
  },
}));

const DurationSelector = React.memo((props) => {
  const {
    vesselId,
    selectedEngine = [],
    layoutStyle,
    dataFetchStatus,
    selectedGraph,
    chart,
  } = props;
  const styles = useStyles();
  const classes = { ...styles, ...layoutStyle };
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const [disableDataDuration, setDisableDataDuration] = useState(true);
  const [latestData, setLatestData] = useState({});
  const oneMonthAgo = dayjs.utc().subtract(1, "month");

  useEffect(async () => {
    let data = [];
    switch (selectedGraph) {
      case GRAPH_TYPE.ENGINE:
        data = await useEngineDataOnce(vesselId);
        break;
      case GRAPH_TYPE.SEP:
        data = await useSepLatestDataOnce(vesselId);
        break;
      case GRAPH_TYPE.ELECTRIC:
        data = await useElectricLatestDataOnce(vesselId);
        break;
    }
    setLatestData(data);
  }, [vesselId, selectedGraph, chart]);

  useEffect(() => {
    let data = true;
    switch (selectedGraph) {
      case GRAPH_TYPE.ENGINE:
        data = checkEngineLatestDataTimeWithinOneMonth(latestData);
        break;
      case GRAPH_TYPE.SEP:
        data = checkSEPLatestDataTimeWithinOneMonth(latestData);
        break;
      case GRAPH_TYPE.ELECTRIC:
        data = checkElectricLatestDataTimeWithinOneMonth(latestData);
        break;
      default:
        data = checkCustomLatestDataTimeWithinOneMonth();
        break;
    }
    setDisableDataDuration(!data);
  }, [latestData, selectedEngine]);

  const getDurationType = () => {
    switch (selectedGraph) {
      case GRAPH_TYPE.ENGINE:
        return DURATION.ENGINE;
      case GRAPH_TYPE.ELECTRIC:
        return DURATION.ELECTRIC;
      case GRAPH_TYPE.SEP:
        return DURATION.SEP;
      case GRAPH_TYPE.CUSTOM:
        return DURATION.CUSTOM;
    }
  };

  const checkEngineLatestDataTimeWithinOneMonth = (latestData) => {
    if (!latestData || !selectedEngine?.length) return false;
    const engineDate = latestData?.engine?.find((v) => v?.position === selectedEngine)?.dateTime;
    return dayjs.utc(engineDate).isAfter(oneMonthAgo);
  };

  const checkElectricLatestDataTimeWithinOneMonth = (latestData) => {
    if (latestData.geDataStatus !== LATEST_DATA_LOAD_STATUS.SUCCESS) return;
    return [...latestData?.sg, ...latestData?.ge].some((item) => {
      return dayjs.utc(item?.dateTime).isAfter(oneMonthAgo);
    });
  };

  const checkSEPLatestDataTimeWithinOneMonth = (latestData) => {
    const latestSepData = latestData?.sepDataState;
    switch (chart) {
      case SEP_CHART_TYPE.LEGS: {
        const latestLegData = latestSepData?.find((item) => !!item.latestLegData)?.latestLegData;
        return latestLegData?.some((item) => {
          return dayjs.utc(item?.dateTime).isAfter(oneMonthAgo);
        });
      }
      case SEP_CHART_TYPE.THRUSTER: {
        const latestThrusterData = latestSepData?.find(
          (item) => !!item.latestThrusterData
        )?.latestThrusterData;
        return latestThrusterData?.some((item) => {
          return dayjs.utc(item?.dateTime).isAfter(oneMonthAgo);
        });
      }
      case SEP_CHART_TYPE.CRANE1:
      case SEP_CHART_TYPE.CRANE2: {
        const latestCraneData = latestSepData?.find(
          (item) => !!item.latestCraneData
        )?.latestCraneData;
        return latestCraneData?.some((item) => {
          return dayjs.utc(item?.dateTime).isAfter(oneMonthAgo);
        });
      }
      case SEP_CHART_TYPE.TRIM_AND_HEEL: {
        const latestTrimAndHeelData = latestSepData?.find(
          (item) => !!item.latestTrimAndHeelData
        )?.latestTrimAndHeelData;
        return latestTrimAndHeelData?.some((item) => {
          return dayjs.utc(item?.dateTime).isAfter(oneMonthAgo);
        });
      }
      default:
        return false;
    }
  };

  const checkCustomLatestDataTimeWithinOneMonth = async () => {
    if (chart === "undefined") return false;
    if (!dataFetchStatus?.isLoading) {
      if (dataFetchStatus?.isNoData) {
        const latestData = (
          await fetchChannelDataByChartIdForDurationSelector(
            vesselId,
            chart,
            oneMonthAgo.format(DATE_FORMAT),
            dayjs.utc().format(DATE_FORMAT),
            29
          )
        )?.customData;
        if (!latestData) return false;
        return latestData?.data?.some((item) => {
          return dayjs.utc(item?.dateTime).isAfter(oneMonthAgo);
        });
      }
      return true;
    }
    return false;
  };

  return (
    <Paper
      className={classes.paper + " " + classes.paperHeightEngine}
      style={{ minHeight: isSmall ? null : "100px" }}
    >
      <Grid
        id="Controller"
        container
        spacing={2}
      >
        <DataDuration
          position={selectedEngine}
          isDisabled={disableDataDuration}
          type={getDurationType()}
          vesselId={vesselId}
          minDate={dayjs.utc().subtract(1, "month").format()}
          currentVessel={props.currentVessel}
        />
      </Grid>
    </Paper>
  );
});

DurationSelector.propTypes = {
  t: PropTypes.func.isRequired,
  chart: PropTypes.string,
  selectedEngine: PropTypes.string,
  layoutStyle: PropTypes.object,
  isNoData: PropTypes.bool,
  isDownloading: PropTypes.bool,
  selectedChart: PropTypes.string,
  currentVessel: PropTypes.object,
  vesselId: PropTypes.string,
  dataFetchStatus: PropTypes.object,
  selectedGraph: PropTypes.string,
};

export default withTranslation()(DurationSelector);
