import axiosBase from "axios";
import { API_URL, axios } from "./axiosSettings";
import { getAuthHeader } from "../helpers/session";
import { convertStreamToJson } from "../util/buffer";
import { FETCH_CANCEL_MESSAGE } from "../constants/constants";

let channelModCancelToken, channelNamesToken, channelConfigToken ,channelDurationToken, channelThresholdToken;
const channelChartToken = {};

export const fetchChannelAllData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/api/channel/alldata`, {
      params: {
        vesselId,
      },
      headers: getAuthHeader(),
    });
    return { channelData: data };
  } catch (e) {
    throw { fetchChannelAllData: e };
  }
};

export const fetchChannelLatestData = async (vesselId) => {
  try {
    const configReq = axios.get(`/api/channelconfig/alldata`, {
      params: {
        vesselId,
      },
      headers: getAuthHeader(),
    });

    const channelReq = axios.get(`/api/channel/latestdata`, {
      params: {
        vesselId,
      },
      headers: getAuthHeader(),
    });

    const [configData, channelData] = await Promise.all([configReq, channelReq]);
    return {
      channelConfigData: configData.data,
      channelLatestData: channelData.data,
    };
  } catch (e) {
    throw { fetchChannelLatestData: e };
  }
};

export const fetchChannelModAllData = async (vesselId) => {
  if(!vesselId) return;
  if (typeof channelModCancelToken !== typeof undefined) {
    channelModCancelToken.abort(FETCH_CANCEL_MESSAGE);
  }

  channelModCancelToken = new AbortController();

  try {
    const response = await fetch(`${API_URL}/api/channelmod/alldata?vesselId=${vesselId}`, {
      method: "GET",
      headers: getAuthHeader(),
      signal: channelModCancelToken.signal,
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);

    return { channelData: data };
  } catch (e) {
    throw { fetchChannelModAllData: e };
  }
};

export const fetchChannelNames = async (vesselId) => {
  if (typeof channelNamesToken !== typeof undefined) {
    channelNamesToken.cancel(FETCH_CANCEL_MESSAGE);
  }

  channelNamesToken = axiosBase.CancelToken.source();

  try {
    const { data } = await axios.get(`/api/channelmod/names`, {
      params: {
        vesselId,
      },
      headers: getAuthHeader(),
      cancelToken: channelNamesToken.token,
    });
    return { names: data };
  } catch (e) {
    throw { fetchChannelNames: e };
  }
};

export const fetchChannelConfig = async(vesselId, channelNo) => {
  if(!vesselId) return;
  if (typeof channelConfigToken !== typeof undefined) {
    channelConfigToken.cancel(FETCH_CANCEL_MESSAGE);
  }

  channelConfigToken = axiosBase.CancelToken.source();

  try {
    const { data } = await axios.get(`/api/channelmod/config`, {
      params: {
        vesselId,
        channelNo,
      },
      headers: getAuthHeader(),
      cancelToken: channelConfigToken.token,
    });
    return { config: data };
  } catch (e) {
    throw { fetchChannelconfig: e };
  }
};

export const fetchChannelDataByChartId = async (
  vesselId,
  chartId = '',
  startDate,
  endDate,
  interval,
  limit = '',
  category = '',
  subcategory = '',
  secretkey = 'default'
) => {
  const key = !subcategory || subcategory === "" ? secretkey : subcategory;
  if (typeof channelChartToken[key] !== typeof undefined) {
    channelChartToken[key].abort(FETCH_CANCEL_MESSAGE);
  }

  channelChartToken[key] = new AbortController();

  const url = `${API_URL}/api/channelmod/trenddata?vesselId=${vesselId}`
    + `&chartId=${chartId ?? ''}&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}`
    + `&limit=${limit}&category=${category}&subcategory=${subcategory}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: getAuthHeader(),
      signal: channelChartToken[key].signal,
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return { customData: data };
  } catch (e) {
    throw { fetchChannelTrend: e };
  }
};

export const fetchChannelDataByChartIdForDurationSelector = async (
  vesselId,
  chartId,
  startDate,
  endDate,
  interval
) => {
  if (typeof channelDurationToken !== typeof undefined) {
    channelDurationToken.cancel(FETCH_CANCEL_MESSAGE);
  }

  channelDurationToken = axiosBase.CancelToken.source();

  try {
    const { data } = await axios.get(`/api/channelmod/trenddata`, {
      params: {
        vesselId,
        chartId,
        startDate,
        endDate,
        minuteInterval: interval,
        limit: 1,
      },
      headers: getAuthHeader(),
      cancelToken: channelDurationToken.token,
    });
    return { customData: data };
  } catch (e) {
    throw { fetchChannelDuration: e };
  }
};

export const getChannelThresholdData = async (vesselId, channelNo) => {
  if (typeof channelThresholdToken !== typeof undefined) {
    channelThresholdToken.cancel(FETCH_CANCEL_MESSAGE);
  }

  channelThresholdToken = axiosBase.CancelToken.source();

  try {
    const { data } = await axios.get(`/api/channelmod/threshold`, {
      params: {
        vesselId,
        channelNo,
      },
      headers: getAuthHeader(),
      cancelToken: channelThresholdToken.token,
    });
    return { channelThresholdData: data };
  } catch (e) {
    throw { getChannelThresholdData: e };
  }
};

export const postChannelThresholdData = async (vesselId, thresholdSettings) => {
  if(!vesselId) return;
  if (typeof channelThresholdToken !== typeof undefined) {
    channelThresholdToken.cancel(FETCH_CANCEL_MESSAGE);
  }

  channelThresholdToken = axiosBase.CancelToken.source();
  try {
    const { data } = await axios.post(`/api/channelmod/threshold/${vesselId}`, thresholdSettings, {
      params: {
        shortUuid: vesselId,
      },
      headers: getAuthHeader(),
      cancelToken: channelThresholdToken.token,
    });
    return { channelThresholdData: data };
  } catch (e) {
    throw { postChannelThresholdData: e };
  }
};
