import { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  fetchEngineData,
  fetchGeneratorEngineData,
  fetchMainEngineData,
  fetchShaftGeneratorEngineData,
} from "../../../api/baseEngineData";
import { calDurations } from "../../../constants/calDuration";
import {
  craneDataProcess,
  fetchCrane,
  fetchLeg,
  fetchThruster,
  fetchTrimHeel,
  legDataProcess,
  thrusterDataProcess,
  trimHeelProcess,
} from "../../../hooks/useSepTrendData";
import { ENGINE_CHART_LIST } from "../../trendPage/engine/engineList";
import { ENGINE_TYPE } from "../../../constants/trendGraph/enginesLine";
import { DATE_FORMAT, FETCH_CANCEL_MESSAGE } from "../../../constants/constants";
import { SEP_CHART_TYPE } from "../../../constants/trendGraph/sepLines";
import ElectricWorker from "../../trendPage/electric/worker/electricGraphData.worker";
import * as Comlink from "comlink";
import { timeKey } from "../../../constants/timeKey";
import { logger } from "../../../api/logger";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType";
import { fetchVesselGpvtgData } from "../../../api/navigationData";
import { fetchChannelDataByChartId } from "../../../api/channel";
import dayjs from "util/dayjs-init.js";

export const DATA_FETCH_TYPE = {
  ENGINE: "engine",
  SEP: "sep",
  GENERATOR: "generator",
  CUSTOM: "custom",
};

export const getDataFetchType = (selectedGraphType) => {
  switch(selectedGraphType) {
    case GRAPH_TYPE.ELECTRIC: return DATA_FETCH_TYPE.GENERATOR;
    case GRAPH_TYPE.SEP: return DATA_FETCH_TYPE.SEP;
    case GRAPH_TYPE.ENGINE: return DATA_FETCH_TYPE.ENGINE;
    case GRAPH_TYPE.CUSTOM: return DATA_FETCH_TYPE.CUSTOM;
    default: return
  }
};

const createGeneratorData = async ({
  sgData,
  geData,
  unmount,
  setGraphData,
  startDate,
  endDate,
  setIsLoading,
}) => {
  const worker = ElectricWorker();
  const wrap = Comlink.wrap(worker);
  const newData = await wrap?.createGraphData({
    sgData,
    geData,
    startDate,
    endDate,
  });
  const key = timeKey(newData);
  if(!unmount.current){
    const nextData = newData.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
    setGraphData(nextData.length > 0 ? { electricData: nextData } : {});
  }
  setIsLoading(false);
  worker.terminate();
};

const channelModCancelTokens = [undefined, undefined];

export const comparisonDataFetch = async (props) => {
  const {
    vesselId,
    dataFetchType,
    selectedGraph,
    setGraphData,
    setDigitalData,
    startDate,
    endDate,
    unmount,
    position,
    currentVesselSelector,
    setIsLoading,
    number,
  } = props;
  const interval = calDurations({ startDate, endDate });

  if (typeof channelModCancelTokens[number] !== typeof undefined) {
    channelModCancelTokens[number].cancel(FETCH_CANCEL_MESSAGE);
  }

  channelModCancelTokens[number] = axios.CancelToken.source();
  const channelModCancelToken = channelModCancelTokens[number];

  switch (dataFetchType) {
    case DATA_FETCH_TYPE.ENGINE:
      Promise.all([
        fetchEngineData(vesselId, startDate, endDate, interval, channelModCancelToken.token),
        fetchMainEngineData(
          position,
          vesselId,
          startDate,
          endDate,
          interval,
          channelModCancelToken.token
        ),
        fetchVesselGpvtgData(vesselId, startDate, endDate, interval),
      ])
        .then((d) => {
          if (d?.[1]?.vesselMainEngineData.length <= 0 && d?.[0]?.vesselEngineData.length <= 0) {
            setIsLoading(false);
          } else {
            const chartDetails = ENGINE_CHART_LIST.find(
              (dd) => selectedGraph === dd?.id && position === dd?.position
            );
            let dataList = chartDetails?.dataList;
            if (currentVesselSelector.MEEngineType === ENGINE_TYPE.STRAIGHT) {
              dataList = dataList?.filter((c) => !c.keyName.startsWith("meExhGasB"));
            }
            const cylinder = currentVesselSelector.cylinder;
            dataList = dataList?.filter(
              (b) => !b.keyName.startsWith("meExhGas") || b.keyName.match(/\d+/)?.[0] <= cylinder
            );

            chartDetails
              ?.reducer(
                d?.[1]?.vesselMainEngineData,
                d?.[0]?.vesselEngineData,
                d?.[2]?.gpvtgData,
                startDate,
                endDate,
                dataList.map((d) => d.keyName)
              )
              .then((dd) => {
                unmount.current || setGraphData({ [selectedGraph]: dd });
                setIsLoading(false);
              });
          }
        })
        .catch((e) => {});
      break;
    case DATA_FETCH_TYPE.SEP:
      switch (selectedGraph) {
        case SEP_CHART_TYPE.LEGS:
          fetchLeg(
            vesselId,
            startDate,
            endDate,
            interval,
            currentVesselSelector?.NoLeg,
            channelModCancelToken.token
          )
            .then((d) => legDataProcess(d ?? [], unmount, setGraphData))
            .then(() => setIsLoading(false))
            .catch((e) => {});
          break;
        case SEP_CHART_TYPE.CRANE1:
        case SEP_CHART_TYPE.CRANE2:
          fetchCrane(
            vesselId,
            startDate,
            endDate,
            interval,
            currentVesselSelector?.NoCrane,
            channelModCancelToken.token
          )
            .then((d) => craneDataProcess(d ?? [], setGraphData))
            .then(() => setIsLoading(false))
            .catch((e) => {});
          break;
        case SEP_CHART_TYPE.THRUSTER:
          fetchThruster(
            vesselId,
            startDate,
            endDate,
            interval,
            currentVesselSelector?.NoThruster,
            channelModCancelToken.token
          )
            .then((d) => thrusterDataProcess(d ?? [], unmount, setGraphData))
            .then(() => setIsLoading(false))
            .catch((e) => {});
          break;
        case SEP_CHART_TYPE.TRIM_AND_HEEL:
          fetchTrimHeel(vesselId, startDate, endDate, interval, channelModCancelToken.token)
            .then((d) => trimHeelProcess(d ?? [], setGraphData))
            .then(() => setIsLoading(false))
            .catch((e) => {});
          break;
      }
      break;
    case DATA_FETCH_TYPE.GENERATOR:
      Promise.all([
          fetchGeneratorEngineData({
            vesselId,
            startDate,
            endDate,
            interval,
            cancelToken: channelModCancelToken.token,
          }),
          fetchShaftGeneratorEngineData({
            vesselId,
            startDate,
            endDate,
            interval,
            cancelToken: channelModCancelToken.token,
          })
      ])
      .then((d) => {
        createGeneratorData({
          sgData: currentVesselSelector?.NoSG < 1 ? [] : d[1]?.loadingData,
          geData: d[0]?.loadingData,
          unmount,
          setGraphData,
          startDate,
          endDate,
          setIsLoading,
        });
      })
      .catch((e) => {});
      break;
    case DATA_FETCH_TYPE.CUSTOM:
      fetchChannelDataByChartId(vesselId, selectedGraph, startDate, endDate, interval).then((data) => {
        const customData = data?.customData;
        const customDataConfig = customData?.config;
        const digitalChs = customDataConfig?.chNo.filter((chNo, idx) => customDataConfig?.chType[idx] === "2");
        if(!customData) return [];
        const returnData = customData?.data?.map((v) => {
          const progressData = v.data.reduce((previous, current) => {
            return Object.assign(previous, {
              [String(current.chNo)]: digitalChs.includes(String(current.chNo)) ? current.status : isNaN(parseFloat(current.data)) ?  null : parseFloat(current.data)
            });
          }, {});
          return Object.assign(progressData, {dateTime: v.dateTime, localDateTime: dayjs(v.dateTime).format(DATE_FORMAT)});
        });
        const returnDataDigital = customData?.data?.map((v) => {
          const progressData = v.data.reduce((previous, current) => {
            return Object.assign(previous, {[String(current.chNo)]: current.status});
          }, {});
          return Object.assign(progressData, {dateTime: v.dateTime, localDateTime: dayjs(v.dateTime).format(DATE_FORMAT)});
        });
        setGraphData(returnData);
        setDigitalData(returnDataDigital);
        setIsLoading(false);
      })
      break;
    default:
      Error("NO DATA!!");
  }
};

export const useComparisonDataFetch = (params) => {
  const {
    vessel,
    vesselId,
    selectedGraph,
    position,
    startDate,
    endDate,
    selectedGraphType,
  } = params;
  const [graphData, setGraphData] = useState({});
  const [digitalData, setDigitalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(false);
  const unmount = useRef(false);
  const currentVesselInfo = vessel;
  const dataFetchType = getDataFetchType(selectedGraphType);
  const comparisonDataFetchFunction = async () => {
    try {
      setIsLoading(true);
      unmount.current ||
        (await comparisonDataFetch({
          ...params,
          setGraphData,
          setDigitalData,
          unmount,
          currentVesselSelector: currentVesselInfo,
          setIsLoading,
          dataFetchType,
        }));
    } catch (e) {
      logger.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setIsOnline(false);
    setGraphData({});
    setDigitalData([]);

    if (!vesselId || startDate === "" || endDate === "" || !selectedGraph) return;
    comparisonDataFetchFunction();
  }, [
    vesselId,
    selectedGraph,
    position,
    startDate,
    endDate,
  ]);
  return {
    graphData,
    isLoading,
    isOnline,
    digitalData,
  };
};
