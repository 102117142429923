import localizationKeys from "../localizationKeys.js";
import { servicePolicyEn } from "../servicePolicy/servicePolicyEn.js";

const customChartCountNote = `
Create up to 8 custom graphs with no more than 16 channels.
Start by searching for a channel to add it to the graph.
`;

const translations = {
  translations: {
    [localizationKeys.FleetOverview_upper]: "FLEET OVERVIEW",
    [localizationKeys.NavigationData_upper]: "NAVIGATION DATA",
    [localizationKeys.NavigationData_lower]: "Navigation Data",
    [localizationKeys.WindSpeed_upper]: "WIND SPEED",
    [localizationKeys.WindDirection_upper]: "WIND DIRECTION",
    [localizationKeys.VesselInformation_upper]: "VESSEL INFORMATION",
    [localizationKeys.CallSign_upper]: "CALL SIGN",
    [localizationKeys.VesselClass_upper]: "CLASSIFICATION SOCIETY",
    [localizationKeys.Mmsi_upper]: "MMSI",
    [localizationKeys.Itinerary_upper]: "ITINERARY",
    [localizationKeys.Eta_upper]: "ETA",
    [localizationKeys.Departure_upper]: "DEPARTURE",
    [localizationKeys.Destination_upper]: "DESTINATION",
    [localizationKeys.Engine_upper]: "ENGINE",
    [localizationKeys.Engine_lower]: "Engine",
    [localizationKeys.BasicEngineData_upper]: "BASIC ENGINE DATA",
    [localizationKeys.BasicSepData_upper]: "SEP DATA",
    [localizationKeys.SepData_lower]: "SEP Data",
    [localizationKeys.Trim_upper]: "TRIM",
    [localizationKeys.Heel_upper]: "HEEL",
    [localizationKeys.EngineLoad_upper]: "ENGINE LOAD",
    [localizationKeys.EngineLoad_lower]: "Engine Load",
    [localizationKeys.EngineRevolution_upper]: "ENGINE REVOLUTION",
    [localizationKeys.EngineRevolution_lower]: "Engine Revolution",
    [localizationKeys.TotalEngineFoc_upper]: "TOTAL ENGINE FOC",
    [localizationKeys.TotalEngineFoc_lower]: "Total Engine FOC",
    [localizationKeys.TotalDieselEngineFoc_upper]: "TOTAL DIESEL ENGINE FOC",
    [localizationKeys.TotalDieselEngineFoc_lower]: "Total Diesel Engine FOC",
    [localizationKeys.CurrentPosition_upper]: "CURRENT POSITION",
    [localizationKeys.EngineData_upper]: "ENGINE DATA",
    [localizationKeys.EngineData_lower]: "Engine Data",
    [localizationKeys.LoadDiagram_upper]: "LOAD DIAGRAM",
    [localizationKeys.PowerCurve_upper]: "POWER CURVE",
    [localizationKeys.PowerBase_upper]: "Power Base",
    [localizationKeys.Foc_upper]: "FOC",
    [localizationKeys.Shp_upper]: "SHP",
    [localizationKeys.Speed_lower]: "Speed",
    [localizationKeys.Og_upper]: "OG",
    [localizationKeys.Log_upper]: "LOG",
    [localizationKeys.EngineTrendGraph_upper]: "ENGINE TREND GRAPH",
    [localizationKeys.CylinderDeviation_lower]: "Cylinder Deviation - Exhaust Gas Out Temp",
    [localizationKeys.CoolingFreshWaterTemp_lower]: "Cooling Fresh Water Temp",
    [localizationKeys.Logout_lower]: "Log out / Switch User",
    [localizationKeys.Settings_lower]: "Settings",
    [localizationKeys.ProductInformation_lower]: "Product Information",
    [localizationKeys.Generator_upper]: "GENERATOR",
    [localizationKeys.Electric_upper]: "ELECTRIC",
    [localizationKeys.Electric_lower]: "Electric",
    [localizationKeys.TotalPower_upper]: "TOTAL POWER",
    [localizationKeys.ElectricTrendGraph_upper]: "ELECTRIC POWER TREND GRAPH",
    [localizationKeys.DataDuration_lower]: "Data Duration",
    [localizationKeys.Hours_lower]: "hour",
    [localizationKeys.Day_lower]: "day",
    [localizationKeys.Week_lower]: "week",
    [localizationKeys.Month_lower]: "month",
    [localizationKeys.Custom_lower]: "Custom",
    [localizationKeys.Custom_upper]: "CUSTOM",
    [localizationKeys.Last30Mins_lower]: "last 30mins",
    [localizationKeys.LastHour_lower]: "last hour",
    [localizationKeys.LastDay_lower]: "last day",
    [localizationKeys.LastWeek_lower]: "last week",
    [localizationKeys.LastMonth_lower]: "last month",
    [localizationKeys.Last3Month_lower]: "last 3month",
    [localizationKeys.Last6Month_lower]: "last 6month",
    [localizationKeys.LastYear_lower]: "last year",
    [localizationKeys.CylinderTemp_upper]: "Cylinder - Exhaust Gas Out Temp",
    [localizationKeys.TrendData_upper]: "TREND DATA",
    [localizationKeys.Monitoring_upper]: "MONITORING DATA",
    [localizationKeys.Monitoring_lower]: "Monitoring Data",
    [localizationKeys.Disconnected_upper]: "VESSEL OFFLINE",
    [localizationKeys.DisconnectedNodata_lower]: "vessel offline - no data",
    [localizationKeys.LastUpdate_upper]: "LAST UPDATE",
    [localizationKeys.NoData_lower]: "NO DATA AVAILABLE",
    [localizationKeys.GraphNotSelected_lower]: "No graph selected",
    [localizationKeys.GraphNotSelectedMsg_lower]: "Please select a vessel and graph options on the right.",
    [localizationKeys.AlarmMonitoring_upper]: "MONITORING",
    [localizationKeys.AlarmMonitoring_lower]: "Monitoring",
    [localizationKeys.Status_upper]: "STATUS",
    [localizationKeys.MainEngine_upper]: "MAIN ENGINE",
    [localizationKeys.Fire_upper]: "FIRE",
    [localizationKeys.LowInsulation_upper]: "LOW INSULATION",
    [localizationKeys.ActiveAlarms_upper]: "ACTIVE ALARMS",
    [localizationKeys.AlarmName_lower]: "Alarm Item",
    [localizationKeys.Category_lower]: "Category",
    [localizationKeys.DateTime_lower]: "Date/Time",
    [localizationKeys.Status_lower]: "Status",
    [localizationKeys.Data_lower]: "Data",
    [localizationKeys.NoActiveAlarms_lower]: "no active alarms",
    [localizationKeys.NoActiveAlarms_upper]: "NO ACTIVE ALARMS",
    [localizationKeys.NoAlarmHistory_upper]: "NO ALARM HISTORY",
    [localizationKeys.NoOperations_upper]: "NO OPERATIONS",
    [localizationKeys.NoChannel_upper]: "NO CHANNEL DATA",
    [localizationKeys.SelectDate_lower]: "Select date",
    [localizationKeys.Submit_lower]: "Submit",
    [localizationKeys.WhatsNew_lower]: "What's New",
    [localizationKeys.NowReleased_lower]: " now released!",
    [localizationKeys.Released_lower]: "Released",
    [localizationKeys.Updated_lower]: "Updated",
    [localizationKeys.ReleaseNotesTitle_lower]: "Release Notes",
    [localizationKeys.ReleaseNotesTitle_upper]: "RELEASE NOTES",
    [localizationKeys.SeeReleaseDetails_lower]: "See Release Details",
    [localizationKeys.TermsofService_lower]: "Terms of Service",
    [localizationKeys.ServiceAgreementContentsTitle_lower]: "Service Agreement Contents",
    [localizationKeys.ServicePolicyTitle_lower]: "Terms of Use for JRCS infoceanus connect",
    [localizationKeys.ServicePolicyText_lower]: servicePolicyEn,
    [localizationKeys.ServicePolicyTitle_upper]: "SERVICE AGREEMENT",
    [localizationKeys.ConfirmServicePolicy_lower]: "I have read and accept the terms of service.",
    [localizationKeys.NoConfirmServicePolicy_lower]: "I do not accept the term of service.",
    [localizationKeys.ServicePolicyLink_lower]: "Service Agreement",
    [localizationKeys.SelectedLanguage_lower]: "Select Language",
    [localizationKeys.BackMenu_lower]: "Back",
    [localizationKeys.Occurrence_lower]: "Occurrence",
    [localizationKeys.RecoveryTime_lower]: "Recovery time",
    [localizationKeys.Search_lower]: "Search",
    [localizationKeys.AlarmMonitoring_upper]: "MONITORING",
    [localizationKeys.AlarmHistory_upper]: "HISTORY",
    [localizationKeys.AlarmHistory_lower]: "History",
    [localizationKeys.AlarmOperations_upper]: "OPERATIONS",
    [localizationKeys.AlarmOperations_lower]: "Operations",
    [localizationKeys.AlarmOperationItem_lower]: "Operation Item",
    [localizationKeys.AlarmOperationItemAbbr_lower]: "Item",
    [localizationKeys.AlarmOperationType_lower]: "Operation Type",
    [localizationKeys.AlarmOperationTypeAbbr_lower]: "Type",
    [localizationKeys.Action_lower]: "Action",
    [localizationKeys.Alarm_upper]: "ALARMS",
    [localizationKeys.OutOf_lower]: "out of",
    [localizationKeys.Value_lower]: "Value",
    [localizationKeys.ResetSearch_lower]: "Reset Search",
    [localizationKeys.ElectricData_upper]: "ELECTRIC DATA",
    [localizationKeys.ElectricData_lower]: "Electric Data",
    [localizationKeys.Minutes_lower]: "mins",
    [localizationKeys.EngineSelection_lower]: "Engine Selection",
    [localizationKeys.Last_lower]: "Last",
    [localizationKeys.StartTime_lower]: "Start Time",
    [localizationKeys.EndTime_lower]: "End Time",
    [localizationKeys.LegTrend_lower]: "Legs",
    [localizationKeys.Thruster_lower]: "Thruster",
    [localizationKeys.Crane1_lower]: "Crane 1",
    [localizationKeys.Crane2_lower]: "Crane 2",
    [localizationKeys.TrimAndHeel_lower]: "Trim & Heel",
    [localizationKeys.SepTrend_upper]: "SEP TREND",
    [localizationKeys.DisconnectedNoEngineData_lower]: "engine offline - no data",
    [localizationKeys.CPP_lower]: "CPP Angle",
    [localizationKeys.Port_abbreviation]: "P",
    [localizationKeys.Starboard_abbreviation]: "S",
    [localizationKeys.Center_abbreviation]: "C",
    [localizationKeys.Number_abbreviation]: "No.",
    [localizationKeys.Total_upper]: "TOTAL",
    [localizationKeys.Power_upper]: "POWER",
    [localizationKeys.Load_upper]: "LOAD %",
    [localizationKeys.RunningTime_upper]: "RUNTIME",
    [localizationKeys.RunningTime_Lower]: "Runtime",
    [localizationKeys.CPP_label]: "CPP ANGLE DATA",
    [localizationKeys.Unit]: "UNIT",
    [localizationKeys.PortEngine]: "P ENGINE",
    [localizationKeys.StarboardEngine]: "S ENGINE",
    [localizationKeys.CenterEngine]: "C ENGINE",
    [localizationKeys.DataComparison_upper]: "DATA COMPARISON",
    [localizationKeys.DataComparison_lower]: "Data Comparison",
    [localizationKeys.Channel_upper]: "CHANNEL",
    [localizationKeys.AllChannel_lower]: "All Channels",
    [localizationKeys.ChannelFavorites_lower]: "Favorites",
    [localizationKeys.Graph_upper]: "GRAPH",
    [localizationKeys.vessel_lower]: "vessel",
    [localizationKeys.graph_lower]: "graph",
    [localizationKeys.SEP_upper]: "SEP",
    [localizationKeys.MainEngine_abbreviation]: "M/E",
    [localizationKeys.Filters_lower]: "Filters",
    [localizationKeys.ResetSearchOptions_lower]: "Reset search options",
    [localizationKeys.SortByHighestValue]: "Sort By Highest Value",
    [localizationKeys.SortByLowestValue]: "Sort By Lowest Value",
    [localizationKeys.SortByNewest]: "Sort By Newest",
    [localizationKeys.SortByOldest]: "Sort By Oldest",
    [localizationKeys.SortAlarmItemAZ]: "Sort Alarm Item A - Z",
    [localizationKeys.SortAlarmItemZA]: "Sort Alarm Item Z - A",
    [localizationKeys.SortAscendingNo]: "Sort Ascending No.",
    [localizationKeys.SortDescendingNo]: "Sort Descending No.",
    [localizationKeys.SortByNewestOccurence]: "Sort By Newest Occurence",
    [localizationKeys.SortByOldestOccurence]: "Sort By Oldest Occurence",
    [localizationKeys.ShowUnresolvedAlarms]: "Show Unresolved Alarms",
    [localizationKeys.SortOperationItemAZ]: "Sort Operation Item A - Z",
    [localizationKeys.SortOperationItemZA]: "Sort Operation Item Z - A",
    [localizationKeys.SortChannelNameAZ]: "Sort Channel Name A - Z",
    [localizationKeys.SortChannelNameZA]: "Sort Channel Name Z - A",
    [localizationKeys.ResetSort]: "Reset Sort",
    [localizationKeys.Emissions_upper]: "EMISSIONS",
    [localizationKeys.EmissionsOverview_lower]: "Emissions Overview",
    [localizationKeys.OccurrenceTime]: "Occurrence Time",
    [localizationKeys.UpdateTime]: "Update Time",
    [localizationKeys.ChannelName]: "Channel Name",
    [localizationKeys.ChannelNumber]: "Channel Number",
    [localizationKeys.Loading]: "Graph loading",
    [localizationKeys.OperationalAnalysis]: "Operational Analysis",
    [localizationKeys.EmissionDataSelect]: "Data Select:",
    [localizationKeys.EmissionDataDuration]: "Data Duration:",
    [localizationKeys.EmissionDataDurationStart]: "From",
    [localizationKeys.EmissionDataDurationEnd]: "To",
    [localizationKeys.EmissionDataDurationError]: "Wrong Duration",
    [localizationKeys.EmissionExportData]: "Export Data:",
    [localizationKeys.EmissionExportDataButton]: "Export",
    [localizationKeys.ShowGraphVariables]: "Show Graph Variables",
    [localizationKeys.HideGraphVariables]: "Hide Graph Variables",
    [localizationKeys.ChangeColor]: "Change Channel Color",
    [localizationKeys.EngineTemp_lower]: "Temperature",
    [localizationKeys.EnginePress_lower]: "Pressure",
    [localizationKeys.WindSpeed_lower]: "Speed",
    [localizationKeys.WindDirection_lower]: "Direction",
    [localizationKeys.VesselHeading]: "Vessel Heading",

    // Custom Charts
    [localizationKeys.CustomSubtitle]: "(Optional) Add name in other language",
    [localizationKeys.CustomSearchChannels]: "Search channels",
    [localizationKeys.CustomSearchChannelsPlaceholder]: "Channel name, Channel No.",
    [localizationKeys.CustomShowAll]: "Show all",
    [localizationKeys.CustomChartCountNote]: customChartCountNote,
    [localizationKeys.CustomNoMatchFound]: "No match found.",
    [localizationKeys.CustomGraphPreview]: "Graph Preview",
    [localizationKeys.CustomVariableNote]: "Add up to 16 variables.",
    [localizationKeys.CustomSaveGraph]: "Save Graph",
    [localizationKeys.CustomCancel]: "Cancel",
    [localizationKeys.CustomDelete]: "Delete",
    [localizationKeys.CustomChannelNo]: "Ch. No.",
    [localizationKeys.CustomChannelName]: "Ch. Name",
    [localizationKeys.CustomUnit]: "Unit",
    [localizationKeys.CustomSignalType]: "Signal Type",
    [localizationKeys.CustomGraphPreviewFull]: "16 Channels added.",
    [localizationKeys.CustomVariableNoteFull]:
      "No more variables can be added to this custom graph. Please remove a variable to add a different one.",
    [localizationKeys.CustomPromptCancelTitle]: "Discard changes to this graph?",
    [localizationKeys.CustomPromptCancelSubtitle]: "Changes you made will not be saved.",
    [localizationKeys.CustomPromptCancelOkButton]: "Cancel Changes",
    [localizationKeys.CustomPromptCancelCancelButton]: "Continue Editing",
    [localizationKeys.CustomPromptSaveTitle]: "Save this custom graph?",
    [localizationKeys.CustomPromptSaveSubtitle]: "You can edit it again later.",
    [localizationKeys.CustomPromptSaveOkButton]: "Save Graph",
    [localizationKeys.CustomPromptSaveCancelButton]: "Continue Editing",
    [localizationKeys.CustomPromptDeleteTitle]: "Are you sure you want to delete this graph?",
    [localizationKeys.CustomPromptDeleteSubtitle]:
      "This graph will be removed and no longer viewable.",
    [localizationKeys.CustomPromptDeleteOkButton]: "Delete Graph",
    [localizationKeys.CustomPromptDeleteCancelButton]: "Cancel",
    [localizationKeys.CustomPromptLeaveTitle]: "You have not saved your graph.",
    [localizationKeys.CustomPromptLeaveSubtitle]: "Would you like to leave without saving?",
    [localizationKeys.CustomPromptLeaveOkButton]: "Exit Without Saving",
    [localizationKeys.CustomPromptLeaveCancelButton]: "Continue Editing",
    [localizationKeys.CustomLastEdited]: "Last edited",
    [localizationKeys.CustomMenuAdd]: "add new custom graph",

    // LCO2
    [localizationKeys.LCO2Page]: "CARGO TANK",
    [localizationKeys.Transport]: "Transport",
    [localizationKeys.Handling]: "Handling",
    [localizationKeys.CargoStatus]: "Cargo Status",
    [localizationKeys.LocalTime]: "Local Time",
    [localizationKeys.FlowRate]: "Flow Rate",
    [localizationKeys.FlowSpeed]: "Flow Speed",
    [localizationKeys.Density]: "Density",
    [localizationKeys.AMBTemperature]: "AMB Temp",
    [localizationKeys.Tank]: "Tank",
    [localizationKeys.CargoTank]: "Cargo Tank",
    [localizationKeys.ShieldInnerSurfaceTemp]: "Shield Inner Temp",
    [localizationKeys.ShieldOuterSurfaceTemp]: "Shield Outer Temp",
    [localizationKeys.TankCoverInnerSurfaceTemp]: "Tank Cover Inner Temp",
    [localizationKeys.CargoTankPress]: "Cargo Tank Press",
    [localizationKeys.CargoTankTemp]: "Cargo Tank Temp",
    [localizationKeys.PressureLoss]: "Pressure loss",
    [localizationKeys.XAngularVelocity]: "Angular Velocity",
    [localizationKeys.YAngularVelocity]: "Angular Velocity",
    [localizationKeys.PositionAndWeather]: "Position and Weather",
    [localizationKeys.TrueWind]: "True Wind",
    [localizationKeys.RelativeWind]: "Relative Wind",
    [localizationKeys.VesselSpeedOG]: "Vessel OG Speed",
    [localizationKeys.LongAndLat]: "Long and Lat",
    [localizationKeys.CargoMimic]: "Cargo MIMIC",
    [localizationKeys.StatusGauge]: "Status Gauge",
    [localizationKeys.TankLevel]: "Tank Level",
    [localizationKeys.No1TankPress]: "No.1 Tank Press",
    [localizationKeys.No2TankPress]: "No.2 Tank Press",
    [localizationKeys.No2CargoPPDisch]: "No.2 Cargo PP Dish",
    [localizationKeys.No2CargoPPSuction]: "No.2 Cargo PP Suction",
    [localizationKeys.PortManifoldLiquid]: "PORT Manifold Liquid",
    [localizationKeys.StarboardManifoldLiquid]: "STBD Manifold Liquid",
    [localizationKeys.CargoTemperature]: "Cargo temperature",
    [localizationKeys.CargoTemperatureAndPressure]: "Cargo temperature and pressure",
    [localizationKeys.CargoLoading]: "Loading",
    [localizationKeys.CargoUnloading]: "Unloading",
    [localizationKeys.CSVDownload]: "CSV Download",
    [localizationKeys.NoLatestData]: "--",
    [localizationKeys.NoLatestDataForChart]: "N/A",
    [localizationKeys.BackToLatest]: "Update view to current",

    // Channel alert threshold settings
    [localizationKeys.ChannelOptions]: "Channel Options",
    [localizationKeys.ConnectNotification]: "Connect notification",
    [localizationKeys.ThresholdReached]: "Threshold Reached",
    [localizationKeys.SystemExternalSettings]: "System external settings",
    [localizationKeys.SelectThresholdForNotification]: "Select threshold for notification",
    [localizationKeys.InputEmailAddresses]: "Input email addresses",
    [localizationKeys.AddEmail]: "Add Email",
    [localizationKeys.SetThreshold]: "Set threshold",
    [localizationKeys.AddThreshold]: "Add Threshold",
    [localizationKeys.SaveChanges]: "Save Changes",
    [localizationKeys.NoSaveConfirmation1st]: "You have not saved changes.",
    [localizationKeys.NoSaveConfirmation2nd]: "Exit channel without applying changes?",
    [localizationKeys.ContinueEditing]: "Continue Editing",
    [localizationKeys.ExitWithoutSaving]: "Exit Without Saving",
    [localizationKeys.DeleteEmailConfirmation1st]: "Are you sure you want to remove this email?",
    [localizationKeys.DeleteEmailConfirmation2nd]:
      "This email will no longer receive notification about this channel",
    [localizationKeys.RemoveEmail]: "Remove Email",
    [localizationKeys.ReturnToChannelOptions]: "Return To Channel Options",
    [localizationKeys.NoMatchConfirmation1st]: "You have matching threshold values!",
    [localizationKeys.NoMatchConfirmation2nd]: "This may cause duplicate emails to occur.",
    [localizationKeys.ConfirmAndSave]: "Confirm And Save",
    [localizationKeys.NotExceedMin]: "Threshold value cannot exceed min value",
    [localizationKeys.NotExceedMax]: "Threshold value cannot exceed max value",
    [localizationKeys.NotExceedLow]: "Low threshold cannot exceed high threshold",
    [localizationKeys.NotExceedHigh]: "High threshold cannot exceed low threshold",
    [localizationKeys.Min]: "Min",
    [localizationKeys.Max]: "Max",
    [localizationKeys.CreateHighThreshold]: "Create High Threshold",
    [localizationKeys.CreateLowThreshold]: "Create Low Threshold",
    [localizationKeys.HighThreshold]: "High Threshold",
    [localizationKeys.LowThreshold]: "Low Threshold",

    //Synthetica
    [localizationKeys.Rank]: "Rank",
    [localizationKeys.Distance]: "Distance",
    [localizationKeys.VesselDetails]: "Vessel Details",
    [localizationKeys.VesselPerformance]: "Vessel Performance",
    [localizationKeys.DistanceTraveled]: "Distance Traveled",
    [localizationKeys.CO2]: "Co2",
    [localizationKeys.FuelConsumption]: "Fuel Consumption",
    [localizationKeys.AverageSpeed]: "Average Speed",
    [localizationKeys.VoyageHistory]: "Voyage History",
    [localizationKeys.Departed]: "Departed",
    [localizationKeys.DepartureTimeUTC]: "Departure Time (UTC)",
    [localizationKeys.ArrivalTimeUTC]: "Arrival Time (UTC)",
    [localizationKeys.SpeedKn]: "Speed (kn)",
    [localizationKeys.Destination]: "Destination",
    [localizationKeys.CII]: "CII",
    [localizationKeys.CIIDiff]: "CII Diff(%)",
    [localizationKeys.DailyCII]: "Daily CII Performance",
    [localizationKeys.CurrentCII]: "Current",
    [localizationKeys.RequiredCII]: "Required",
    [localizationKeys.PredictedEOYCII]: "EOY Predicted Mean",
    [localizationKeys.PredictedIntervalCII]: "Predicted Interval",
    [localizationKeys.CIIOverTime]: "Vessel CII Over Time",

    // Vessel Overview
    [localizationKeys.VesselDetailsOverview]: "Vessel Details Overview",
    [localizationKeys.Updated]: "Updated",
    [localizationKeys.VesselClass]: "VESSEL CLASS",
    [localizationKeys.CIIRanking]: "CII Ranking",
    [localizationKeys.VesselPosition]: "Vessel Position",
    [localizationKeys.TrueWindSpeed]: "True Wind Speed",
    [localizationKeys.TrueWindDirection]: "True Wind Direction",
    [localizationKeys.LocalWeather]: "Local Weather",
    [localizationKeys.SeaWaterTemp]: "Sea Water Temp",
    [localizationKeys.ReposedAlarms]: "Reposed Alarms",
    [localizationKeys.GoToAlarmDetails]: "Go to alarm details",
    [localizationKeys.CurrentStatus]: "Current Status",
    [localizationKeys.MainEngineLoad]: "MAIN ENGINE LOAD",
    [localizationKeys.MainEnginePowerCurve]: "MAIN ENGINE POWER CURVE",

  },
};

export default translations;
