/* global google */
import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";

import {
  setSelectedVessel,
  selectedVessel,
  setFleetMarkers,
  setAlarmMarkers,
  setMapCenter,
  setMapZoom,
  resetMapMarkers,
  markersResetDone,
  mapData,
} from "../../model/slice/fleetNavigationDataSlice.js";
import { fleetAlarmSelector } from "../../model/slice/alarmSlice.js";
import { useDetectVesselConnection } from "../../hooks/useDetectVesselConnection.js";
import {
  addVesselMarker,
  addAlarmMarker,
  updateOrRemoveAlarmMarker,
  selectedVesselMarkerURL,
} from "./markers";
import { VESSEL_TYPE } from "../../constants/constants.js";
import { setRoute } from "./displayRoutes.js";

import { SHIMONOSEKI_PORT_LATLNG } from "../../constants/shimonosekiPort.js";

const Map = ({ vessels, isMainPage }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const currentVessel = useSelector(selectedVessel);
  const mapDetails = useSelector(mapData);
  const fleetAlarmData = useSelector(fleetAlarmSelector);
  const [initialZoom, setInitialZoom] = useState(true);
  const { connections } = useDetectVesselConnection();
  const [map, setMap] = useState(null);
  const MAX_INITIAL_ZOOM = 3;
  const markers = [];
  const alarmMarkers = [];
  const SELECTED_VESSEL_ZINDEX = 1000;
  const HOVER_VESSEL_ZINDEX = 800;
  const location = useLocation();

  const routePolyline = [
    null,
    new google.maps.Polyline({
      geodesic: true,
      strokeColor: "#3388bb",
      strokeOpacity: 1,
      strokeWeight: 3,
    }),
  ];

  const setSelected = (map, vesselId, pos) => {
    // setRoute(null, vesselId, routePolyline);
    if (isLg) {
      const bounds = map.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
  
      const neLng = ne.lng() < 0 ? 360 + ne.lng() : ne.lng();
      const midLng = pos.lng + ((neLng - sw.lng()) / 4);
      const point = new google.maps.LatLng(
        pos.lat,
        midLng,
      );
  
      map.setCenter(point);
    } else {
      map.setCenter(pos);
    }

    dispatch(setSelectedVessel(vesselId));
    // routePolyline[0] = vesselId;
    // setRoute(map, vesselId, markers[vesselId]?.position, routePolyline);
  };

  const resetSelected = () => {
    dispatch(setSelectedVessel(null));
  };

  const setMarkersFleet = () => {
    dispatch(setFleetMarkers(markers));
  };

  const setMarkersAlarm = () => {
    dispatch(setAlarmMarkers(alarmMarkers));
  };

  const setCenterHandle = (val) => {
    dispatch(
      setMapCenter({
        lat: val.lat(),
        lng: val.lng(),
      })
    );
  };

  const hideFleetMarkers = () => {
    for (const key in mapDetails.fleetMarkers) {
      //mapDetails.fleetMarkers[key]?.setVisible(false);
      if(mapDetails.fleetMarkers[key]!=null)
        mapDetails.fleetMarkers[key].map = null;
    }
  };

  const hideAlarmMarkers = () => {
    for (const key in mapDetails.alarmMarkers) {
      if(mapDetails.alarmMarkers[key]!=null)
        mapDetails.alarmMarkers[key].map = null;
    }
  };

  const hideAllMarkers = () => {
    hideFleetMarkers();
    hideAlarmMarkers();
  };

  const setZoomHandle = (val) => {
    dispatch(setMapZoom(val));
  };

  const initMap = () => {
    const currentLocation = location.pathname.split("/")[1];
    if (currentLocation === "main") {
      resetSelected();
    }
    const newMap = new window.google.maps.Map(ref.current, {
      center: mapDetails.center,
      zoom: mapDetails.zoom,
      minZoom: 2,
      mapTypeId: "terrain",
      mapId: "2e138cc78acc4038",
      disableDefaultUI: true,
      restriction: {
        latLngBounds: { north: 83.38, south: -85, west: -180, east: 180 },
      },
    });

    google.maps.event.addListener(newMap, "zoom_changed", () => {
      if (initialZoom && newMap.getZoom() > mapDetails.zoom) {
        setZoomHandle(newMap.getZoom());
        setCenterHandle(newMap.getCenter());
      }
    });

    google.maps.event.addListener(newMap, "dragend", () => {
      setCenterHandle(newMap.getCenter());
    });

    google.maps.event.addListener(newMap, "click", () => {
      resetSelected();
    });

    setMap(newMap);
    setInitialZoom(true);
  };

  useEffect(() => {
    if (!mapDetails?.center || !map) return;
    map.setCenter(mapDetails?.center);
  }, [mapDetails?.center]);

  useEffect(() => {
    for (const key in mapDetails.fleetMarkers) {
      if (mapDetails.currentVessels.map(v => v.vessel_id).includes(key)){
        if (mapDetails.fleetMarkers[key] != null)
          mapDetails.fleetMarkers[key].map = map;
        if (mapDetails.alarmMarkers[key] != null)
          mapDetails.alarmMarkers[key].map = map;
      }
      else {
        if (mapDetails.fleetMarkers[key] != null)
          mapDetails.fleetMarkers[key].map = null;
        if (mapDetails.alarmMarkers[key] != null)
          mapDetails.alarmMarkers[key].map = null;
      }
    }
  }, [mapDetails?.currentVessels]);

  useEffect(() => {
    if (mapDetails?.isResetMarkers) {
      hideAllMarkers();
      if (!map) {
        initMap();
      }
      dispatch(markersResetDone());
    }
  }, [mapDetails?.isResetMarkers]);

  useEffect(() => {
    if (!currentVessel) return;
    google.maps.event.trigger(mapDetails.fleetMarkers[currentVessel.vessel_id], "click");
  }, [currentVessel?.vessel_id]);

  useEffect(() => {
    if (!ref?.current) return;

    if (map) {
      setMap(null);
      dispatch(resetMapMarkers());
    } else {
      initMap();
    }
  }, [ref?.current]);

  useEffect(() => {
    if (!map || vessels?.length === 0 || mapDetails?.isResetMarkers) return;
    const bounds = new window.google.maps.LatLngBounds();
    vessels.forEach((object, idx) => {
      const isVesselDisconnected = connections.includes(object.vessel_id);
      const isSelected = object.vessel_id === currentVessel?.vessel_id;
      const isSEPvessel = object?.vesselType === VESSEL_TYPE.SEP;
      const shipType =
        object.vesselLatestAisData?.length === 0 ? 0 : object.vesselLatestAisData[0].shipType;
      const rotation = object.vesselLatestHehdtData[0]?.head || 0;

      if (mapDetails.fleetMarkers[object.vessel_id]) {
        const marker = mapDetails.fleetMarkers[object.vessel_id];
        const newPosition = new google.maps.LatLng(
          object.vesselLatestGpggaData[0]?.latitude || SHIMONOSEKI_PORT_LATLNG.lat,
          object.vesselLatestGpggaData[0]?.longitude || SHIMONOSEKI_PORT_LATLNG.lng,
        );
        marker.position = newPosition;

        const icon = marker.content;
        if(icon !== undefined){
          icon.src = selectedVesselMarkerURL(
            isSEPvessel,
            shipType,
            rotation,
            isVesselDisconnected,
            isSelected
          );
          const scale = isSelected ? 1.125 : 1;
          icon.style.transform = `translateY(50%) scale(${scale})`
        }
        
        marker.zIndex = isSelected ? SELECTED_VESSEL_ZINDEX : 300;
        markers[object.vessel_id] = marker;
      } else {
        const currentLocation = location.pathname.split("/")[1];
        let marker = null;
        if (currentLocation) {
          marker = addVesselMarker(
            currentVessel?.vessel_id,
            map,
            object,
            isVesselDisconnected,
            false
          );
        } else {
          marker = addVesselMarker(
            currentVessel?.vessel_id,
            map,
            object,
            isVesselDisconnected,
            isSelected
          );
        }
        if (vessels.length === 1 && isMainPage) {
          map.setCenter(marker.position);
        }

        bounds.extend(marker.position);

        if (isMainPage) {
          google.maps.event.addListener(marker, "click", function () {
            setSelected(map, object.vessel_id, marker.position);
          });
          // handle hover effect
          marker.content.addEventListener('mouseover', () => {
            markers[object.vessel_id].content.style.transform = `translateY(50%) scale(1.125)`;
            markers[object.vessel_id].zIndex = HOVER_VESSEL_ZINDEX;
          });
          // un-hover when mouse leaves
          marker.content.addEventListener('mouseout', () => {
            markers[object.vessel_id].content.style.transform = `translateY(50%) scale(1)`;
            markers[object.vessel_id].zIndex = 300;
          });
        }

        markers[object.vessel_id] = marker;
        if (!isMainPage) {
          const e = {
            latLng: new google.maps.LatLng(-89.99, -180),
          };
          map.addListener("click", (mapsMouseEvent) => {
            if (mapsMouseEvent.latLng.lat() == -89.99) {
              routePolyline[0] = null;
              setRoute(null, null, null, routePolyline);
            }
          });
          google.maps.event.trigger(map, "click", e);
          routePolyline[0] = object.vessel_id;
          setRoute(map, object.vessel_id, marker.position, routePolyline);
        }
      }
    });

    if (initialZoom && isMainPage) {
      const zoom = map.getZoom();
      const listener = google.maps.event.addListener(map, "idle", function () {
        map.setZoom(zoom > MAX_INITIAL_ZOOM ? MAX_INITIAL_ZOOM : zoom);
        google.maps.event.removeListener(listener);
      });
      setInitialZoom(false);
    }
    setMarkersFleet();
  }, [
    map,
    vessels,
    mapDetails?.isResetMarkers,
    currentVessel,
    JSON.stringify(connections),
  ]);

  // handle alarm marker
  useEffect(() => {
    if (!map || vessels?.length === 0 || mapDetails?.isResetMarkers) return;
    vessels.forEach((object, idx) => {
      const isVesselDisconnected = connections.includes(object.vessel_id);

      if (mapDetails.alarmMarkers[object.vessel_id]) {
        const newPosition = new google.maps.LatLng(
          object.vesselLatestGpggaData[0]?.latitude || SHIMONOSEKI_PORT_LATLNG.lat,
          object.vesselLatestGpggaData[0]?.longitude || SHIMONOSEKI_PORT_LATLNG.lng,
        );

        if (isVesselDisconnected || !isMainPage) {
          if (mapDetails.alarmMarkers[object.vessel_id]) {
            const alarmMarker = mapDetails.alarmMarkers[object.vessel_id];
            alarmMarker.map = null;
          }
        } else {
          let alarmMarker = null;
          if (mapDetails.alarmMarkers[object.vessel_id]) {
            alarmMarker = mapDetails.alarmMarkers[object.vessel_id];
            alarmMarker = updateOrRemoveAlarmMarker(
              fleetAlarmData,
              object,
              alarmMarker,
              newPosition
            );
          } else {
            alarmMarker = addAlarmMarker(
              map,
              fleetAlarmData,
              object,
              idx
            );
          }
          alarmMarkers[object.vessel_id] = alarmMarker;
        }
      } else {
        if (!isVesselDisconnected && isMainPage) {
          const alarmMarker = addAlarmMarker(
            map,
            fleetAlarmData,
            object,
            idx
          );
          alarmMarkers[object.vessel_id] = alarmMarker;
        }
      }
    });
    setMarkersAlarm();
  }, [map, vessels, JSON.stringify(fleetAlarmData), JSON.stringify(connections),])

  return (
    <div ref={ref} id="map" style={{ height: "100%", width: "100%" }} />
  );
};

Map.propTypes = {
  vessels: PropTypes.array,
  isMainPage: PropTypes.bool,
};

export default React.memo(Map);
