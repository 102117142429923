import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";

//Image imports
import EngineIcon from "../../../../../assets/images/vessels/vessel-menu/icon-menu-engine.svg";
import EngineIconSelected from "../../../../../assets/images/vessels/vessel-menu/icon-menu-engine-selected.svg";

// //Localization Imports
import localizationKeys from "../../../../i18n/localizationKeys.js";
import { NoData } from "../../../offlineComponent/noData.jsx";
import { COLORS } from "../../../../constants/colors.js";
import { useFocCalculation } from "../../../navigationPage/hooks/useFocCalculation.js";
import { MAIN_ENGINE } from "../../../../constants/constants.js";
import { useDispatch } from "react-redux";
import { FOC_DURATION_LIST, setSelectedFOCDuration } from "../../../../model/slice/fleetNavigationDataSlice.js";
import { getFocUnit, focValue } from "../../../../util/navigation/navigation";

const useStyles = () => ({
  container: {
    height: "100% !important",
  },
  titleContainer: {
    textAlign: "center",
    fontSize: "11px",
    color: COLORS.greenishBlue,
    fontWeight: "500",
  },
  engineContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  engineIconContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  engineImage: {
    height: "40px",
    width: "40px",
    margin: "-8px",
  },
  iconLabel: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "10px",
    color: "#000000",
  },
  iconLabelSelected: {
    color: "#ffffff",
  },
  iconHolder: {
    cursor: "pointer",
    position: "relative",
    maxWidth: "40px",
    maxHeight: "40px",
    width: "40px",
    height: "40px",
  },
  fixedTitleTextSize: {
    "& p": {
      fontSize: "12px",
    },
  },
  navValueTextSize: {
    fontSize: "12px",
    textAlign: "right",
  },
  label: {
    color: COLORS.greenishBlue,
  },
  navLabelTextSize: {
    fontSize: "12px",
  },
});

const EngineIcons = ({ engines, selectedEngine, setSelectedEngine, t, classes }) => {
  const clickEventHandler = (selectionIndex) => {
    setSelectedEngine(selectionIndex);
  };

  return engines.map((e) => (
    <Grid item xs={12}
        key={e.position}
        className={classes.engineIconContainer}>
      <div
        className={classes.iconHolder}
        onClick={() => {
          clickEventHandler(e.position);
        }}
      >
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            className={classes.engineImage}
            src={selectedEngine === e.position ? EngineIconSelected : EngineIcon}
            alt={"engine image"}
          />
        </div>
        <div
          className={
            selectedEngine === e.position
              ? `${classes.iconLabel} ${classes.iconLabelSelected}`
              : classes.iconLabel
          }
        >
          {t(e.label)}
        </div>
      </div>
    </Grid>
  ));
};

const Display = ({ vessel, mainEngineData, isInitialLoad, isNoData, engines, selectedEngine, setSelectedEngine, currentFoc, meFoc, geFoc, t, classes }) => {
  if (isInitialLoad) {
    return <CircularProgress style={{ margin: '0 auto' }} />
  } else if (isNoData) {
    return <NoData />
  }

  const focUnit = (type) => {
    let unit = '';
    if (type === 'engine') {
      unit = getFocUnit(vessel?.engineFoUnit);
    } else if (type === 'generator') {
      unit = getFocUnit(vessel?.generatorFoUnit);
    }
    return `${unit.toUpperCase()}/day`;
  };

  return (
    <>
      <Grid item xs={2}>
        {(engines.length > 0 || (engines.length > 0 && currentFoc)) && 
          <Grid container className={classes.engineContainer}>
            {EngineIcons({ engines, selectedEngine, setSelectedEngine, t, classes })}
          </Grid>
        }
      </Grid>
      <Grid item xs={10} style={{ display: "flex", justifyContent: "center" }}>
        <table style={{ borderSpacing: "6px" }}>
          <tbody>
            <tr>
              <td>
                <div
                  className={clsx(
                    classes.navLabelTextSize,
                    classes.label
                  )}
                >
                  {t(localizationKeys.EngineLoad_lower)}
                </div>
              </td>
              <td>
                {(() => {
                  if (vessel?.NoME === 2) {
                    //Get values of mainEngine data.
                    //mainEngineData is an array[ 0: port, 1: starboard]
                    //we need -1 because if selected engine in UI is 1 then we need to access mainEngineData[0]
                    const data = mainEngineData?.find(
                      (m) => m.position === MAIN_ENGINE[selectedEngine - 1]
                    );
                    const engineLoad =
                      data !== undefined && data.meOutput !== -1
                        ? (data.meOutput / vessel.meMaxKw) * 100
                        : undefined;
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>
                        {engineLoad === undefined ? "*" : engineLoad.toFixed(2)} %
                      </div>
                    );
                  } else if (vessel?.NoME === 1) {
                    const data = mainEngineData?.find(
                      (m) => m.position === MAIN_ENGINE[2]
                    );
                    const powerData =
                      data?.shaftHorsePower !== null
                        ? data?.shaftHorsePower
                        : data?.meOutput;
                    const engineLoad =
                      data !== undefined && powerData !== -1
                        ? (powerData / vessel.meMaxKw) * 100
                        : undefined;

                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>
                        {engineLoad === undefined ? "*" : engineLoad.toFixed(2)} %
                      </div>
                    );
                  } else {
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>* %</div>
                    );
                  }
                })()}
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className={clsx(
                    classes.navLabelTextSize,
                    classes.label
                  )}
                >
                  {t(localizationKeys.EngineRevolution_lower)}
                </div>
              </td>
              <td>
                {(() => {
                  if (vessel?.NoME === 2) {
                    //Get values of mainEngine data.
                    //mainEngineData is an array[ 0: port, 1: starboard]
                    //we need -1 because if selected engine in UI is 1 then we need to access mainEngineData[0]
                    const data = mainEngineData?.find(
                      (m) => m.position === MAIN_ENGINE[selectedEngine - 1]
                    );
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>
                        {data === undefined || data.shaftRevolution === -1
                          ? ""
                          : data.shaftRevolution}{" "}
                        rpm
                      </div>
                    );
                  } else if (vessel?.NoME === 1) {
                    const data = mainEngineData?.find(
                      (m) => m.position === MAIN_ENGINE[2]
                    );
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>
                        {data === undefined || data.meSpeed === -1
                          ? ""
                          : data.meSpeed}{" "}
                        rpm
                      </div>
                    );
                  } else {
                    return (
                      <div className={clsx(classes.navValueTextSize, classes.navValue)}>rpm</div>
                    );
                  }
                })()}
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className={clsx(
                    classes.navLabelTextSize,
                    classes.label
                  )}
                >
                  {t(localizationKeys.TotalEngineFoc_lower)}
                </div>
              </td>
              <td>
                {/* Main Engine FOC */}
                <div
                  data-testid="main_engine_foc_data"
                  className={clsx(classes.navValueTextSize, classes.navValue)}
                >
                  {focValue(meFoc, vessel?.engineFoUnit)} {focUnit("engine")}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className={clsx(
                    classes.navLabelTextSize,
                    classes.label
                  )}
                >
                  {t(localizationKeys.TotalDieselEngineFoc_lower)}
                </div>
              </td>
              <td>
                {/* Generator Engine FOC */}
                <div
                  data-testid="generator_engine_foc_data"
                  className={clsx(classes.navValueTextSize, classes.navValue)}
                >
                  {focValue(geFoc, vessel?.generatorFoUnit)} {focUnit("generator")}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </>
  )
};

const EngineDataPanel = (props) => {
  const dispatch = useDispatch();
  const { vessel, mainEngineData, isInitialLoad, style, classes, t } = props;
  const styles = { ...style, ...classes };
  const [selectedEngine, setSelectedEngine] = React.useState(1); //Selected engine defaults to 1
  const [geFoc, setGeFoc] = React.useState();
  const [meFoc, setMeFoc] = React.useState();
  const [engines, setEngines] = React.useState([]);

  const { currentFoc } = useFocCalculation({
    vesselId: vessel.id,
    // Removing hard cording, it is necessary to add flow meter unit info or unify flow meter unit in iAPI
    // flowmeterType: vessel?.engineFoUnitFactor,
  });

  React.useEffect(() => {
    dispatch(setSelectedFOCDuration(FOC_DURATION_LIST[0]));
  }, [vessel.id]);

  React.useEffect(() => {
    let engine = [];
    switch (vessel?.NoME) {
      case 1:
        engine = [
          {
            label: localizationKeys.Center_abbreviation,
            position: 1,
          },
        ];
        break;
      case 2:
        engine = [
          {
            label: localizationKeys.Port_abbreviation,
            position: 1,
          },
          {
            label: localizationKeys.Starboard_abbreviation,
            position: 2,
          },
        ];
    }
    setEngines(engine);
  }, [vessel?.NoME]);

  useEffect(() => {
    setSelectedEngine(1);
  }, [engines]);

  React.useEffect(() => {
    if (currentFoc === undefined || currentFoc === null) {
      setGeFoc(null);
      setMeFoc(null);
    } else {
      setGeFoc(currentFoc.geTotal * vessel?.generatorFoUnitFactor);
      setMeFoc(currentFoc.meTotal * vessel?.engineFoUnitFactor);
    }
  }, [currentFoc]);

  return (
    <Grid container className={`${styles.container} ${styles.components}`} rowGap={2}>
      <Grid item xs={12} className={styles.titleContainer}>
        {t(localizationKeys.BasicEngineData_upper)}
      </Grid>
      {Display({ vessel, mainEngineData, isInitialLoad, isNoData: engines.length <= 0, engines, selectedEngine, setSelectedEngine, currentFoc, meFoc, geFoc, t, classes: styles })}
    </Grid>
  );
};

EngineDataPanel.propTypes = {
  vessel: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  style: PropTypes.object,
  t: PropTypes.func.isRequired,
  mainEngineData: PropTypes.array,
  isInitialLoad: PropTypes.bool,
};

export default withStyles(useStyles)(withTranslation()(EngineDataPanel));
