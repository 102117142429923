import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useMediaQuery,
} from "@mui/material";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
  TableCellStyled,
  TableRowStyled,
  TableCellStyledForFunction,
} from "../utils/tableStyled.jsx";
import dayjs from 'util/dayjs-init.js';
import constants from "../utils/constants.js";
import { getComparator, stableSort, alarmOperationsListHeader } from "../utils/helper.js";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredAlarmSelector,
  operationAlarmSelector,
  isAlarmOperationLoading,
  getSearchButtonSelector,
  currentWindowPosition,
  currentTablePosition,
  setCurrentWindowPosition,
  setCurrentTablePosition,
  currentOperationPage,
  setCurrentOperationPage,
} from "../../../model/slice/alarmSlice.js";
import { fetchAlarmOperations } from "../../../model/async/alarmAsync.js";
import clsx from "clsx";
import { ArrowRightIcon } from "./arrowRightIcon.jsx";
import { ArrowDownIcon } from "./arrowDownIcon.jsx";
import PageButton from "../../common/listUtil/pageButton.jsx";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside.js";

import SortIcon from "../../../../assets/images/alarmIcon/sort-icon.svg";
import SortIconPrimary from "../../../../assets/images/alarmIcon/sort-icon-primary.svg";
import ResetIcon from "../../../../assets/images/alarmIcon/reset-icon.svg";
import ResetIconPrimary from "../../../../assets/images/alarmIcon/reset-icon-primary.svg";
import CloseIcon from "../../../../assets/images/alarmIcon/close-icon.svg";
import CalenderIconSetColor from "../../common/calenderIconSetColor.jsx";
import { MobileSortIcon } from "../../common/listUtil/mobileSortIcon.jsx";
import { paginationStyle } from "../../common/listUtil/paginationStyle.js";
import SortUpIconSetColor from "../../common/SortUpIconSetColor.jsx";
import SortDownIconSetColor from "../../common/SortDownIconSetColor.jsx";
import { selectedTimezone } from "../../../model/slice/timezoneSlice.js";
import { formatDate } from "../../../util/timezone.js";

const pageButtonBase = {
  //height: "calc(100% - 50px)",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "10px",
};

const settingHolder = {
  padding: "10px 0px",
  backgroundColor: "rgba(44, 58, 72, 1.0)",
  position: "absolute",
  color: "#ffffff",
  fontSize: "12px",
};

const useStyles = makeStyles((theme) => ({
  headerHolder: {
    height: "38px",
    backgroundColor: "#2c3a48",
    [theme.breakpoints.down('sm')]: {
      height: "24px",
    },
    "& .MuiTableCell-root": {
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
      color: "#ffffff",
      [theme.breakpoints.down('sm')]: {
        fontSize: "10px",
      },
    },
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
  sortableColumnHeader: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  columnHeader: {},
  columnHeaderSort: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  dataHolder: {
    "& .MuiTableCell-root": {
      fontSize: "12px",
      fontWeight: "300",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
      color: "#ffffff",
      [theme.breakpoints.down('sm')]: {
        fontSize: "8px",
      },
    },
  },
  noAlarmStyle: {
    paddingTop: "50px",
    fontSize: "16px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pageButton: {
    ...pageButtonBase,
    "& .MuiSvgIcon-root": {
      color: "#FFFFFF",
    },
  },
  disabledPageButton: {
    ...pageButtonBase,
    "& .MuisvgIcon-root": {
      color: "rgba(255,255,255,0.3)",
    },
  },
  buttonGrid: {
    //alignItems: "flex-start",
    justifyContent: "center",
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  backButtonAdjustment: {
    "& .MuisvgIcon-root": {
      paddingLeft: "13px",
    },
  },
  japaneseTableTitle: {
    whiteSpace: "nowrap",
  },
  arrowIconDown: {
    transform: "rotate(90deg)",
  },
  controlRow: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "inline-block",
    [theme.breakpoints.down('sm')]: {
      height: "10px",
      width: "10px",
    },
  },
  userSettingHolder: {
    ...settingHolder,
    top: "44px",
    width: "250px",
    right: "0",
    [theme.breakpoints.down('md')]: {
      top: "38px",
      right: "5%",
    },
    [theme.breakpoints.down('sm')]: {
      top: "auto",
      bottom: "8%",
      right: "8%",
      position: "fixed",
    },
  },
  settingsTable: {
    width: "100%",
    borderSpacing: "0",
  },
  settingRow: {
    height: "35px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(38, 48, 59) !important",
    },
    "& td": {
      paddingLeft: "10px",
    },
  },
  selectColor: {
    backgroundColor: "rgb(33, 38, 47) !important",
  },
  mobileSort: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "8px",
    position: "fixed",
    right: "30px",
    bottom: "60px",
    width: "30px",
    height: "30px",
    background: "#2c3a48",
    "&:hover": {
      background: "#2c3a48",
    },
  },
  mobileSortIcon: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "inline-block",
  },
  mobileRestSort: {
    marginTop: "10px",
    width: "60%",
    height: "30px",
    borderRadius: "5px 5px 5px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#21262f",
    "&:hover": {
      background: "#21262f",
    },
  },
  settingRowMobile: {
    height: "35px",
    cursor: "pointer",
  },
  mobileCloseIcon: {
    width: "20px",
    borderRadius: "5px 5px 5px 5px",
    display: "flex",
    justifyContent: "right",
  },
  arrowicon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rowLeft: {
    width: "25px",
    "& > img": {
      position: "relative",
      left: "3px",
    },
  },
  rowLeftForCalender: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
  },
  whiteText: {
    color: '#bec2c5',
  },
}));

// https://material-ui.com/components/tables/
// material ui table sample
const OperationsList = memo(({ t, vesselId, i18n, height }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
  const isXsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const alarmList = useSelector(operationAlarmSelector);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(constants.ALARM_HEADER_ID.DATETIME); //set the default sort
  const dispatch = useDispatch();
  const filteredList = useSelector(filteredAlarmSelector);
  const isLoading = useSelector(isAlarmOperationLoading);
  const searchButtonFlag = useSelector(getSearchButtonSelector);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isDisplaySortDialog, setIsDisplaySortDialog] = useState(false);
  const menuRef = useRef(null);
  const currentWinPos = useSelector(currentWindowPosition);
  const currentTablePos = useSelector(currentTablePosition);
  const page = useSelector(currentOperationPage);
  const timezone = useSelector(selectedTimezone);
  const setPage = (d) => dispatch(setCurrentOperationPage(d));
  let updateFlag = true;

  useEffect(() => {
    dispatch(fetchAlarmOperations({ vesselId }));
    const interval = setInterval(() => dispatch(fetchAlarmOperations({ vesselId })), 1000 * 60);
    const windowScrollHandler = () => {
      const y = window.scrollY;
      if (y !== 0) dispatch(setCurrentWindowPosition(y));
    };
    window.addEventListener("scroll", windowScrollHandler);

    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", windowScrollHandler);
    };
  }, []);

  useEffect(() => {
    setInitialLoad(true);
  }, [vesselId]);

  useOnClickOutside([menuRef], () => {
    setIsDisplaySortDialog(false);
  });

  const handleRequestSort = (property, sortable) => (event) => {
    if (!sortable) return;
    const isAsc = orderBy === property && order === constants.ORDER.ASC;
    setOrder(isAsc ? constants.ORDER.DESC : constants.ORDER.ASC);
    setOrderBy(property);
  };

  const ResetSort = () => {
    setOrder("asc");
    setOrderBy(constants.ALARM_HEADER_ID.DATETIME);
  };

  const CloseDialog = () => {
    setIsDisplaySortDialog(false);
  };

  const handleRequestSortForMenu = (property, selectorder) => (event) => {
    setOrder(selectorder);
    setOrderBy(property);
  };

  const displaySortDialog = () => {
    if (isDisplaySortDialog) {
      return (
        <div className={classes.userSettingHolder} ref={menuRef}>
          <table className={classes.settingsTable}>
            <tbody>
              {isXsDown && (
                <tr className={`${classes.settingRowMobile}`}>
                  <td colSpan="2" align="right" valign="top">
                    <div className={classes.mobileCloseIcon} onClick={CloseDialog}>
                      <img
                        src={CloseIcon}
                        className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
                      />
                    </div>
                  </td>
                </tr>
              )}
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ID && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ID,
                  constants.ORDER.DESC
                )}
              >
                <td className={classes.rowLeft}>
                  <SortDownIconSetColor color={orderBy === constants.ALARM_HEADER_ID.ID && order === constants.ORDER.DESC? "white" : "#bec2c5"} />
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.ID || order !== constants.ORDER.DESC? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortAscendingNo)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ID && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ID,
                  constants.ORDER.ASC
                )}
              >
                <td className={classes.rowLeft}>
                  <SortUpIconSetColor color={orderBy === constants.ALARM_HEADER_ID.ID && order === constants.ORDER.ASC? "white" : "#bec2c5"} />
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.ID || order !== constants.ORDER.ASC? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortDescendingNo)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.DATETIME && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.DATETIME,
                  constants.ORDER.ASC
                )}
              >
                <td className={classes.rowLeftForCalender}>
                  <CalenderIconSetColor color={orderBy === constants.ALARM_HEADER_ID.DATETIME && order === constants.ORDER.ASC? "white" : "#bec2c5"} />
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.DATETIME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortByNewest)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.DATETIME && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.DATETIME,
                  constants.ORDER.DESC
                )}
              >
                <td className={classes.rowLeftForCalender}>
                  <CalenderIconSetColor color={orderBy === constants.ALARM_HEADER_ID.DATETIME && order === constants.ORDER.DESC? "white" : "#bec2c5"} />
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.DATETIME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortByOldest)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ALARM_NAME && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ALARM_NAME,
                  constants.ORDER.DESC
                )}
              >
                <td className={`${classes.rowLeft} ${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                  &ensp;A
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortAlarmItemAZ)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.ALARM_HEADER_ID.ALARM_NAME && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.ALARM_HEADER_ID.ALARM_NAME,
                  constants.ORDER.ASC
                )}
              >
                <td className={`${classes.rowLeft} ${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  &ensp;Z
                </td>
                <td className={`${orderBy !== constants.ALARM_HEADER_ID.ALARM_NAME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortAlarmItemZA)}
                </td>
              </tr>
              {isXsDown && (
                <tr className={`${classes.settingRowMobile}`}>
                  <td colSpan="2" align="center">
                    <div className={classes.mobileRestSort} onClick={ResetSort}>
                      <img
                        src={ResetIcon}
                        className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
                      />
                      {t(localizationKeys.ResetSort)}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  const EnhancedTableHeader = () => {
    return (
      <TableHead>
        <TableRow className={classes.headerHolder}>
          {alarmOperationsListHeader(t, isSmDown)
            .filter((a) => isMdUp || !a.hideOnSmallDevice)
            .map((d, i) => (
              <TableCellStyled
                key={`alarm-operation-table-header-${i}`}
                align={d.align}
                colSpan={d.id === constants.ALARM_HEADER_ID.ACTION && !isXsDown ? 3 : 1}
                sortDirection={(d.sortable && orderBy) === d.id ? order : false}
                onClick={handleRequestSort(d.id, d.sortable)}
                className={d.sortable ? classes.sortableColumnHeader : classes.columnHeader}
                style={d.style}
              >
                <span
                  className={clsx(
                    i18n?.language !== "en-US" &&
                      [
                        constants.ALARM_HEADER_ID.OCCURRENCE,
                        constants.ALARM_HEADER_ID.RECOVERY,
                        constants.ALARM_HEADER_ID.DATETIME,
                      ].includes(d.id) &&
                      classes.japaneseTableTitle
                  )}
                >
                  {d.name}
                </span>
                {orderBy === d.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === constants.ORDER.DESC ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
                {d.sortable && (
                  <TableSortLabel
                    active={orderBy === d.id}
                    direction={orderBy === d.id ? order : "asc"}
                  />
                )}
              </TableCellStyled>
            ))}
          {!isXsDown && (
            <TableCellStyledForFunction
              style={{ maxWidth: "30px" }}
              onClick={() => {
                setIsDisplaySortDialog(!isDisplaySortDialog);
              }}
            >
              <img
                src={
                  order === "asc" && orderBy === constants.ALARM_HEADER_ID.DATETIME
                    ? SortIcon
                    : SortIconPrimary
                }
                className={`${classes.controlRow} ${classes.columnHeaderSort}`}
              />
            </TableCellStyledForFunction>
          )}
          {!isXsDown && (
            <TableCellStyledForFunction style={{ maxWidth: "30px" }} onClick={ResetSort}>
              <img
                src={
                  order === "asc" && orderBy === constants.ALARM_HEADER_ID.DATETIME
                    ? ResetIcon
                    : ResetIconPrimary
                }
                className={`${classes.controlRow} ${classes.columnHeaderSort}`}
              />
            </TableCellStyledForFunction>
          )}
        </TableRow>
      </TableHead>
    );
  };

  const displayData = useCallback(() => {
    if (searchButtonFlag) {
      return filteredList?.length ? filteredList : null;
    } else if (alarmList?.length) {
      return alarmList;
    } else {
      return null;
    }
  }, [searchButtonFlag, alarmList, filteredList]);

  const formattedDateDisplayData = useCallback(() => {
    if (displayData()?.length > 0) {
      return displayData().map(data => ({
        ...data,
        dateTime: formatDate(data.dateTime, timezone, constants.ALARM_DATE_FORMAT),
      }));
    }
    return null;
  }, [timezone, displayData()]);

  const span = () => (isMdUp ? 8 : isXsDown ? 5 : 7);

  const tableBody = () =>
    stableSort(formattedDateDisplayData(), getComparator(order, orderBy))
      .slice(
        page * constants.ROWS_PER_PAGE,
        page * constants.ROWS_PER_PAGE + constants.ROWS_PER_PAGE
      )
      .map((row, i) => (
        <TableRowStyled key={`alarm-operation-list-${i}`} className={classes.dataHolder}>
          {isMdUp && <TableCellStyled align="left">{row.channelNo}</TableCellStyled>}
          <TableCellStyled align="left">{row.channelName}</TableCellStyled>
          <TableCellStyled align="left">{row.operationType}</TableCellStyled>
          <TableCellStyled align="center">{row.targetStatus}</TableCellStyled>
          <TableCellStyled align="right" style={{ paddingRight: "0" }}>
            {row.beforeValue}
          </TableCellStyled>
          <TableCellStyled
            align="center"
            style={{ width: "60px", paddingLeft: "0", paddingRight: "0" }}
          >
            <ArrowRightIcon />
          </TableCellStyled>
          <TableCellStyled align="left" style={{ paddingLeft: "0" }}>
            {row.afterValue}
          </TableCellStyled>
          <TableCellStyled align="left">
            {row?.dateTime
              ? dayjs.utc(row.dateTime).format(constants.ALARM_DATE_FORMAT)
              : constants.INVALID_DATE}
          </TableCellStyled>
        </TableRowStyled>
      ));

  const tableBodyXs = () =>
    stableSort(formattedDateDisplayData(), getComparator(order, orderBy)).map((row, i) => (
      <TableRowStyled key={`alarm-operation-list-${i}`} className={classes.dataHolder}>
        {isMdUp && <TableCellStyled align="left">{row.channelNo}</TableCellStyled>}
        <TableCellStyled align="left">{row.channelName}</TableCellStyled>
        <TableCellStyled align="left">{row.operationType}</TableCellStyled>
        <TableCellStyled align="center">{row.targetStatus}</TableCellStyled>
        <TableCellStyled align="center">
          <p>{row.beforeValue}</p>
          <p>
            <ArrowDownIcon />
          </p>
          <p>{row.afterValue}</p>
        </TableCellStyled>
        <TableCellStyled align="center">
          {row?.dateTime
            ? dayjs.utc(row.dateTime).format(constants.ALARM_DATE_FORMAT)
            : constants.INVALID_DATE}
        </TableCellStyled>
      </TableRowStyled>
    ));

  useEffect(() => {
    const table = document.getElementById("dataGrid");
    const tableScrollHandler = () => {
      const y = table.scrollTop;
      if (y !== 0) dispatch(setCurrentTablePosition(y));
    };
    if (formattedDateDisplayData()?.length === 0) setPage(0);
    else if (
      searchButtonFlag &&
      Math.floor(filteredList?.length / constants.ROWS_PER_PAGE) < page
    ) {
      setPage(Math.floor(filteredList?.length / constants.ROWS_PER_PAGE));
    }
    if (updateFlag) {
      updateFlag = false;
      table.addEventListener("scroll", tableScrollHandler);
    }
    window.scrollTo(0, currentWinPos);
    table.scrollTo(0, currentTablePos);

    return () => {
      table.removeEventListener("scroll", tableScrollHandler);
    };
  }, [formattedDateDisplayData()]);

  const DisplayalarmList = () => {
    if (initialLoad && isLoading) {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={span()} align="center">
            <CircularProgress />
          </TableCellStyled>
        </TableRowStyled>
      );
    } else if (formattedDateDisplayData() !== null) {
      return isXsDown ? tableBodyXs() : tableBody();
    } else if (isLoading) {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={span()} align="center">
            <CircularProgress />
          </TableCellStyled>
        </TableRowStyled>
      );
    } else {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={span()} align="center" className={classes.noAlarmStyle}>
            {t(localizationKeys.NoOperations_upper)}
          </TableCellStyled>
        </TableRowStyled>
      );
    }
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container alignItems="stretch" style={{ display: "flex" }} justifyContent="center">
      <Grid item sm={12} style={{ width: "100%" }}>
        <TableContainer
          id="dataGrid"
          style={{
            borderRadius: "8px",
            height: isMdUp ? "100%" : `calc(100vh - ${height}px)`,
            minHeight: "400px",
            position: "relative",
          }}
        >
          <Table aria-label="customized table">
            <EnhancedTableHeader />
            <TableBody>
              <DisplayalarmList />
            </TableBody>
          </Table>
          {isXsDown && (
            <div
              className={classes.mobileSort}
              onClick={() => {
                setIsDisplaySortDialog(!isDisplaySortDialog);
              }}
            >
              <MobileSortIcon
                color={orderBy == null ? "white" : "#19B2AB"}
                className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
              />
            </div>
          )}
          {displaySortDialog()}
        </TableContainer>
        {isMdUp && (
          <div className={classes.arrowicon}>
            <Grid className={classes.arrowicon}>
              <PageButton
                type={constants.PAGE_BUTTON.BACK}
                page={page}
                setPage={setPage}
                displayData={formattedDateDisplayData()}
                classes={classes}
                isLoading={isLoading}
              />
              <TablePagination
                component="div"
                count={formattedDateDisplayData()?.length ?? 0}
                page={page}
                sx={paginationStyle}
                onPageChange={handleChangePage}
                rowsPerPage={constants.ROWS_PER_PAGE}
                rowsPerPageOptions={[]}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from} - ${to} ${t(localizationKeys.OutOf_lower)} ${count !== -1 ? count : 0}`
                }
              />
              <PageButton
                type={constants.PAGE_BUTTON.FORWARD}
                page={page}
                setPage={setPage}
                displayData={formattedDateDisplayData()}
                classes={classes}
                isLoading={isLoading}
              />
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
});

OperationsList.propTypes = {
  t: PropTypes.func,
  filterValue: PropTypes.string,
  durationFilter: PropTypes.object,
  filterStatus: PropTypes.string,
};

export default withTranslation()(OperationsList);
