import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import makeStyles from '@mui/styles/makeStyles';
import localizationKeys from "../../i18n/localizationKeys";
import PropTypes from "prop-types";

import CloseIcon from "../../../assets/images/custom/close_icon.svg";

import { releaseNoteAllEn } from "../../i18n/releaseNotes/releaseNoteAllEn";
import { releaseNoteAllJp } from "../../i18n/releaseNotes/releaseNoteAllJp";

const useStyles = makeStyles({
  modal: {
    backgroundColor: "#161a1e",
    color: "#ffffff",
    paddingBottom: 0,
    top: "75px", 
    "& .ant-modal-content": {
      backgroundColor: "unset",
      padding: "50px 8.5% 30px 8.5%",
      height: "100%",
      overflowY: "auto",
    },
    "& .ant-modal-header": {
      backgroundColor: "unset",
      "& .ant-modal-title": {
        color: "#ffffff",
        fontSize: "24px",
        fontWeight: "400",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
      },
    },
    "& .ant-modal-body": {
      height: "calc(100% - 50px)",
      width: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    }
  },
  btn: {
    color: "#000",
    fontSize: "16px",
    fontWeight: "500",
    backgroundColor: "#28b6c7",
    borderRadius: "5px",
    textAlign: "center",
    width: "172px",
    height: "43px",
    cursor: "pointer",
    border: 0,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: 0,
  },
  noteArea: {
    height: "calc(100% - 90px)",
    width: "100%",
    overflowY: "auto",
  },
  infoArea: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "10px",
  },
  text: {
    color: "#fff",
  },
  infoTitle: {
    fontStyle: "italic",
    marginRight: "5px",
  },
  newFeatureTitle: {
    fontWeight: 600,
    marginTop: "35px",
    marginBottom: "15px",
  },
  newFeatureContent: {
    color: "#fff",
    "&::before": {
      content: '"• "',
    },
    marginTop: "5px",
  },
  newFeatureSubContent: {
    marginLeft: "20px",
    marginTop: "5px",
  },
  image: {
    width: "90%",
    marginLeft: "5%",
    height: "auto",
  },
});

export function ReleaseNoteModal(props) {
  const { when, onCancel, version, releaseDate, updatedDate, selectedLanguage } = props;
  const classes = useStyles();
  const releaseDateDisplayed = selectedLanguage === "ja" ? releaseDate.format("YYYY/MM/DD HH:mm,") : releaseDate.format("MM/DD/YYYY HH:mm.");
  const updatedDateDisplayed = selectedLanguage === "ja" ? updatedDate.format("YYYY/MM/DD HH:mm") : updatedDate.format("MM/DD/YYYY HH:mm.");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const releaseNoteNewestEn = releaseNoteAllEn[0];
  const releaseNoteNewestJp = releaseNoteAllJp[0];
  const releaseNoteNewest = selectedLanguage === "ja" ? releaseNoteNewestJp : releaseNoteNewestEn;

  return (
    <Modal
      title={selectedLanguage === "ja" ? `${t(localizationKeys.NowReleased_lower)}${version}` : `${version}${t(localizationKeys.NowReleased_lower)}`}
      open={when}
      onCancel={onCancel}
      footer={null}
      width={"calc(37.5vw)"}
      height={"calc(100vh - 140px)"}
      closeIcon={<img src={CloseIcon} />}
      maskClosable={true}
      className={classes.modal}
    >
      <div className={classes.infoArea}>
        <span className={`${classes.text} ${classes.infoTitle}`}>
          {t(localizationKeys.Released_lower)}
        </span>
        <span className={classes.text} style={{ marginRight: "15px", }}>
          {releaseDateDisplayed}
        </span>
        <span className={`${classes.text} ${classes.infoTitle}`}>
          {t(localizationKeys.Updated_lower)}
        </span>
        <span className={classes.text}>
          {updatedDateDisplayed}
        </span>
      </div>
      <div className={classes.noteArea}>
        <div className={classes.text}>
          {releaseNoteNewest[0].introduction}
        </div>
        {releaseNoteNewest.slice(1).map((section, idx) => (
          <div key={ `${section.title}_${idx}_area` }>
            <div className={classes.newFeatureTitle} key={ `${section.title}_${idx}` }>
              {section.title}
            </div>
            {section.descriptions.map((item, itemIdx) => (
              <div className={classes.text} key={`${section.title}_${item}_${itemIdx}`}>
                {item.split("\n").map((line, lineIdx) => (
                  <div 
                    className={lineIdx === 0 ? classes.newFeatureContent : classes.newFeatureSubContent}
                    key={`${section.title}_${item}_${itemIdx}_${lineIdx}`}
                  >
                    {line}
                  </div>
                ))}
              </div>
            ))}
            {section.images.map((img, imgIdx) => (
              <img
                key={`${section.title}_img_${imgIdx}`}
                src={img}
                className={classes.image}
              />
            ))}
          </div>
        ))}
        <br />
        <br />
        <div className={classes.text}>
          {releaseNoteNewest[0].conclusion}
        </div>
      </div>

      <button
        className={classes.btn}
        onClick={() => navigate("/releasenotes")}
      >
        {t(localizationKeys.SeeReleaseDetails_lower)}
      </button>
    </Modal>
  );
}

ReleaseNoteModal.propTypes = {
  when: PropTypes.bool,
  version: PropTypes.string,
  releaseDate: PropTypes.object,
  updatedDate: PropTypes.object,
  selectedLanguage: PropTypes.string,
  onCancel: PropTypes.func,
}