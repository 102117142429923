export const CHANNEL_TYPE = {
  ANALOG: "1",
  DIGITAL: "2",
  PULSE: "6",
};

export const isAnalogChannel = (channelType) => {
  // channelType 1 is Analog, 6 is Running hour, count
  return [CHANNEL_TYPE.ANALOG, CHANNEL_TYPE.PULSE].includes(channelType);
}

export const isDigitalChannel = (channelType) => {
  // channelType 2 is Digital
  return [CHANNEL_TYPE.DIGITAL].includes(channelType);
}

export const isDisplayedOnCustom = (channelType) => {
  return isAnalogChannel(channelType) || isDigitalChannel(channelType);
}